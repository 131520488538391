import React from 'react';
import { makeAuthenticator, makeUserManager, Callback } from 'react-oidc';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';

const config = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: `${window.location.origin}/drop-ticket/redirect`,
    response_type: 'code',
    loadUserInfo: false,
    scope: 'openid profile',
    metadata: {
        issuer: process.env.REACT_APP_ISSUER,
        authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
        token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
        jwks_uri: process.env.REACT_APP_JWKS_URI
    }
};
const userManager = makeUserManager(config);

const AppWithAuth = makeAuthenticator({
    userManager,
    signinArgs: {
        state: {
            partialDropTicketId: window.location.href.includes('?id=') ? window.location.href.substring(window.location.href.indexOf('?id=')) : ''
        }
    }
})(App);

export default function AuthWrapper() {
    return (
        <>
            {process.env.ACCESS_LEVEL === 'secure' && (
                <Router basename="/drop-ticket">
                    <Switch>
                        <Route path="/redirect"
                            render={(routeProps) => (
                                <Callback userManager={userManager}
                                    onSuccess={(user) => {
                                        routeProps.history.push(`/insured${user.state.partialDropTicketId}`);
                                    }}
                                />
                            )}
                        />
                        <AppWithAuth />
                    </Switch>
                </Router>
            )}
            {process.env.ACCESS_LEVEL === 'public' && (
                <Router basename="/public-drop-ticket">
                    <Switch>
                        <App />
                    </Switch>
                </Router>
            )}
        </>
    );
}
