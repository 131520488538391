import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ErrorSpan from './ErrorSpan';
import ValidationRules from '../../validations/ValidationRules';

function SelectField(props: InferProps<typeof SelectField.propTypes>) {
    const checkHasError = (): boolean => {
        const nameSplitArray = props.name.split('.');
        if (nameSplitArray.length > 1) {
            return props.errors
                && props.errors[nameSplitArray[0]]
                && props.errors[nameSplitArray[0]][nameSplitArray[1]]
                && props.errors[nameSplitArray[0]][nameSplitArray[1]][`${nameSplitArray[2]}`];
        }
        return props.errors && props.errors[props.name];
    };

    const getValidationRules = () => {
        const nameSplitArray = props.name.split('.');
        if (nameSplitArray.length > 1) {
            return ValidationRules[nameSplitArray[0]][`${nameSplitArray[2]}`];
        }
        return ValidationRules[props.name];
    };

    const hasError = checkHasError();
    const rules = getValidationRules();

    return (
        <label htmlFor={props.id}>
            <b>
                {props.labelText}
                {' '}
            </b>
            {props.required && <span className="pds-label-required">*</span>}
            <select className={hasError ? 'pds-select-error' : ''}
                id={props.id}
                value={props.value || undefined}
                onBlur={(event) => props.onBlur?.(event)}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                {...props.register(props.name, rules)}
            >
                <option hidden />
                {props.options.map((selectOption) => (
                    <>
                        {props.hideValue && (!selectOption.optional && (
                        <option key={selectOption.label} value={selectOption.value}>{selectOption.label}</option>
                        ))}
                        {!props.hideValue && (
                        <option key={selectOption.label} value={selectOption.value}>{selectOption.label}</option>
                        )}
                    </>
                ))}
            </select>
            <ErrorSpan hasError={hasError} name={props.name} errors={props.errors} />
        </label>
    );
}

SelectField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.any.isRequired,
    register: PropTypes.any.isRequired,
    errors: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any,
    value: PropTypes.string,
    labelText: PropTypes.string,
    options: PropTypes.array.isRequired,
    required: PropTypes.bool,
    hideValue: PropTypes.bool,
    defaultValue: PropTypes.any
};

SelectField.defaultProps = {
    errors: undefined,
    onBlur: undefined,
    onChange: undefined,
    value: null,
    labelText: '',
    required: false,
    hideValue: false,
    defaultValue: ''
};

export default SelectField;
