import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import RadioField from './RadioField';
import * as Constants from '../../utility/constants';
import CheckBoxField from './CheckBoxField';
import TextField from './TextField';
import MaskedInput from './MaskedInput';
import '@pds/util/util.min.css';

function ExistingInsurance(props: InferProps<typeof ExistingInsurance.propTypes>) {
    const existingInsuranceResponse = props.watch(`${props.idPrefix}ExistingInsuranceResponse`, props.requestData[`${props.idPrefix}ExistingInsuranceResponse`]);
    const existingInsuranceReplacementResponse = props.watch(`${props.idPrefix}ExistingInsuranceReplacementResponse`);
    const existingInsurancePolicies = props.watch(`${props.idPrefix}ExistingInsurancePolicies`);

    const addPolicy = () => {
        if (props.fields.length < 10) {
            props.append({});
        }
    };

    const deletePolicy = (index: number) => {
        if (props.fields.length > 1) {
            props.remove(index);
        }
    };

    const replacementError = () => {
        if (existingInsuranceReplacementResponse === 'true' && existingInsurancePolicies !== null) {
            let hasReplacingPolicy = false;
            existingInsurancePolicies.forEach((existingInsurancePolicy: any) => {
                if (existingInsurancePolicy.replacing) {
                    hasReplacingPolicy = true;
                }
            });
            props.hasReplacementError(!hasReplacingPolicy);
            return !hasReplacingPolicy;
        }
        props.hasReplacementError(false);
        return false;
    };

    const resetExistingInsurancePolicies = () => {
        if (existingInsuranceResponse === 'true' && props.fields.length === 0) {
            props.append({});
        } else if (existingInsuranceResponse === 'false') {
            for (let index = 0; index < props.fields.length; index += 1) {
                props.remove(index);
            }
            if (props.idPrefix === 'insured') {
                props.setRequest(Object.assign(props.requestData, { insuredExistingInsurancePolicies: [{}] }));
            } else if (props.idPrefix === 'owner') {
                props.setRequest(Object.assign(props.requestData, { ownerExistingInsurancePolicies: [{}] }));
            }
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <label htmlFor={`${props.idPrefix}ExistingInsurance`}>
                        <b>
                            Does the
                            {' '}
                            {props.labelFor}
                            {' '}
                            have any life insurance or annuities inforce or applied for?
                        </b>
                        <span className="pds-label-required"> *</span>
                    </label>
                </div>
                <br />
                <div className="row col-lg-12">
                    <RadioField name={`${props.idPrefix}ExistingInsuranceResponse`}
                        values={Constants.YES_NO_INPUTS}
                        register={props.register}
                        errors={props.errors}
                        defaultChecked={props.requestData[`${props.idPrefix}ExistingInsuranceResponse`]}
                        onChange={resetExistingInsurancePolicies()}
                    />
                </div>
            </div>
            <br />
            {existingInsuranceResponse === 'true' && (
                <>
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor={`${props.idPrefix}ExistingInsuranceReplacementResponse`}>
                                <b>
                                    Does the
                                    {' '}
                                    {props.labelFor}
                                    {' '}
                                    plan to replace any of their existing contracts?
                                </b>
                                <span className="pds-label-required">*</span>
                            </label>
                        </div>
                        <br />
                        <div className="row col-lg-12">
                            <RadioField name={`${props.idPrefix}ExistingInsuranceReplacementResponse`}
                                values={Constants.YES_NO_INPUTS}
                                register={props.register}
                                errors={props.errors}
                                defaultChecked={props.requestData[`${props.idPrefix}ExistingInsuranceReplacementResponse`]}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor={`${props.idPrefix}ExistingInsurancePolicies`}>
                                <b>Please list all policies (at least one is required): </b>
                                <span className="pds-label-required">*</span>
                            </label>
                            {replacementError() && (
                                <div id="replacementError">
                                    <span className="pds-error" id="hasReplacingPolicy">
                                        Please select one or more contracts to replace
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row pds-util-margin-top-16">
                        <div className="col-lg-12">
                            <button type="button" className="pds-button pds-button-tertiary" onClick={addPolicy}>Add Policy</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <table className="pds-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Company
                                            <label htmlFor={`${props.idPrefix}ExistingInsurancePoliciesCompany`}><span className="pds-label-required">*</span></label>
                                        </th>
                                        <th>
                                            Amount
                                            <label htmlFor={`${props.idPrefix}ExistingInsurancePoliciesAmount`}><span className="pds-label-required">*</span></label>
                                        </th>
                                        <th>Replacing?</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.fields.map((policy: any, index: number) => (
                                        <tr key={policy.id}>
                                            <td data-heading="Company" className="pds-util-vertical-align-top">
                                                <TextField id={`${props.idPrefix}ExistingInsurancePolicies.${index}.company`}
                                                    name={`${props.idPrefix}ExistingInsurancePolicies.${index}.company`}
                                                    type="text"
                                                    register={props.register}
                                                    errors={props.errors}
                                                    defaultValue={props.requestData[`${props.idPrefix}ExistingInsurancePolicies`]?.[index]?.company}
                                                />
                                            </td>
                                            <td data-heading="Amount" className="pds-util-vertical-align-top">
                                                <MaskedInput id={`${props.idPrefix}ExistingInsurancePolicies.${index}.amount`}
                                                    name={`${props.idPrefix}ExistingInsurancePolicies.${index}.amount`}
                                                    mask={Constants.FACE_AMOUNT_MASK}
                                                    defaultValue={props.requestData[`${props.idPrefix}ExistingInsurancePolicies`]?.[index]?.amount}
                                                    control={props.control}
                                                    errors={props.errors}
                                                />
                                            </td>
                                            <td data-heading="Replacing" className="pds-util-vertical-align-top">
                                                <CheckBoxField id={`${props.idPrefix}ExistingInsurancePolicies.${index}.replacing`}
                                                    name={`${props.idPrefix}ExistingInsurancePolicies.${index}.replacing`}
                                                    disabled={existingInsuranceReplacementResponse === 'false'}
                                                    register={props.register}
                                                    errors={props.errors}
                                                    labelText="Yes"
                                                    defaultChecked={props.requestData[`${props.idPrefix}ExistingInsurancePolicies`]?.[index]?.replacing}
                                                />
                                            </td>
                                            <td className="pds-util-vertical-align-top">
                                                <button type="button" className="pds-button pds-button-tertiary-border" onClick={() => deletePolicy(index)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                </>
            )}
        </>
    );
}

ExistingInsurance.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    control: PropTypes.any.isRequired,
    append: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    idPrefix: PropTypes.string.isRequired,
    labelFor: PropTypes.string.isRequired,
    hasReplacementError: PropTypes.any.isRequired,
    errors: PropTypes.any,
    requestData: PropTypes.any.isRequired,
    setRequest: PropTypes.func.isRequired
};

ExistingInsurance.defaultProps = {
    errors: undefined
};

export default ExistingInsurance;
