import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import '@pds/stepIndicator/stepIndicator.min.css';
import * as Constants from '../../utility/constants';

function StepIndicator(props: InferProps<typeof StepIndicator.propTypes>) {
    const INSURED_STEP = ['active', 'disabled', 'disabled', 'disabled', 'disabled'];
    const OWNER_STEP = ['completed', 'active', 'disabled', 'disabled', 'disabled'];
    const COVERAGE_STEP = ['completed', 'comleted', 'active', 'disabled', 'disabled'];
    const FINPRO_STEP = ['completed', 'completed', 'completed', 'active', 'disabled'];
    const ATTESTATION_STEP = ['completed', 'completed', 'completed', 'completed', 'active'];

    let status: any;
    if (props.step === Constants.INSURED_STEP) {
        status = INSURED_STEP;
    } else if (props.step === Constants.OWNER_STEP) {
        status = OWNER_STEP;
    } else if (props.step === Constants.COVERAGE_STEP) {
        status = COVERAGE_STEP;
    } else if (props.step === Constants.FINPRO_STEP) {
        status = FINPRO_STEP;
    } else if (props.step === Constants.ATTESTATION_STEP) {
        status = ATTESTATION_STEP;
    }

    return (
        <div aria-label="progress" className="pds-stepIndicator">
            <ol>
                <li className={`pds-stepIndicator-item pds-stepIndicator-item-${status[0]}`}>
                    <span className="pds-stepIndicator-step-text">Proposed Insured</span>
                </li>
                {(props.isInsuredOwnerSame === 'false') && (
                    <li className={`pds-stepIndicator-item pds-stepIndicator-item-${status[1]}`}>
                        <span className="pds-stepIndicator-step-text">Owner</span>
                    </li>
                )}
                <li className={`pds-stepIndicator-item pds-stepIndicator-item-${status[2]}`}>
                    <span className="pds-stepIndicator-step-text">Coverage</span>
                </li>
                <li className={`pds-stepIndicator-item pds-stepIndicator-item-${status[3]}`}>
                    <span className="pds-stepIndicator-step-text">Financial Professional</span>
                </li>
                <li className={`pds-stepIndicator-item pds-stepIndicator-item-${status[4]}`}>
                    <span className="pds-stepIndicator-step-text">Attestation/Submit</span>
                </li>
            </ol>
            <span className="pds-stepIndicator-mobile-text pds-typography-eyebrow-2" />
        </div>
    );
}

StepIndicator.propTypes = {
    step: PropTypes.string.isRequired,
    isInsuredOwnerSame: PropTypes.string.isRequired
};

export default StepIndicator;
