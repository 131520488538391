import { createNumberMask } from 'text-mask-addons';

export const LANGUAGE_ENGLISH = 'EN';

export const INSURED_PATH = '/insured';
export const LANDING_PAGE_PATH = '/';
export const OWNER_PATH = '/owner';
export const COVERAGE_PATH = '/coverage';
export const FINPRO_PATH = '/financialProfessional';
export const ATTESTATION_PATH = '/attestation';
export const SUBMIT_PATH = '/submit';
export const SUBMIT_TO_FINPRO_PATH = '/submitToFinPro';

export const STATES = [
    { value: '0001', label: 'AL' },
    { value: '0054', label: 'AK' },
    { value: '0003', label: 'AR' },
    { value: '0002', label: 'AZ' },
    { value: '0004', label: 'CA' },
    { value: '0005', label: 'CO' },
    { value: '0006', label: 'CT' },
    { value: '0008', label: 'DC' },
    { value: '0007', label: 'DE' },
    { value: '0009', label: 'FL' },
    { value: '0010', label: 'GA' },
    { value: '0056', label: 'HI' },
    { value: '0014', label: 'IA' },
    { value: '0011', label: 'ID' },
    { value: '0012', label: 'IL' },
    { value: '0013', label: 'IN' },
    { value: '0015', label: 'KS' },
    { value: '0016', label: 'KY' },
    { value: '0017', label: 'LA' },
    { value: '0020', label: 'MA' },
    { value: '0019', label: 'MD' },
    { value: '0018', label: 'ME' },
    { value: '0021', label: 'MI' },
    { value: '0022', label: 'MN' },
    { value: '0024', label: 'MO' },
    { value: '0023', label: 'MS' },
    { value: '0025', label: 'MT' },
    { value: '0032', label: 'NC' },
    { value: '0033', label: 'ND' },
    { value: '0026', label: 'NE' },
    { value: '0028', label: 'NH' },
    { value: '0029', label: 'NJ' },
    { value: '0030', label: 'NM' },
    { value: '0027', label: 'NV' },
    { value: '0031', label: 'NY', optional: true },
    { value: '0034', label: 'OH' },
    { value: '0035', label: 'OK' },
    { value: '0036', label: 'OR' },
    { value: '0037', label: 'PA' },
    { value: '0038', label: 'RI' },
    { value: '0039', label: 'SC' },
    { value: '0040', label: 'SD' },
    { value: '0041', label: 'TN' },
    { value: '0042', label: 'TX' },
    { value: '0043', label: 'UT' },
    { value: '0045', label: 'VA' },
    { value: '0044', label: 'VT' },
    { value: '0046', label: 'WA' },
    { value: '0048', label: 'WI' },
    { value: '0047', label: 'WV' },
    { value: '0049', label: 'WY' }

];

export const GENDER = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' }
];

export const YES_NO_INPUTS = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' }
];

export const OWNER_TYPE = [
    { value: 'individual', label: 'Individual' },
    { value: 'other', label: 'Other' }
];

export const OWNER_TYPE_APP_REDESIGN = [
    { value: 'Individual', label: 'Individual' },
    { value: 'Organization', label: 'Organization' },
    { value: 'Trust', label: 'Trust' }
];

export const NUMERIC = /^[0-9]+$/;
export const FIELD_OFFICE_CONTACT_PHONE = /\(\d{3}\)\s\d{3}-\d{4}$/;
export const PHONE = /\((?!800|888)\d{3}\)\s\d{3}-\d{4}$/;
export const LIST_BILL = /\d{12}-\d{3}$/;
export const DATE = /^(0?[1-9]|1[012])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/;
export const ZIP = /^\d{5}(-\d{4})?$/;
export const EMAIL = /^[\w'-]+(?:\.[\w'-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,25}$/;
export const PERCENTAGE = /(^[1-9]{1}[0-9]?(?:\.\d{1,2})?)$|(^0\.\d{1,2}?)$|(^100([.]0{1,2})?)$/;
export const DETAIL_CODE = /\d{5}-\d{5}$/;
export const BILLING_CLIENT_ID = /\d{7}$/;
export const SSN = /^([\dX]{3}-?[\dX]{2}-?\d{4})$/;
export const NAME = /^[a-zA-Z\s'-]+$/;

export const OWNER_RELATIONS = [
    { value: 'Business', label: 'Business', relationRoleType: '0036' },
    { value: 'Business Associate', label: 'Business Associate', relationRoleType: '0037' },
    { value: 'Business Partner', label: 'Business Partner', relationRoleType: '0038' },
    { value: 'Charity', label: 'Charity', organizationFormType: '0008', optional: true },
    { value: 'Child', label: 'Child', relationRoleType: '0039' },
    { value: 'Creditor', label: 'Creditor', relationRoleType: '0042' },
    { value: 'Daughter', label: 'Daughter', relationRoleType: '0039', relationDescription: '0027' },
    { value: 'Domestic Partner', label: 'Domestic Partner', relationRoleType: '0130' },
    { value: 'Employer', label: 'Employer', relationRoleType: '0027' },
    { value: 'Father', label: 'Father', relationRoleType: '0041', relationDescription: '0030' },
    { value: 'Fiancee', label: 'Fiancee', relationRoleType: '0043', relationDescription: '0070' },
    { value: 'Friend', label: 'Friend', relationRoleType: '0044' },
    { value: 'Grandchild', label: 'Grandchild', relationRoleType: '0045' },
    { value: 'Granddaughter', label: 'Granddaughter', relationRoleType: '0045', relationDescription: '0031' },
    { value: 'Grandfather', label: 'Grandfather', relationRoleType: '0046', relationDescription: '0034' },
    { value: 'Grandmother', label: 'Grandmother', relationRoleType: '0046', relationDescription: '0033' },
    { value: 'Grandparent', label: 'Grandparent', relationRoleType: '0046' },
    { value: 'Grandson', label: 'Grandson', relationRoleType: '0045', relationDescription: '0032' },
    { value: 'Husband', label: 'Husband', relationRoleType: '0040', relationDescription: '0035' },
    { value: 'Legal Guardian', label: 'Legal Guardian', relationRoleType: '0047' },
    { value: 'Mother', label: 'Mother', relationRoleType: '0041', relationDescription: '0029' },
    { value: 'Other', label: 'Other', relationRoleType: '0076' },
    { value: 'Other Relative', label: 'Other Relative', relationRoleType: '0048' },
    { value: 'Parent', label: 'Parent', relationRoleType: '0041' },
    { value: 'Parent (Step)', label: 'Parent (Step)', relationRoleType: '0049' },
    { value: 'Sibling', label: 'Sibling', relationRoleType: '0050' },
    { value: 'Son', label: 'Son', relationRoleType: '0039', relationDescription: '0028' },
    { value: 'Spouse', label: 'Spouse', relationRoleType: '0040' },
    { value: 'Step Child', label: 'Step Child', relationRoleType: '0051' },
    { value: 'Stepfather', label: 'Stepfather', relationRoleType: '0049', relationDescription: '0037' },
    { value: 'Stepmother', label: 'Stepmother', relationRoleType: '0049', relationDescription: '0038' },
    { value: 'Stepparent', label: 'Stepparent', relationRoleType: '0049' },
    { value: 'Trust', label: 'Trust', organizationFormType: '0007', optional: true },
    { value: 'Wife', label: 'Wife', relationRoleType: '0040', relationDescription: '0036' }
];

export const INDIVIDUAL_OWNER_RELATIONS = [
    { value: 'Business Associate', label: 'Business Associate', relationRoleType: '0037' },
    { value: 'Business Partner', label: 'Business Partner', relationRoleType: '0038' },
    { value: 'Child', label: 'Child', relationRoleType: '0039' },
    { value: 'Daughter', label: 'Daughter', relationRoleType: '0039', relationDescription: '0027' },
    { value: 'Domestic Partner', label: 'Domestic Partner', relationRoleType: '0130' },
    { value: 'Father', label: 'Father', relationRoleType: '0041', relationDescription: '0030' },
    { value: 'Fiancee', label: 'Fiancee', relationRoleType: '0043', relationDescription: '0070' },
    { value: 'Friend', label: 'Friend', relationRoleType: '0044' },
    { value: 'Grandchild', label: 'Grandchild', relationRoleType: '0045' },
    { value: 'Granddaughter', label: 'Granddaughter', relationRoleType: '0045', relationDescription: '0031' },
    { value: 'Grandfather', label: 'Grandfather', relationRoleType: '0046', relationDescription: '0034' },
    { value: 'Grandmother', label: 'Grandmother', relationRoleType: '0046', relationDescription: '0033' },
    { value: 'Grandparent', label: 'Grandparent', relationRoleType: '0046' },
    { value: 'Grandson', label: 'Grandson', relationRoleType: '0045', relationDescription: '0032' },
    { value: 'Husband', label: 'Husband', relationRoleType: '0040', relationDescription: '0035' },
    { value: 'Legal Guardian', label: 'Legal Guardian', relationRoleType: '0047' },
    { value: 'Mother', label: 'Mother', relationRoleType: '0041', relationDescription: '0029' },
    { value: 'Other', label: 'Other', relationRoleType: '0076' },
    { value: 'Other Relative', label: 'Other Relative', relationRoleType: '0048' },
    { value: 'Parent', label: 'Parent', relationRoleType: '0041' },
    { value: 'Sibling', label: 'Sibling', relationRoleType: '0050' },
    { value: 'Son', label: 'Son', relationRoleType: '0039', relationDescription: '0028' },
    { value: 'Spouse', label: 'Spouse', relationRoleType: '0040' },
    { value: 'Step Child', label: 'Stepchild', relationRoleType: '0051' },
    { value: 'Stepfather', label: 'Stepfather', relationRoleType: '0049', relationDescription: '0037' },
    { value: 'Stepmother', label: 'Stepmother', relationRoleType: '0049', relationDescription: '0038' },
    { value: 'Stepparent', label: 'Step Parent', relationRoleType: '0049' },
    { value: 'Wife', label: 'Wife', relationRoleType: '0040', relationDescription: '0036' }
];

export const ORGANIZATION_OWNER_RELATIONS = [
    { value: 'Business', label: 'Business', relationRoleType: '0036' },
    { value: 'Charity', label: 'Charity', organizationFormType: '0008', optional: true },
    { value: 'Creditor', label: 'Creditor', relationRoleType: '0042' },
    { value: 'Employer', label: 'Employer', relationRoleType: '0027' }
];

export const TRUST_OWNER_RELATIONS = [
    { value: 'Trust', label: 'Trust', organizationFormType: '0007', optional: true }
];

export const FINPRO_RELATIONS = [
    { value: 'Financial Professional/Self', label: 'Financial Professional/Self', relationRoleType: '0092' },
    { value: 'Spouse', label: 'Spouse', relationRoleType: '0040' },
    { value: 'Child Under 18', label: 'Child Under 18', relationRoleType: '0039' },
    { value: 'Business Relationship', label: 'Business Relationship', relationRoleType: '0037' },
    { value: 'None ', label: 'None ', relationRoleType: null }
];

export const PHONE_MASK = [
    '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
];

export const DATE_OF_BIRTH_MASK = [
    /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/
];

export const ZIP_MASK = (value: string) => {
    return [
        /\d/, /\d/, /\d/, /\d/, /\d/, (value.length > 5 ? '-' : /\d/), /\d/, /\d/, /\d/, /\d/
    ];
};

export const LIST_BILL_MASK = [
    /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/
];

export const BILLING_CLIENT_ID_MASK = [
    /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/
];

export const TOBACCO = [
    { value: 'true', label: 'Tobacco' },
    { value: 'false', label: 'Nontobacco' }
];

export const EXAM_REQUIRMENT = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' }
];

export const PARAMED_VENDOR = [
    { value: 'APPS - Portamedic', label: 'APPS-Portamedic' },
    { value: 'ExamOne', label: 'ExamOne' }
];

export const ALTERNATE_ADDITIONAL_POLICY = [
    { value: 'Alternate', label: 'Alternate' },
    { value: 'Additional', label: 'Additional' },
    { value: 'No', label: 'No' }
];

export const PRODUCT = [
    { value: '10YT20', label: '10 Year Term' },
    { value: '15YT20', label: '15 Year Term' },
    { value: '20YT20', label: '20 Year Term' },
    { value: '30YT20', label: '30 Year Term' }
];

export const TERM_2023_PRODUCT = [
    { value: '10T23', label: '10 Year Term' },
    { value: '15T23', label: '15 Year Term' },
    { value: '20T23', label: '20 Year Term' },
    { value: '30T23', label: '30 Year Term' }
];

export const CONVERSION_OPTIONS = [
    { value: 'None', label: 'None' },
    { value: 'Limited', label: 'Limited Conversion Period' },
    { value: 'Full', label: 'Full Conversion Period' }
];

export const CONVERSION_OPTION_TOOLTIP_TEXT = `Conversion Options are to determine the length of conversion period. 
    None - No conversion on base policy, 
    Limited- Available during the predefined Conversion Period, 
    Full - Allows conversion until the earlier of the full level premium period or age 70`;

export const BILLING_FREQUENCY = [
    { value: '0005', label: 'Monthly' },
    { value: '0004', label: 'Quarterly' },
    { value: '0002', label: 'Semi-Annual' },
    { value: '0001', label: 'Annual' }

];

export const BILLING_TYPE = [
    { value: '0001', label: 'EFT' },
    { value: '0002', label: 'Direct bill' },
    { value: '0003', label: 'List Bill' }
];

export const UNDERWRITING_CLASS = [
    { value: '0005', label: 'Super Preferred' },
    { value: '0001', label: 'Preferred' },
    { value: '0006', label: 'Super Standard' },
    { value: '0003', label: 'Standard' }

];

export const RATING = [
    { value: '0.5', label: 'Table 2' },
    { value: '0.75', label: 'Table 3' },
    { value: '1', label: 'Table 4' },
    { value: '1.25', label: 'Table 5' },
    { value: '1.5', label: 'Table 6' }

];

export const LICENSED_FINANCIAL_PROFESSIONAL = [
    { value: true, label: 'I am the soliciting financial professional' }
];

export const FIELD_OFFICE_CONTACT = [
    { value: false, label: 'I am completing this drop ticket on behalf of the soliciting financial professional' }
];

export const FACE_AMOUNT_MASK = createNumberMask({
    prefix: '',
    integerLimit: 11
});

export const PREMIUM_MASK = createNumberMask({
    prefix: '',
    integerLimit: 7,
    allowDecimal: true
});

export const PERCENTAGE_MASK = createNumberMask({
    prefix: '',
    integerLimit: 3,
    allowDecimal: true
});

export const BENEFICIARY_RELATIONS = [
    { relationRoleType: '0056', relationDescription: '0039', label: 'Aunt', value: 'Aunt' },
    { relationRoleType: '0058', label: 'Bank', value: 'Bank' },
    { relationRoleType: '0050', relationDescription: '0041', label: 'Brother', value: 'Brother' },
    { relationRoleType: '0050', relationDescription: '0043', label: 'Brother-in-Law', value: 'Brother-in-Law' },
    { relationRoleType: '0036', label: 'Business', value: 'Business' },
    { relationRoleType: '0037', label: 'Business Associate', value: 'Business Associate' },
    { relationRoleType: '0038', label: 'Business Partner', value: 'Business Partner' },
    { organizationFormType: '0008', label: 'Charity', value: 'Charity' },
    { relationRoleType: '0039', label: 'Child', value: 'Child' },
    { relationRoleType: '0059', label: 'Children', value: 'Children' },
    { relationRoleType: '0060', label: 'Children of the Insured', value: 'Children of the Insured' },
    { relationRoleType: '0061', label: 'Children this Marriage', value: 'Children this Marriage' },
    { relationRoleType: '0098', label: 'Children/StepChildren', value: 'Children/StepChildren' },
    { organizationFormType: '0009', label: 'Church', value: 'Church' },
    { relationRoleType: '0116', label: 'Co-Mortgager', value: 'Co-Mortgager' },
    { organizationFormType: '0010', label: 'College', value: 'College' },
    { relationRoleType: '0117', relationDescription: '0071', label: 'Common-Law Husband', value: 'Common-Law Husband' },
    { relationRoleType: '0117', relationDescription: '0072', label: 'Common-Law Wife', value: 'Common-Law Wife' },
    { organizationFormType: '0011', label: 'Company', value: 'Company' },
    { organizationFormType: '0002', label: 'Corporation', value: 'Corporation' },
    { relationRoleType: '0054', label: 'Cousin', value: 'Cousin' },
    { organizationFormType: '0012', label: 'Credit Union', value: 'Credit Union' },
    { relationRoleType: '0042', label: 'Creditor', value: 'Creditor' },
    { relationRoleType: '0062', label: 'Custodian', value: 'Custodian' },
    { relationRoleType: '0039', relationDescription: '0027', label: 'Daughter', value: 'Daughter' },
    { relationRoleType: '0039', relationDescription: '0046', label: 'Daughter-in-Law', value: 'Daughter-in-Law' },
    { relationRoleType: '0130', label: 'Domestic Partner', value: 'Domestic Partner' },
    { relationRoleType: '0025', label: 'Employee', value: 'Employee' },
    { relationRoleType: '0027', label: 'Employer', value: 'Employer' },
    { relationRoleType: '0064', label: 'Estate of Insured', value: 'Estate of Insured' },
    { relationRoleType: '0052', relationDescription: '0047', label: 'Ex-Husband', value: 'Ex-Husband' },
    { relationRoleType: '0052', relationDescription: '0048', label: 'Ex-Wife', value: 'Ex-Wife' },
    { relationRoleType: '0041', relationDescription: '0030', label: 'Father', value: 'Father' },
    { relationRoleType: '0053', relationDescription: '0050', label: 'Father-in-Law', value: 'Father-in-Law' },
    { relationRoleType: '0043', relationDescription: '0069', label: 'Fiance', value: 'Fiance' },
    { relationRoleType: '0043', relationDescription: '0070', label: 'Fiancee', value: 'Fiancee' },
    { relationRoleType: '0118', relationDescription: '0073', label: 'Foster Brother', value: 'Foster Brother' },
    { relationRoleType: '0119', label: 'Foster Children', value: 'Foster Children' },
    { relationRoleType: '0120', relationDescription: '0075', label: 'Foster Daughter', value: 'Foster Daughter' },
    { relationRoleType: '0121', relationDescription: '0077', label: 'Foster Father', value: 'Foster Father' },
    { relationRoleType: '0121', relationDescription: '0078', label: 'Foster Mother', value: 'Foster Mother' },
    { relationRoleType: '0122', label: 'Foster Parents', value: 'Foster Parents' },
    { relationRoleType: '0118', relationDescription: '0074', label: 'Foster Sister', value: 'Foster Sister' },
    { relationRoleType: '0120', relationDescription: '0076', label: 'Foster Son', value: 'Foster Son' },
    { relationRoleType: '0044', label: 'Friend', value: 'Friend' },
    { relationRoleType: '0065', label: 'Funeral Home', value: 'Funeral Home' },
    { relationRoleType: '0102', label: 'Godchildren', value: 'Godchildren' },
    { relationRoleType: '0103', relationDescription: '0058', label: 'Goddaughter', value: 'Goddaughter' },
    { relationRoleType: '0124', relationDescription: '0079', label: 'Godfather', value: 'Godfather' },
    { relationRoleType: '0124', relationDescription: '0080', label: 'Godmother', value: 'Godmother' },
    { relationRoleType: '0125', label: 'Godparents', value: 'Godparents' },
    { relationRoleType: '0103', relationDescription: '0059', label: 'Godson', value: 'Godson' },
    { relationRoleType: '0104', relationDescription: '0060', label: 'Grandaunt', value: 'Grandaunt' },
    { relationRoleType: '0045', label: 'Grandchild', value: 'Grandchild' },
    { relationRoleType: '0066', label: 'Grandchildren', value: 'Grandchildren' },
    { relationRoleType: '0045', relationDescription: '0031', label: 'Granddaughter', value: 'Granddaughter' },
    { relationRoleType: '0046', relationDescription: '0034', label: 'Grandfather', value: 'Grandfather' },
    { relationRoleType: '0046', relationDescription: '0033', label: 'Grandmother', value: 'Grandmother' },
    { relationRoleType: '0105', relationDescription: '0061', label: 'Grandnephew', value: 'Grandnephew' },
    { relationRoleType: '0105', relationDescription: '0062', label: 'Grandniece', value: 'Grandniece' },
    { relationRoleType: '0046', label: 'Grandparent', value: 'Grandparent' },
    { relationRoleType: '0067', label: 'Grandparents', value: 'Grandparents' },
    { relationRoleType: '0045', relationDescription: '0032', label: 'Grandson', value: 'Grandson' },
    { relationRoleType: '0104', relationDescription: '0081', label: 'Granduncle', value: 'Granduncle' },
    { relationRoleType: '0068', label: 'Great-Grandchildren', value: 'Great-Grandchildren' },
    { relationRoleType: '0069', relationDescription: '0051', label: 'Great-Granddaughter', value: 'Great-Granddaughter' },
    { relationRoleType: '0070', relationDescription: '0053', label: 'Great-Grandfather', value: 'Great-Grandfather' },
    { relationRoleType: '0070', relationDescription: '0054', label: 'Great-Grandmother', value: 'Great-Grandmother' },
    { relationRoleType: '0071', label: 'Great-Grandparents', value: 'Great-Grandparents' },
    { relationRoleType: '0069', relationDescription: '0052', label: 'Great-Grandson', value: 'Great-Grandson' },
    { relationRoleType: '0110', label: 'Guardian', value: 'Guardian' },
    { organizationFormType: '0013', label: 'Hospital', value: 'Hospital' },
    { relationRoleType: '0040', relationDescription: '0035', label: 'Husband', value: 'Husband' },
    { relationRoleType: '0126', label: 'Institution', value: 'Institution' },
    { relationRoleType: '0047', label: 'Legal Guardian', value: 'Legal Guardian' },
    { relationRoleType: '0072', label: 'Mortgager', value: 'Mortgager' },
    { relationRoleType: '0041', relationDescription: '0029', label: 'Mother', value: 'Mother' },
    { relationRoleType: '0053', relationDescription: '0049', label: 'Mother-in-Law', value: 'Mother-in-Law' },
    { relationRoleType: '0055', relationDescription: '0055', label: 'Nephew', value: 'Nephew' },
    { relationRoleType: '0055', relationDescription: '0056', label: 'Niece', value: 'Niece' },
    { organizationFormType: '0014', label: 'Nursing Home', value: 'Nursing Home' },
    { relationRoleType: '0076', label: 'Other', value: 'Other' },
    { relationRoleType: '0001', label: 'Owner', value: 'Owner' },
    { relationRoleType: '0041', label: 'Parent', value: 'Parent' },
    { relationRoleType: '0073', label: 'Parents', value: 'Parents' },
    { relationRoleType: '0053', label: 'Parent-in-Law', value: 'Parent-in-Law' },
    { relationRoleType: '0038', label: 'Partner', value: 'Partner' },
    { organizationFormType: '0001', label: 'Partnership', value: 'Partnership' },
    { organizationFormType: '0007', trustType: '0001', label: 'Present Trust', value: 'Present Trust' },
    { relationRoleType: '0050', label: 'Sibling', value: 'Sibling' },
    { relationRoleType: '0050', relationDescription: '0042', label: 'Sister', value: 'Sister' },
    { relationRoleType: '0050', relationDescription: '0044', label: 'Sister-in-Law', value: 'Sister-in-Law' },
    { relationRoleType: '0039', relationDescription: '0028', label: 'Son', value: 'Son' },
    { relationRoleType: '0039', relationDescription: '0045', label: 'Son-in-Law', value: 'Son-in-Law' },
    { relationRoleType: '0040', label: 'Spouse', value: 'Spouse' },
    { relationRoleType: '0051', label: 'Step Child', value: 'Step Child' },
    { relationRoleType: '0106', relationDescription: '0063', label: 'Stepbrother', value: 'Stepbrother' },
    { relationRoleType: '0107', label: 'Stepchildren', value: 'Stepchildren' },
    { relationRoleType: '0051', relationDescription: '0064', label: 'Stepdaughter', value: 'Stepdaughter' },
    { relationRoleType: '0049', relationDescription: '0037', label: 'Stepfather', value: 'Stepfather' },
    { relationRoleType: '0049', relationDescription: '0038', label: 'Stepmother', value: 'Stepmother' },
    { relationRoleType: '0049', label: 'Stepparent', value: 'Stepparent' },
    { relationRoleType: '0127', label: 'Stepparents', value: 'Stepparents' },
    { relationRoleType: '0106', relationDescription: '0065', label: 'Stepsister', value: 'Stepsister' },
    { relationRoleType: '0051', relationDescription: '0066', label: 'Stepson', value: 'Stepson' },
    { organizationFormType: '0016', label: 'Synagogue', value: 'Synagogue' },
    { organizationFormType: '0007', trustType: '0002', label: 'Testamentary Trust', value: 'Testamentary Trust' },
    { organizationFormType: '0007', label: 'Trust', value: 'Trust' },
    { relationRoleType: '0056', relationDescription: '0040', label: 'Uncle', value: 'Uncle' },
    { organizationFormType: '0017', label: 'University', value: 'University' },
    { relationRoleType: '0000', relationDescription: '0000', label: 'Unknown', value: 'Unknown' },
    { relationRoleType: '0040', relationDescription: '0036', label: 'Wife', value: 'Wife' }
];

export const LANGUAGES = [
    { value: 'AC', label: 'Acholi' },
    { value: 'AF', label: 'Afrikaans' },
    { value: 'AH', label: 'Amharic' },
    { value: 'AK', label: 'Akan' },
    { value: 'AL', label: 'Albanian' },
    { value: 'AM', label: 'Armenian' },
    { value: 'AN', label: 'Aklan' },
    { value: 'AR', label: 'Arabic' },
    { value: 'AS', label: 'Assyrian' },
    { value: 'AZ', label: 'Azerbaijani' },
    { value: 'BA', label: 'Bambara' },
    { value: 'BE', label: 'Belorussian' },
    { value: 'BH', label: 'Bhojpuri' },
    { value: 'BI', label: 'Bicolano' },
    { value: 'BL', label: 'Bulgarian' },
    { value: 'BN', label: 'Bengali' },
    { value: 'BO', label: 'Bosnian' },
    { value: 'BS', label: 'Basque' },
    { value: 'BU', label: 'Burmese' },
    { value: 'CA', label: 'Cantonese (Chinese)' },
    { value: 'CB', label: 'Cambodian' },
    { value: 'CC', label: 'Chao-Chou' },
    { value: 'CE', label: 'Cebuano' },
    { value: 'CG', label: 'Chungshanese' },
    { value: 'CH', label: 'Chaochow' },
    { value: 'CI', label: 'Chinese' },
    { value: 'CL', label: 'Chaldean' },
    { value: 'CM', label: 'Chamorro' },
    { value: 'CR', label: 'Croatian' },
    { value: 'CT', label: 'Catalan' },
    { value: 'CU', label: 'Chuukese' },
    { value: 'CV', label: 'Chavacano' },
    { value: 'CW', label: 'Chichewa' },
    { value: 'CZ', label: 'Czech' },
    { value: 'DA', label: 'Dakota' },
    { value: 'DK', label: 'Dinka' },
    { value: 'DN', label: 'Danish' },
    { value: 'DR', label: 'Dari' },
    { value: 'DU', label: 'Dutch' },
    { value: 'EN', label: 'English' },
    { value: 'ES', label: 'Estonian' },
    { value: 'EW', label: 'Ewe' },
    { value: 'FA', label: 'Farsi' },
    { value: 'FC', label: 'French Creole' },
    { value: 'FF', label: 'Fulani' },
    { value: 'FG', label: 'Figian' },
    { value: 'FI', label: 'Fijian' },
    { value: 'FL', label: 'Flemish' },
    { value: 'FN', label: 'Finnish' },
    { value: 'FO', label: 'Fon' },
    { value: 'FR', label: 'French' },
    { value: 'FU', label: 'Fukienese' },
    { value: 'FZ', label: 'Fuzhou' },
    { value: 'GA', label: 'Ga' },
    { value: 'GD', label: 'Gaddang' },
    { value: 'GE', label: 'German' },
    { value: 'GL', label: 'Gaelic' },
    { value: 'GM', label: 'Guamanian' },
    { value: 'GO', label: 'Georgian' },
    { value: 'GR', label: 'Greek' },
    { value: 'GU', label: 'Gujarati' },
    { value: 'HA', label: 'Hawaiian' },
    { value: 'HB', label: 'Hubei' },
    { value: 'HD', label: 'Hindi' },
    { value: 'HE', label: 'Hebrew' },
    { value: 'HH', label: 'Hunanese' },
    { value: 'HI', label: 'Haitian Creole' },
    { value: 'HK', label: 'Hakka' },
    { value: 'HM', label: 'Hmong' },
    { value: 'HN', label: 'Hungarian' },
    { value: 'HU', label: 'Hausa' },
    { value: 'IB', label: 'Ibanag' },
    { value: 'IC', label: 'Icelandic' },
    { value: 'IG', label: 'Ilonggo' },
    { value: 'IL', label: 'Ilocano' },
    { value: 'IN', label: 'Indonesian' },
    { value: 'IO', label: 'Ibo' },
    { value: 'IT', label: 'Italian' },
    { value: 'JK', label: 'Jakartanese' },
    { value: 'JP', label: 'Japanese' },
    { value: 'JV', label: 'Javanese' },
    { value: 'KA', label: 'Kanjobal' },
    { value: 'KB', label: 'Krahn' },
    { value: 'KH', label: 'Khamu' },
    { value: 'KI', label: 'Krio' },
    { value: 'KK', label: 'Kikuyu' },
    { value: 'KM', label: 'Khmer (Cambodian)' },
    { value: 'KN', label: 'Karen' },
    { value: 'KO', label: 'Korean' },
    { value: 'KR', label: 'Kirghiz' },
    { value: 'KS', label: 'Kashmiri' },
    { value: 'KU', label: 'Kurdish' },
    { value: 'KZ', label: 'Kazakh' },
    { value: 'LA', label: 'Latvian' },
    { value: 'LG', label: 'Luganda' },
    { value: 'LI', label: 'Lingala' },
    { value: 'LO', label: 'Lao' },
    { value: 'LT', label: 'Lithuanian' },
    { value: 'LU', label: 'Luxembourgeois' },
    { value: 'MA', label: 'Macendonian' },
    { value: 'MB', label: 'Malayan' },
    { value: 'MD', label: 'Mandinka' },
    { value: 'ME', label: 'Marshallese' },
    { value: 'MG', label: 'Magahi' },
    { value: 'MH', label: 'Maithili' },
    { value: 'MI', label: 'Mien' },
    { value: 'MK', label: 'Mankon' },
    { value: 'ML', label: 'Malayalam' },
    { value: 'MM', label: 'Monogolian' },
    { value: 'MN', label: 'Mandarin (Chinese)' },
    { value: 'MO', label: 'Mandingo' },
    { value: 'MR', label: 'Marathi' },
    { value: 'MS', label: 'Maay Somali' },
    { value: 'MT', label: 'Maltese' },
    { value: 'MX', label: 'Mixteco' },
    { value: 'MY', label: 'Malagasy' },
    { value: 'MZ', label: 'Malay' },
    { value: 'NA', label: 'Nanjing' },
    { value: 'NE', label: 'Neapolitan' },
    { value: 'NI', label: 'Ninponese' },
    { value: 'NO', label: 'Norwegian' },
    { value: 'NP', label: 'Nepali' },
    { value: 'NU', label: 'Nuer' },
    { value: 'NV', label: 'Navajo' },
    { value: 'OI', label: 'Oriya' },
    { value: 'OR', label: 'Oromo' },
    { value: 'PA', label: 'Pao-An' },
    { value: 'PC', label: 'Portuguese Creole' },
    { value: 'PL', label: 'Palestinian' },
    { value: 'PM', label: 'Pampangan' },
    { value: 'PN', label: 'Pangasinan' },
    { value: 'PO', label: 'Polish' },
    { value: 'PP', label: 'Papiamento' },
    { value: 'PR', label: 'Portuguese' },
    { value: 'PS', label: 'Pashto' },
    { value: 'PT', label: 'Pampanto' },
    { value: 'PU', label: 'Punjabi' },
    { value: 'QU', label: 'Quechua' },
    { value: 'RO', label: 'Romanian' },
    { value: 'RU', label: 'Russian' },
    { value: 'SA', label: 'Samoan' },
    { value: 'SC', label: 'Serbo-Croatian' },
    { value: 'SD', label: 'Sindhu' },
    { value: 'SE', label: 'Swedish' },
    { value: 'SG', label: 'Senegalese' },
    { value: 'SH', label: 'Shanghai' },
    { value: 'SI', label: 'Sicilian' },
    { value: 'SL', label: 'Slovak' },
    { value: 'SM', label: 'Somali' },
    { value: 'SN', label: 'Sinhalese' },
    { value: 'SP', label: 'Spanish' },
    { value: 'SR', label: 'Serbian' },
    { value: 'SS', label: 'Shanghainese' },
    { value: 'SU', label: 'Suchown' },
    { value: 'SV', label: 'Slovenian' },
    { value: 'SW', label: 'Swahili' },
    { value: 'SZ', label: 'Szechuan' },
    { value: 'TA', label: 'Tagalog' },
    { value: 'TB', label: 'Tibetan' },
    { value: 'TC', label: "Tohono O'Odhan" },
    { value: 'TG', label: 'Tigre' },
    { value: 'TH', label: 'Thai' },
    { value: 'TI', label: 'Tigrinya' },
    { value: 'TJ', label: 'Tajiki' },
    { value: 'TK', label: 'Turkmen' },
    { value: 'TL', label: 'Telugu' },
    { value: 'TM', label: 'Tamil' },
    { value: 'TN', label: 'Tongan' },
    { value: 'TO', label: 'Toishanese' },
    { value: 'TR', label: 'Turkish' },
    { value: 'TS', label: 'Tshiluba' },
    { value: 'TT', label: 'Tigrigna' },
    { value: 'TU', label: 'Toucouleur' },
    { value: 'TW', label: 'Taiwanese' },
    { value: 'TX', label: 'Twi' },
    { value: 'UK', label: 'Ukrainian' },
    { value: 'UM', label: 'Urmese' },
    { value: 'UR', label: 'Urdu' },
    { value: 'VI', label: 'Vietnamese' },
    { value: 'VS', label: 'Visayan' },
    { value: 'WO', label: 'Wolof' },
    { value: 'WU', label: 'Wuxinese' },
    { value: 'WW', label: 'Waray-Waray' },
    { value: 'YI', label: 'Yiddish' },
    { value: 'YO', label: 'Yoruba' },
    { value: 'ZA', label: 'Zambal' }

];

export const FULL_SSN_MASK = [
    /[\dX]/, /[\dX]/, /[\dX]/, '-', /[\dX]/, /[\dX]/, '-', /\d/, /\d/, /\d/, /\d/
];

export const FOUR_DIGIT_SSN_MASK = [
    /\d/, /\d/, /\d/, /\d/
];

export const maskSsn = (event: any) => {
    const { value } = event.target;
    if (SSN.test(value)) {
        const maskedSsn = value.substring(0, 7).replace(/\d/g, 'X') + value.substring(7);
        event.target.value = maskedSsn;
    }
};

export const DETAIL_CODE_MASK = [
    /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/
];

export const OFFICE_NUMBER_MASK = [
    /\d/, /\d/, /\d/, /\d/, /\d/
];

export const OTHER_DETAIL_CODE = [
    { value: 'otherDetailCode', label: 'Other' }
];

export const UNKNOWN_DETAIL_CODE = [
    { value: 'unknownDetailCode', label: 'Unknown' }
];

export const INSURED_STEP = 'Proposed Insured';
export const OWNER_STEP = 'Owner';
export const COVERAGE_STEP = 'Coverage';
export const FINPRO_STEP = 'Financial Professional';
export const ATTESTATION_STEP = 'Attestation/Submission';

export const FL_STATE_WRITTEN_CODE = '0009';
export const IN_STATE_WRITTEN_CODE = '0013';
