import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import '@pds/checkbox/checkbox.min.css';

function CheckBoxField(props: InferProps<typeof CheckBoxField.propTypes>) {
    return (
        <div className="pds-checkbox">
            <input id={props.id}
                data-testid={props.id}
                type="checkbox"
                disabled={props.disabled}
                onBlur={(event) => props.onBlur?.(event)}
                onChange={props.onChange}
                {...props.register(props.name)}
                defaultChecked={props.defaultChecked}
            />
            <label htmlFor={props.id}>{props.labelText}</label>
        </div>
    );
}

CheckBoxField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.any.isRequired,
    register: PropTypes.any.isRequired,
    maxLength: PropTypes.number,
    errors: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any,
    labelText: PropTypes.string,
    defaultChecked: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};

CheckBoxField.defaultProps = {
    maxLength: undefined,
    errors: undefined,
    onBlur: undefined,
    onChange: undefined,
    labelText: '',
    required: false,
    defaultChecked: false,
    disabled: false
};

export default CheckBoxField;
