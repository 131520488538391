import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ErrorMessages from '../../validations/ErrorMessages';

function ErrorSpan(props: InferProps<typeof ErrorSpan.propTypes>) {
    const getErrorType = () => {
        const nameSplitArray = props.name.split('.');
        if (nameSplitArray.length > 1) {
            return props.errors
                && props.errors[nameSplitArray[0]]
                && props.errors[nameSplitArray[0]][nameSplitArray[1]][`${nameSplitArray[2]}`]?.type;
        }
        return props.errors && props.errors[props.name]?.type;
    };

    const getErrorMessages = () => {
        const nameSplitArray = props.name.split('.');
        if (nameSplitArray.length > 1) {
            return ErrorMessages[nameSplitArray[0]][`${nameSplitArray[2]}`][`${getErrorType()}`];
        }
        return ErrorMessages[props.name][`${getErrorType()}`];
    };

    return (props.hasError
        && (
            <span className="pds-error" id={`${props.name}Error`}>
                {getErrorMessages()}
            </span>
        )
    );
}

ErrorSpan.propTypes = {
    hasError: PropTypes.any,
    name: PropTypes.any,
    errors: PropTypes.any
};

ErrorSpan.defaultProps = {
    hasError: false,
    name: undefined,
    errors: undefined
};

export default ErrorSpan;
