import React, { useEffect } from 'react';
import '@pds/core/core.min.css';
import { useHistory } from 'react-router-dom';

function Submit() {
    const history = useHistory();
    const STAGING_HOSTNAME = 'insurance.finpro.staging.principal.com';
    const PROD_HOSTNAME = 'insurance.finpro.principal.com';
    const DROP_TICKET_PUBLIC_URL_PROD = 'https://insurance.finpro.principal.com/public-drop-ticket';
    const DROP_TICKET_SECURE_URL_PROD = 'https://insurance.finpro.principal.com/drop-ticket';
    const DROP_TICKET_PUBLIC_URL_STAGING = 'https://insurance.finpro.staging.principal.com/public-drop-ticket';
    const DROP_TICKET_SECURE_URL_STAGING = 'https://insurance.finpro.staging.principal.com/drop-ticket';
    const DROP_TICKET_PUBLIC_URL_DEV = 'https://insurance.finpro.dev.principal.com/public-drop-ticket';
    const DROP_TICKET_SECURE_URL_DEV = 'https://insurance.finpro.dev.principal.com/drop-ticket';

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                const hostname = window.location.hostname;
                if (PROD_HOSTNAME === hostname) {
                    if (process.env.ACCESS_LEVEL === 'public') {
                        window.location.assign(DROP_TICKET_PUBLIC_URL_PROD);
                    } else if (process.env.ACCESS_LEVEL === 'secure') {
                        window.location.assign(DROP_TICKET_SECURE_URL_PROD);
                    }
                } else if (STAGING_HOSTNAME === hostname) {
                    if (process.env.ACCESS_LEVEL === 'public') {
                        window.location.assign(DROP_TICKET_PUBLIC_URL_STAGING);
                    } else if (process.env.ACCESS_LEVEL === 'secure') {
                        window.location.assign(DROP_TICKET_SECURE_URL_STAGING);
                    }
                } else if (process.env.ACCESS_LEVEL === 'public') {
                    window.location.assign(DROP_TICKET_PUBLIC_URL_DEV);
                } else if (process.env.ACCESS_LEVEL === 'secure') {
                    window.location.assign(DROP_TICKET_SECURE_URL_DEV);
                }
            }
        };
    }, [history]);

    return (
        <div className="container pds-container">
            <div className="row col-sm-9 col-lg-10 pds-util-padding-bottom-16">
                <p className="pds-typography-h6">Submission Complete</p>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-10 col-lg-8">
                    Thank You!
                    <br />
                    Your Drop Ticket has been submitted.
                    <br />
                    A confirmation e-mail containing details of this application has been sent to the Office Contact.
                </div>
            </div>
            <br />
        </div>
    );
}

export default Submit;
