class RestServiceError extends Error {

    status: number;

    constructor(message: string | undefined, status: number) {
        super(message);
        this.status = status;
        this.name = 'RestServiceError';
    }

}

export default RestServiceError;
