import React, { useEffect } from 'react';
import '@pds/core/core.min.css';
import { useHistory } from 'react-router-dom';

function SubmitToAdvisor() {
    const history = useHistory();
    const STAGING_HOSTNAME = 'insurance.finpro.staging.principal.com';
    const PROD_HOSTNAME = 'insurance.finpro.principal.com';
    const DROP_TICKET_SECURE_URL_PROD = 'https://insurance.finpro.principal.com/drop-ticket';
    const DROP_TICKET_SECURE_URL_STAGING = 'https://insurance.finpro.staging.principal.com/drop-ticket';
    const DROP_TICKET_SECURE_URL_DEV = 'https://insurance.finpro.dev.principal.com/drop-ticket';

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                const hostname = window.location.hostname;
                if (PROD_HOSTNAME === hostname) {
                    window.location.assign(DROP_TICKET_SECURE_URL_PROD);
                } else if (STAGING_HOSTNAME === hostname) {
                    window.location.assign(DROP_TICKET_SECURE_URL_STAGING);
                } else {
                    window.location.assign(DROP_TICKET_SECURE_URL_DEV);
                }
            }
        };
    }, [history]);

    return (
        <div className="container pds-container">
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <h4>Submission Complete</h4>
                    <p>
                        Thank You!
                        <br />
                        Your Drop Ticket has been submitted.
                        <br />
                        An e-mail containing a link to the case has been sent to the financial professional.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SubmitToAdvisor;
