import React, { useEffect, useState } from 'react';
import { UserData } from 'react-oidc';
import PropTypes, { InferProps } from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import '@pds/typography/typography.min.css';
import '@pds/core/core.min.css';
import '@pds/util/util.min.css';
import '@pds/radio/radio.min.css';
import '@pds/input/input.min.css';
import '@pds/label/label.min.css';
import '@pds/select/select.min.css';
import '@pds/button/button.min.css';
import '@pds/erroredInput/erroredInput.min.css';
import '@pds/alert/alert.min.css';
import Icon from '@pds-react/icon';
import * as Constants from '../utility/constants';
import TextField from './form-fields/TextField';
import RadioField from './form-fields/RadioField';
import MaskedInput from './form-fields/MaskedInput';
import SelectField from './form-fields/SelectField';
import AdditionalFinPro from './form-fields/AdditionalFinPro';
import StepIndicator from './commons/StepIndicator';
import submitPartialDropTicket from '../services/submit-partial-drop-ticket';
import ErrorModal from './commons/ErrorModal';

function Advisor(props: InferProps<typeof Advisor.propTypes>) {
    const { register, formState: { errors }, handleSubmit, watch, control, setValue, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'additionalFinPros',
        control
    });

    const history = useHistory();
    const [isVerifying, setVerificationState] = useState(false);
    const [isSubmitToFinPro, setSubmitToFinProState] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isError, setIsError] = useState(false);
    const [selectedDetailCode, setSelectedDetailCode] = useState<string | null | undefined>(undefined);
    const [detailCodes, setDetailCodes] = useState([{}]);
    const [isFinancialProfessionalFound, setFinancialProfessionalState] = useState(
        props.requestData.isFinancialProfessionalFound ? props.requestData.isFinancialProfessionalFound : false
    );
    const [primaryFinPro, setPrimaryFinPro] = useState(() => {
        if (props.requestData.primaryFinPro) {
            return props.requestData.primaryFinPro;
        } else if (props.requestData.isPartialDropTicket) {
            return setPrimaryFinProForPartial();
        } else {
            return {};
        }
    });
    const [showAdditionalFinProSection, setShowAdditionalFinProSection] = useState(
        props.requestData.showAdditionalFinProSection ? props.requestData.showAdditionalFinProSection : !!props.requestData.isPartialDropTicket
    );
    const [isValidServicingPercentage, setServicingPercentageErrorResponse] = useState(false);
    const [isValidSellingPercentage, setSellingPercentageErrorResponse] = useState(false);
    const [bgaSpecificOfficeDetails, setBgaSpecificOfficeDetails] = useState(() => {
        if (props.requestData.bgaSpecificOfficeDetails) {
            return props.requestData.bgaSpecificOfficeDetails;
        } else {
            return {};
        }
    });
    const [primaryFinProEnterpriseId, setPrimaryFinProEnterpriseId] = useState<string | null | undefined>(props.requestData.primaryFinProEnterpriseId);
    const primaryFinProFirstName = watch('primaryFinProFirstName');
    const primaryFinProMiddleName = watch('primaryFinProMiddleName');
    const primaryFinProLastName = watch('primaryFinProLastName');
    const primaryFinProDetailCode = watch('primaryFinProDetailCode');
    const primaryFinProSsn = watch('primaryFinProSsn');
    const additionalFinProResponse = watch('additionalFinProResponse');
    const licensedFinPro = watch('licensedFinPro');
    const primaryDetailCode = watch('primaryDetailCode');
    const otherPrimaryDetailCode = watch('otherPrimaryDetailCode');

    const isPublicDropTicket = props.requestData.publicDropTicket;
    const isBgaSpecificDropTicket = props.requestData.bgaSpecificDropTicket;

    const { user } = React.useContext(UserData);
    const authToken = user !== null ? user.access_token : '';

    const onSubmit = async (finProDetails: any) => {
        let servicingPercentageErrorResponse = isValidServicingPercentage;
        let sellingPercentageErrorResponse = isValidSellingPercentage;
        if (additionalFinProResponse === 'true') {
            const additionalFinProsArray = JSON.parse(JSON.stringify(props.requestData.additionalFinPros));
            additionalFinProsArray.forEach((finPro: any, index: number) => {
                finProDetails.additionalFinPros[index].finProFirstName = finPro.finProFirstName;
                finProDetails.additionalFinPros[index].finProMiddleName = finPro.finProMiddleName;
                finProDetails.additionalFinPros[index].finProLastName = finPro.finProLastName;
                finProDetails.additionalFinPros[index].finProSsn = finPro.finProSsn;
                finProDetails.additionalFinPros[index].finProDetailCode = finPro.finProDetailCode;
                finProDetails.additionalFinPros[index].finProPhoneNumber = finPro.finProPhoneNumber;
                finProDetails.additionalFinPros[index].finProEmailAddress = finPro.finProEmailAddress;
            });
        } else {
            servicingPercentageErrorResponse = true;
            sellingPercentageErrorResponse = true;
        }
        if (servicingPercentageErrorResponse && sellingPercentageErrorResponse) {
            if (!isPublicDropTicket && !isFinancialProfessionalSubmitting()) {
                try {
                    setSubmitToFinProState(true);
                    const data = { ...props.requestData, ...finProDetails };
                    await submitPartialDropTicket(data, authToken);
                    setSubmitToFinProState(false);
                    history.push(Constants.SUBMIT_TO_FINPRO_PATH);
                } catch (error) {
                    setShowErrorModal(true);
                    setSubmitToFinProState(false);
                }
            } else if (!(isPublicDropTicket && isBgaSpecificDropTicket && isPrimaryDetailCodeInvalid() && isOtherPrimaryDetailCodeInvalid())) {
                let checkedDetailCode = selectedDetailCode;
                if (checkedDetailCode) {
                    checkedDetailCode = primaryFinProDetailCode;
                    setSelectedDetailCode(primaryFinProDetailCode);
                }
                props.setRequest({
                    ...props.requestData,
                    ...finProDetails,
                    selectedDetailCode: checkedDetailCode,
                    isFinancialProfessionalFound,
                    showAdditionalFinProSection,
                    primaryFinPro,
                    bgaSpecificOfficeDetails,
                    primaryFinProEnterpriseId
                });
                history.push(Constants.ATTESTATION_PATH);
            }
        }
    };

    const showPrevious = () => {
        if (additionalFinProResponse === 'true') {
            const additionalFinProsArray = JSON.parse(JSON.stringify(getValues('additionalFinPros')));
            additionalFinProsArray.forEach((finPro: any, index: number) => {
                props.requestData.additionalFinPros[index].sellingCommissionPercentage = finPro.sellingCommissionPercentage;
                props.requestData.additionalFinPros[index].servicingCommissionPercentage = finPro.servicingCommissionPercentage;
            });
            setValue('additionalFinPros', props.requestData.additionalFinPros);
        }
        props.setRequest({
            ...props.requestData,
            isFinancialProfessionalFound,
            showAdditionalFinProSection,
            primaryFinPro,
            bgaSpecificOfficeDetails,
            primaryFinProEnterpriseId
        });
        history.push(Constants.COVERAGE_PATH);
    };

    const isLicenseNumberRequired = () => {
        return props.requestData.stateWritten === Constants.FL_STATE_WRITTEN_CODE || props.requestData.stateWritten === Constants.IN_STATE_WRITTEN_CODE;
    };

    const isFinancialProfessionalAddressRequired = () => {
        return props.requestData.stateWritten === Constants.IN_STATE_WRITTEN_CODE;
    };

    const isDetailCodeListEmpty = (list: Array<string>) => {
        if (list !== undefined && list !== null) {
            return list.length === 0;
        }
        return true;
    };

    const setDetailCodeMap = (detailCodeList: Array<string>) => {
        let detailCodeMap: any[] = [];
        detailCodeList.forEach((detailCode) => {
            detailCodeMap = [
                ...detailCodeMap,
                {
                    label: detailCode,
                    value: detailCode
                }
            ];
        });
        if (isPublicDropTicket) {
            setDetailCodes(detailCodeMap);
        }
        return detailCodeMap;
    };

    const isDetailCodeNotPresent = () => {
        return isPublicDropTicket && isFinancialProfessionalFound && selectedDetailCode === null;
    };

    const isDetailCodePresent = () => {
        return isPublicDropTicket && isFinancialProfessionalFound && selectedDetailCode;
    };

    const resetSearchedFinancialProfessionalDetails = () => {
        setFinancialProfessionalState(false);
    };

    const isFinancialProfessionalSubmitting = () => {
        return licensedFinPro === 'true' || props.requestData.isPartialDropTicket;
    };

    const getOfficeName = (offices: any) => {
        const officeNumber = parseInt(props.requestData.bgaSpecificOfficeNumber);
        if (offices[officeNumber]) {
            return offices[officeNumber];
        }
        return null;
    };

    const setOfficeDetailsOnResponse = (offices: any) => {
        if (offices) {
            setBgaSpecificOfficeDetails(offices);
        }
        const officeName = getOfficeName(offices);
        const officeNumber = props.requestData.bgaSpecificOfficeNumber;
        setValue('officeName', officeName);
        setValue('officeNumber', officeNumber);
        setValue('otherPrimaryDetailCodeOfficeName', officeName);
        setValue('otherPrimaryDetailCodeOfficeNumber', officeNumber);
        setValue('unknownPrimaryDetailCodeOfficeName', officeName);
        setValue('unknownPrimaryDetailCodeOfficeNumber', officeNumber);
    };

    const verifyFinancialProfessional = () => {
        setVerificationState(true);
        axios({
            method: 'post',
            url: `${getDropTicketFinancialProfessionalUrl()}`,
            data: {
                advisor: { firstName: primaryFinProFirstName, lastName: primaryFinProLastName, ssn: primaryFinProSsn },
                officeNumbers: isBgaSpecificDropTicket ? [props.requestData.bgaSpecificOfficeNumber] : []
            }
        }).then(res => {
            const primaryFinProDetails: any = res.data;
            if (!isDetailCodeListEmpty(primaryFinProDetails.advisor.detailCodesList)) {
                setSelectedDetailCode(primaryFinProDetails.advisor.detailCodesList[0]);
                setDetailCodeMap(primaryFinProDetails.advisor.detailCodesList);
            } else {
                setSelectedDetailCode('otherDetailCode');
            }
            if (isBgaSpecificDropTicket) {
                setOfficeDetailsOnResponse(primaryFinProDetails.offices);
            }
            setPrimaryFinProEnterpriseId(primaryFinProDetails.advisor.enterpriseId);
            setVerificationState(false);
            setFinancialProfessionalState(true);
        }).catch(err => {
            const status = err.response.status;
            if (status === 404 || status === 400) {
                setSelectedDetailCode(null);
                setPrimaryFinProEnterpriseId(null);
                setFinancialProfessionalState(true);
                if (isBgaSpecificDropTicket) {
                    setOfficeDetailsOnResponse(err.response.data.offices);
                }
            } else {
                setShowErrorModal(true);
            }
            setVerificationState(false);
        });
    };

    function getDropTicketFinancialProfessionalUrl() {
        return process.env.DROP_TICKET_FINPRO_URL_V2;
    }

    function setPrimaryFinProForPartial() {
        const finPro: any = {};
        finPro.finProFirstName = props.requestData.primaryFinProFirstName;
        finPro.finProMiddleName = props.requestData.primaryFinProMiddleName;
        finPro.finProLastName = props.requestData.primaryFinProLastName;
        finPro.finProSsn = props.requestData.primaryFinProSsn;
        finPro.finProDetailCode = props.requestData.primaryFinProDetailCode;
        return finPro;
    }

    const resetPrimaryFinProDetails = () => {
        setValue('primaryFinProFirstName', null);
        setValue('primaryFinProMiddleName', null);
        setValue('primaryFinProLastName', null);
        setValue('primaryFinProSsn', null);
        setValue('primaryFinProEmailAddress', null);
    };

    const initializePrimaryFinProDetails = () => {
        setValue('primaryFinProFirstName', props.requestData.primaryFinProFirstName);
        setValue('primaryFinProMiddleName', props.requestData.primaryFinProMiddleName);
        setValue('primaryFinProLastName', props.requestData.primaryFinProLastName);
        setValue('primaryFinProSsn', props.requestData.primaryFinProSsn);
        setValue('primaryFinProEmailAddress', props.requestData.primaryFinProEmailAddress);
    };

    const isPrimaryDetailCodeInvalid = () => {
        if (primaryDetailCode) {
            const officeNumber = primaryDetailCode.substring(0, 5);
            return !(props.requestData.bgaSpecificOfficeNumber === officeNumber);
        }
        return false;
    };

    const isOtherPrimaryDetailCodeInvalid = () => {
        if (otherPrimaryDetailCode) {
            const officeNumber = otherPrimaryDetailCode.substring(0, 5);
            return !(props.requestData.bgaSpecificOfficeNumber === officeNumber);
        }
        return false;
    };

    const getValidOfficeNumber = () => {
        return props.requestData.bgaSpecificOfficeNumber.toString();
    };

    const getSecureFlowDefaultCheckedDetailCode = () => {
        return props.requestData.primaryFinProDetailCode !== null ? props.requestData.primaryFinProDetailCode
            : props.requestData.primaryFinProDetailCodes[0];
    };

    useEffect(() => {
        if (additionalFinProResponse === 'true') {
            const finPro: any = {};
            finPro.finProFirstName = primaryFinProFirstName;
            finPro.finProMiddleName = primaryFinProMiddleName;
            finPro.finProLastName = primaryFinProLastName;
            finPro.finProSsn = primaryFinProSsn;
            finPro.finProDetailCode = primaryFinProDetailCode;
            setPrimaryFinPro({ ...primaryFinPro, ...finPro });
        }
    }, [additionalFinProResponse]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (isPublicDropTicket && primaryFinProFirstName && primaryFinProLastName && primaryFinProSsn) {
            verifyFinancialProfessional();
        }
    }, [isError]);

    return (
        <div className="container pds-container">
            <StepIndicator step={Constants.FINPRO_STEP}
                isInsuredOwnerSame={props.requestData.insuredOwnerSame}
            />
            <br />
            <div className="row col-sm-9 col-lg-10">
                <p className="pds-typography-h3">Submitting Office</p>
            </div>
            <div className="row pds-util-float-right pds-util-padding-right-12">
                <label htmlFor="required">
                    <span className="pds-label-required">*</span>
                    <small className="pds-util-float-right" id="required">Required</small>
                </label>
            </div>
            <br />
            <form name="forms.advisor" onSubmit={handleSubmit(onSubmit)}>
                {(!isPublicDropTicket && !props.requestData.isPartialDropTicket) && (
                    <div>
                        <div className="row ">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label htmlFor="licensedFinPro">
                                            <b>Who is completing this drop ticket? </b>
                                        </label>
                                        <br />
                                    </div>
                                    <div className="row col-lg-12">
                                        <RadioField name="licensedFinPro"
                                            values={Constants.LICENSED_FINANCIAL_PROFESSIONAL}
                                            register={register}
                                            onChange={initializePrimaryFinProDetails}
                                        />
                                        <RadioField name="licensedFinPro"
                                            values={Constants.FIELD_OFFICE_CONTACT}
                                            register={register}
                                            onChange={resetPrimaryFinProDetails}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                )}
                {(licensedFinPro || isPublicDropTicket || props.requestData.isPartialDropTicket) && (
                    <div>
                        <div className="row">
                            <div className="col-lg-8">
                                <TextField id="fieldOfficeContactName"
                                    labelText="Contact name (the person we will keep updated on status)"
                                    name="fieldOfficeContactName"
                                    type="text"
                                    defaultValue={props.requestData.fieldOfficeContactName}
                                    register={register}
                                    errors={errors}
                                    required
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-lg-8">
                                <MaskedInput id="fieldOfficeContactPhoneNumber"
                                    labelText="Contact phone number"
                                    name="fieldOfficeContactPhoneNumber"
                                    mask={Constants.PHONE_MASK}
                                    defaultValue={props.requestData.fieldOfficeContactPhoneNumber}
                                    control={control}
                                    placeHolder="(999) 999-9999"
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-lg-8">
                                <TextField id="fieldOfficeContactEmailAddress"
                                    labelText="Contact email address"
                                    name="fieldOfficeContactEmailAddress"
                                    type="text"
                                    defaultValue={props.requestData.fieldOfficeContactEmailAddress}
                                    register={register}
                                    errors={errors}
                                    required
                                />
                            </div>
                        </div>
                        <br />
                        {(!isFinancialProfessionalSubmitting() && !isPublicDropTicket) && (
                            <div>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-8">
                                        <TextField id="officeName"
                                            labelText="Office Name"
                                            name="officeName"
                                            type="text"
                                            defaultValue={props.requestData.officeName}
                                            register={register}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-6 col-lg-8">
                                        <MaskedInput id="officeNumber"
                                            labelText="Office number"
                                            name="officeNumber"
                                            defaultValue={props.requestData.officeNumber}
                                            mask={Constants.OFFICE_NUMBER_MASK}
                                            control={control}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        <br />
                        <div className="row col-sm-12 col-md-10 col-lg-8">
                            <p className="pds-typography-h5">Primary Financial Professional</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <TextField id="primaryFinProFirstName"
                                    labelText="First name"
                                    name="primaryFinProFirstName"
                                    type="text"
                                    readOnly={isFinancialProfessionalSubmitting()}
                                    defaultValue={props.requestData.primaryFinProFirstName}
                                    register={register}
                                    errors={errors}
                                    onChange={resetSearchedFinancialProfessionalDetails}
                                    required
                                />
                            </div>
                            <div className="col-lg-4">
                                <TextField id="primaryFinProMiddleName"
                                    labelText="Middle name"
                                    name="primaryFinProMiddleName"
                                    type="text"
                                    readOnly={isFinancialProfessionalSubmitting()}
                                    defaultValue={props.requestData.primaryFinProMiddleName}
                                    register={register}
                                    errors={errors}
                                    onChange={resetSearchedFinancialProfessionalDetails}
                                />
                            </div>
                            <div className="col-lg-4">
                                <TextField id="primaryFinProLastName"
                                    labelText="Last name"
                                    name="primaryFinProLastName"
                                    type="text"
                                    readOnly={isFinancialProfessionalSubmitting()}
                                    defaultValue={props.requestData.primaryFinProLastName}
                                    register={register}
                                    errors={errors}
                                    onChange={resetSearchedFinancialProfessionalDetails}
                                    required
                                />
                            </div>
                        </div>
                        <br />
                        {isPublicDropTicket && (
                            <div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <MaskedInput id="primaryFinProSsn"
                                            labelText="SSN or Tax ID"
                                            name="primaryFinProSsn"
                                            mask={Constants.FULL_SSN_MASK}
                                            defaultValue={props.requestData.primaryFinProSsn}
                                            control={control}
                                            errors={errors}
                                            onBlur={Constants.maskSsn}
                                            onChange={resetSearchedFinancialProfessionalDetails}
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        {(isFinancialProfessionalSubmitting() && !isPublicDropTicket) && (
                            <div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <MaskedInput id="primaryFinProSsn"
                                            labelText="Last 4 digits of Social Security Number"
                                            name="primaryFinProSsn"
                                            mask={Constants.FOUR_DIGIT_SSN_MASK}
                                            defaultValue={props.requestData.primaryFinProSsn}
                                            control={control}
                                            errors={errors}
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        {isPublicDropTicket && (
                            <div>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-10">
                                        <div className="pds-util-padding-left-2">
                                            <button id="verifyFinPro"
                                                className="pds-button pds-button-primary"
                                                type="button"
                                                onClick={verifyFinancialProfessional}
                                                disabled={isVerifying}
                                            >
                                                Verify Financial Professional
                                            </button>
                                            {isVerifying && (
                                                <span className="text-center pds-util-margin-left-8">
                                                    <Icon name="loader" size={20} className="pds-util-padding-top-8" />
                                                    <span>Verifying...</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        {isDetailCodeNotPresent() && (
                            <div id="alertSearchAdvisor">
                                <div className="pds-alert pds-alert-error">
                                    <div className="pds-card-section">
                                        <div className="pds-alert-content" role="alert">
                                            Sorry, we were not able to verify the financial professional information provided. Please double check your entry. If it is correct then
                                            {' '}
                                            continue to complete the drop ticket, but work with your agency to get appointed.
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        {(isFinancialProfessionalFound || !isPublicDropTicket) && (
                            <div>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <SelectField id="insuredFinancialProfessionalRelationship"
                                            labelText="Proposed Insured relationship to the Financial Professional(s)?"
                                            name="insuredFinancialProfessionalRelationship"
                                            options={Constants.FINPRO_RELATIONS}
                                            register={register}
                                            errors={errors}
                                            defaultValue={props.requestData.insuredFinancialProfessionalRelationship}
                                            required
                                            hideValue
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-lg-8">
                                        <MaskedInput id="primaryFinProPhoneNumber"
                                            labelText="Financial professional mobile number"
                                            helperText="This will be used if authentication is required"
                                            name="primaryFinProPhoneNumber"
                                            mask={Constants.PHONE_MASK}
                                            defaultValue={props.requestData.primaryFinProPhoneNumber}
                                            control={control}
                                            placeHolder="(999) 999-9999"
                                            errors={errors}
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-lg-8">
                                        <TextField id="primaryFinProEmailAddress"
                                            labelText="Financial professional email address"
                                            name="primaryFinProEmailAddress"
                                            type="text"
                                            defaultValue={props.requestData.primaryFinProEmailAddress}
                                            register={register}
                                            errors={errors}
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                                {(isFinancialProfessionalAddressRequired()) && (
                                    <>
                                        <div>
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <TextField id="primaryFinProPostalAddressLine1"
                                                        labelText="Street address"
                                                        name="primaryFinProPostalAddressLine1"
                                                        placeHolder="Address line 1"
                                                        type="text"
                                                        register={register}
                                                        errors={errors}
                                                        defaultValue={props.requestData.primaryFinProPostalAddressLine1}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <TextField id="primaryFinProPostalAddressLine2"
                                                        name="primaryFinProPostalAddressLine2"
                                                        placeHolder="Address line 2"
                                                        type="text"
                                                        register={register}
                                                        errors={errors}
                                                        defaultValue={props.requestData.primaryFinProPostalAddressLine2}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <TextField id="primaryFinProPostalAddressCity"
                                                        labelText="City"
                                                        name="primaryFinProPostalAddressCity"
                                                        type="text"
                                                        register={register}
                                                        errors={errors}
                                                        defaultValue={props.requestData.primaryFinProPostalAddressCity}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <SelectField id="primaryFinProPostalAddressState"
                                                        labelText="State"
                                                        name="primaryFinProPostalAddressState"
                                                        options={Constants.STATES}
                                                        register={register}
                                                        errors={errors}
                                                        defaultValue={props.requestData.primaryFinProPostalAddressState}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <MaskedInput id="primaryFinProPostalAddressZip"
                                                        labelText="Zip"
                                                        name="primaryFinProPostalAddressZip"
                                                        mask={Constants.ZIP_MASK}
                                                        defaultValue={props.requestData.primaryFinProPostalAddressZip}
                                                        control={control}
                                                        errors={errors}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                )}
                                {isLicenseNumberRequired() && (
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <TextField id="primaryFinProLicenseNumberMandatory"
                                                labelText="License number"
                                                name="primaryFinProLicenseNumberMandatory"
                                                type="text"
                                                defaultValue={props.requestData.primaryFinProLicenseNumberMandatory}
                                                register={register}
                                                errors={errors}
                                                required
                                            />
                                        </div>
                                    </div>
                                )}
                                {!isLicenseNumberRequired() && (
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <TextField id="primaryFinProLicenseNumberNonMandatory"
                                                labelText="License number"
                                                name="primaryFinProLicenseNumberNonMandatory"
                                                type="text"
                                                defaultValue={props.requestData.primaryFinProLicenseNumberNonMandatory}
                                                register={register}
                                            />
                                        </div>
                                    </div>
                                )}
                                <br />
                            </div>
                        )}
                        {((!isFinancialProfessionalSubmitting() && !isPublicDropTicket) || isDetailCodeNotPresent()) && (
                            <div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <MaskedInput id="primaryDetailCode"
                                            labelText="Statement/Detail Code (e.g. 01234-56789)"
                                            name="primaryDetailCode"
                                            mask={Constants.DETAIL_CODE_MASK}
                                            defaultValue={props.requestData.primaryDetailCode}
                                            control={control}
                                            placeHolder="e.g. 01234-56789"
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <br />
                                {(isPublicDropTicket && isBgaSpecificDropTicket && isPrimaryDetailCodeInvalid()) && (
                                    <div className="row col-lg-12" id="primaryDetailCodeInvalidError">
                                        <span className="pds-error">
                                            Please enter valid detail code for office
                                            {' '}
                                            {getValidOfficeNumber()}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        {isDetailCodeNotPresent() && (
                            <div>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <TextField id="officeName"
                                            labelText="Office Name"
                                            name="officeName"
                                            type="text"
                                            defaultValue={props.requestData.officeName}
                                            register={register}
                                            errors={errors}
                                            readOnly={isPublicDropTicket && isBgaSpecificDropTicket}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-lg-4">
                                        <MaskedInput id="officeNumber"
                                            labelText="Office number"
                                            name="officeNumber"
                                            defaultValue={props.requestData.officeNumber}
                                            control={control}
                                            mask={Constants.OFFICE_NUMBER_MASK}
                                            errors={errors}
                                            readOnly={isPublicDropTicket && isBgaSpecificDropTicket}
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        {(isFinancialProfessionalSubmitting() || isDetailCodePresent()) && (
                            <div id="detailCodePresent">
                                <div className="row">
                                    <div className="col-lg-11">
                                        <label htmlFor="required">
                                            <div>
                                                <b>Statement/Detail code </b>
                                                <span className="pds-label-required">*</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="row col-lg-12">
                                    {selectedDetailCode !== 'otherDetailCode' && (
                                        <RadioField name="primaryFinProDetailCode"
                                            values={isFinancialProfessionalSubmitting() ? setDetailCodeMap(props.requestData.primaryFinProDetailCodes) : detailCodes}
                                            register={register}
                                            defaultChecked={isPublicDropTicket ? selectedDetailCode : getSecureFlowDefaultCheckedDetailCode()}
                                            errors={errors}
                                            verticalLayout
                                        />
                                    )}
                                    <RadioField name="primaryFinProDetailCode"
                                        values={Constants.OTHER_DETAIL_CODE}
                                        register={register}
                                        errors={errors}
                                    />
                                    {primaryFinProDetailCode === 'otherDetailCode' && (
                                        <div className="col-lg-12 pds-util-margin-top-16 pds-util-padding-left-30">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-9">
                                                    <MaskedInput id="otherPrimaryDetailCode"
                                                        labelText="Detail code (e.g. 12345-67890)"
                                                        name="otherPrimaryDetailCode"
                                                        mask={Constants.DETAIL_CODE_MASK}
                                                        defaultValue={props.requestData.otherPrimaryDetailCode}
                                                        control={control}
                                                        placeHolder="e.g. 01234-56789"
                                                        errors={errors}
                                                    />
                                                </div>
                                                <br />
                                                {(isPublicDropTicket && isBgaSpecificDropTicket && isOtherPrimaryDetailCodeInvalid()) && (
                                                    <div className="row col-lg-12" id="otherPrimaryDetailCodeInvalidError">
                                                        <span className="pds-error">
                                                            Please enter valid detail code for office
                                                            {' '}
                                                            {getValidOfficeNumber()}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-9">
                                                    <TextField id="otherPrimaryDetailCodeOfficeName"
                                                        labelText="Office Name"
                                                        name="otherPrimaryDetailCodeOfficeName"
                                                        type="text"
                                                        defaultValue={props.requestData.otherPrimaryDetailCodeOfficeName}
                                                        register={register}
                                                        errors={errors}
                                                        readOnly={isPublicDropTicket && isBgaSpecificDropTicket}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-9">
                                                    <MaskedInput id="otherPrimaryDetailCodeOfficeNumber"
                                                        labelText="Office number"
                                                        name="otherPrimaryDetailCodeOfficeNumber"
                                                        defaultValue={props.requestData.otherPrimaryDetailCodeOfficeNumber}
                                                        control={control}
                                                        mask={Constants.OFFICE_NUMBER_MASK}
                                                        errors={errors}
                                                        readOnly={isPublicDropTicket && isBgaSpecificDropTicket}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    )}
                                    <RadioField name="primaryFinProDetailCode"
                                        values={Constants.UNKNOWN_DETAIL_CODE}
                                        register={register}
                                        errors={errors}
                                    />
                                    {primaryFinProDetailCode === 'unknownDetailCode' && (
                                        <div className="col-lg-12 pds-util-margin-top-16 pds-util-padding-left-30">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-9">
                                                    <TextField id="unknownPrimaryDetailCodeOfficeName"
                                                        labelText="Office Name"
                                                        name="unknownPrimaryDetailCodeOfficeName"
                                                        type="text"
                                                        defaultValue={props.requestData.officeName}
                                                        register={register}
                                                        errors={errors}
                                                        readOnly={isPublicDropTicket && isBgaSpecificDropTicket}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-9">
                                                    <MaskedInput id="unknownPrimaryDetailCodeOfficeNumber"
                                                        labelText="Office number"
                                                        name="unknownPrimaryDetailCodeOfficeNumber"
                                                        defaultValue={props.requestData.officeNumber}
                                                        control={control}
                                                        mask={Constants.OFFICE_NUMBER_MASK}
                                                        errors={errors}
                                                        readOnly={isPublicDropTicket && isBgaSpecificDropTicket}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <br />
                            </div>
                        )}
                        {(!isPublicDropTicket || isFinancialProfessionalFound) && (
                            <div>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label htmlFor="additionalFinProResponse">
                                            <b>Would you like to add another financial professional? </b>
                                        </label>
                                    </div>
                                    <RadioField name="additionalFinProResponse"
                                        values={Constants.YES_NO_INPUTS}
                                        register={register}
                                        errors={errors}
                                        defaultChecked={props.requestData.additionalFinProResponse}
                                    />
                                </div>
                                <br />
                                {additionalFinProResponse === 'true' && (
                                    <AdditionalFinPro watch={watch}
                                        control={control}
                                        fields={fields}
                                        append={append}
                                        remove={remove}
                                        errors={errors}
                                        requestData={props.requestData}
                                        setRequest={props.setRequest}
                                        primaryFinPro={primaryFinPro}
                                        showAdditionalFinProSection={showAdditionalFinProSection}
                                        setShowAdditionalFinProSection={setShowAdditionalFinProSection}
                                        validateServicingPercentage={(
                                            servicingPercentageErrorFlag: boolean
                                        ) => setServicingPercentageErrorResponse(servicingPercentageErrorFlag)}
                                        validateSellingPercentage={(
                                            sellingPercentageErrorFlag: boolean
                                        ) => setSellingPercentageErrorResponse(sellingPercentageErrorFlag)}
                                    />
                                )}
                            </div>
                        )}
                        <br />
                        {(!isPublicDropTicket && !props.requestData.isPartialDropTicket) && (
                            <div>
                                <div className="row col-sm-12 col-lg-12">
                                    <div className="pds-util-padding-left-2">
                                        <button id="previous"
                                            className="pds-button pds-button-secondary"
                                            type="button"
                                            onClick={showPrevious}
                                            disabled={isSubmitToFinPro}
                                        >
                                            Previous
                                        </button>
                                    </div>
                                    {isFinancialProfessionalSubmitting() && (
                                        <div className="pds-util-padding-left-12">
                                            <button id="next" className="pds-button pds-button-primary" type="submit">Next</button>
                                        </div>
                                    )}
                                    {!isFinancialProfessionalSubmitting() && (
                                        <div className="pds-util-padding-left-12">
                                            <button id="submitToFinPro"
                                                className="pds-button pds-button-primary"
                                                type="submit"
                                                disabled={isSubmitToFinPro}
                                            >
                                                Submit to Financial Professional
                                            </button>
                                            {isSubmitToFinPro && (
                                                <span className="text-center pds-util-margin-left-8">
                                                    <Icon name="loader" size={20} className="pds-util-padding-top-8" />
                                                    <span>Submitting...</span>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <br />
                            </div>
                        )}
                    </div>
                )}
                {(!isPublicDropTicket && !licensedFinPro && !props.requestData.isPartialDropTicket) && (
                    <div>
                        <div className="row col-sm-12 col-lg-12">
                            <div className="pds-util-padding-left-2">
                                <button id="previous" className="pds-button pds-button-secondary" type="button" onClick={showPrevious}>Previous</button>
                            </div>
                        </div>
                        <br />
                    </div>
                )}
                {(isPublicDropTicket || props.requestData.isPartialDropTicket) && (
                    <div>
                        <div className="row col-sm-12 col-lg-12">
                            <div className="pds-util-padding-left-2">
                                <button id="previous" className="pds-button pds-button-secondary" type="button" onClick={showPrevious}>Previous</button>
                            </div>
                            <div className="pds-util-padding-left-12">
                                <button id="next"
                                    className="pds-button pds-button-primary"
                                    type="submit"
                                    disabled={isPublicDropTicket ? !isFinancialProfessionalFound : false}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                        <br />
                    </div>
                )}
                {showErrorModal && (
                    <ErrorModal showErrorModal={showErrorModal} setShowErrorModal={setShowErrorModal} setIsError={setIsError} />
                )}
            </form>
        </div>
    );
}

Advisor.propTypes = {
    setRequest: PropTypes.func.isRequired,
    requestData: PropTypes.any.isRequired
};

export default Advisor;
