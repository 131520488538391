import React from 'react';
import '@pds/footers/footers.min.css';

const Footer: React.FC = () => {
    return (
        <footer className=" pds-footer-unauthenticated">
            <section className=" pds-footer-unauthenticated-row">
                <div aria-label="Principal social media links" className="pds-footer-unauthenticated-social-links">
                    <a data-gtm="navigation-footer" className="pds-link-unstyled" aria-label="Principal on Facebook" href="https://www.facebook.com/PrincipalFinancial">
                        <div role="img" aria-label="Facebook logo" className="pds-imgFacebook" />
                    </a>
                    <a data-gtm="navigation-footer" className="pds-link-unstyled" aria-label="Principal on Twitter" href="https://www.twitter.com/Principal">
                        <div role="img" aria-label="Twitter logo" className="pds-imgTwitter" />
                    </a>
                    <a data-gtm="navigation-footer"
                        className="pds-link-unstyled"
                        aria-label="Principal on LinkedIn"
                        href="https://www.linkedin.com/company/principalfinancialgroup"
                    >
                        <div role="img" aria-label="LinkedIn logo" className="pds-imgLinkedIn" />
                    </a>
                    <a data-gtm="navigation-footer" className="pds-link-unstyled" aria-label="Principal on YouTube" href="https://www.youtube.com/user/PrincipalFinancial">
                        <div role="img" aria-label="Youtube logo" className="pds-imgYoutube" />
                    </a>
                </div>
                <div>
                    <ul className="pds-footer-unauthenticated-nav-inline">
                        <li>
                            <a data-gtm="navigation-footer" className="pds-link-unstyled" href="https://www.principal.com/terms-of-use">Terms of use</a>
                        </li>
                        <li>
                            <a data-gtm="navigation-footer" className="pds-link-unstyled" href="https://www.principal.com/products-services-disclosures">Disclosures</a>
                        </li>
                        <li>
                            <a data-gtm="navigation-footer" className="pds-link-unstyled" href="https://www.principal.com/privacy-policies">Privacy</a>
                        </li>
                        <li>
                            <a data-gtm="navigation-footer" className="pds-link-unstyled" href="https://www.principal.com/security-policies">Security</a>
                        </li>
                        <li>
                            <a data-gtm="navigation-footer"
                                className="pds-link-unstyled"
                                href="https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct"
                            >
                                Report fraud
                            </a>
                        </li>
                        <li>
                            <a data-gtm="navigation-footer" className="pds-link-unstyled" href="#placeholder">Site map</a>
                        </li>
                        <li>
                            <a data-gtm="navigation-footer" className="pds-link-unstyled" href="#placeholder">Español</a>
                        </li>
                    </ul>
                </div>
                <div className=" pds-footer-unauthenticated-legal ">
                    <p>
                        {' '}
                        &copy;
                        <span className="pds-footer-year" />
                        , Principal Financial Services, Inc.
                    </p>
                    <p>
                        {' '}
                        {' '}
                        {' '}
                        Securities offered through Principal Securities, Inc., member
                        {' '}
                        <a data-gtm="navigation-footer" className="pds-link-unstyled" href="http://www.sipc.org">SIPC</a>
                    </p>
                </div>
            </section>
        </footer>
    );
};
export default Footer;
