import { post } from './rest-service-adapter';

export default async function submitPartialDropTicket(requestData: any, authToken: any) {
    try {
        const partialDropTicketRequestData = JSON.parse(JSON.stringify(requestData));
        return await post(`${process.env.DROP_TICKET_SYSTEM_PARTIAL_URL}`, partialDropTicketRequestData, authToken);
    } catch (error) {
        throw error;
    }
}
