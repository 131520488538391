import React, { useState, useEffect } from 'react';
import '@pds/modal/modal.min.css';
import '@pds/util/util.min.css';
import { useForm } from 'react-hook-form';
import PropTypes, { InferProps } from 'prop-types';
import TextField from './TextField';
import * as Constants from '../../utility/constants';
import MaskedInput from './MaskedInput';

function AdditionalFinProModal(props: InferProps<typeof AdditionalFinProModal.propTypes>) {
    const [showMessage, setShowMessage] = useState(false);

    // This is used to unmount and remount the MaskedInput component everytime the modal is closed
    // This resets the value of the MaskedInput component
    const [mountMaskedInputKey, setMountMasketInputKey] = useState(0);

    const {
        formState: { errors: errorsFinProModal },
        register: registerFinProModal,
        handleSubmit: handleFinProModalSubmit,
        setValue: additionalFinProSetValue,
        control: additionalFinProControl
    } = useForm();

    useEffect(() => {
        if (!props.hideModal && props.editFinProFlag) {
            additionalFinProSetValue('finProFirstName', props.additionalFinPro.finProFirstName);
            additionalFinProSetValue('finProMiddleName', props.additionalFinPro.finProMiddleName);
            additionalFinProSetValue('finProLastName', props.additionalFinPro.finProLastName);
            additionalFinProSetValue('finProSsn', props.additionalFinPro.finProSsn);
            additionalFinProSetValue('finProDetailCode', props.additionalFinPro.finProDetailCode);
            additionalFinProSetValue('finProPhoneNumber', props.additionalFinPro.finProPhoneNumber);
            additionalFinProSetValue('finProEmailAddress', props.additionalFinPro.finProEmailAddress);
        }
    }, [props.hideModal]);

    const setAdditionalFinPro = (data: any) => {
        if (!props.editFinProFlag) {
            props.append({});
        }
        props.setAdditonalFinPro({ ...data });
        props.setHideModal(true);
        props.setShowAdditionalFinProSection(true);
        clearAdditionalFinProData();
        setMountMasketInputKey(prevKey => prevKey + 1);
    };

    const closeModal = () => {
        props.setHideModal(true);
        clearAdditionalFinProData();
        setMountMasketInputKey(prevKey => prevKey + 1);
    };

    const clearAdditionalFinProData = () => {
        additionalFinProSetValue('finProFirstName', '');
        additionalFinProSetValue('finProMiddleName', '');
        additionalFinProSetValue('finProLastName', '');
        additionalFinProSetValue('finProSsn', '');
        additionalFinProSetValue('finProPhoneNumber', '');
        additionalFinProSetValue('finProEmailAddress', '');
        additionalFinProSetValue('finProDetailCode', '');
    };

    return (
        <div className="pds-modal pds-util-margin-vert-16" id="pds-modal">
            <button type="button"
                id="addAdditionalFinPro"
                name="addAdditionalFinPro"
                onClick={() => {
                    if (props.fields.length < 6) {
                        props.setHideModal(false);
                        props.setEditFinProFlag(false);
                        setShowMessage(false);
                    } else {
                        setShowMessage(true);
                    }
                }}
                className="pds-button pds-button-primary pds-modal-open-dialog"
            >
                Add Financial Professional
            </button>
            {((showMessage) && (props.fields.length > 5)) && (
                <div className="pds-util-margin-left-2" id="addMaxAdditionalFinProError">
                    <span className="pds-error">
                        You have already reached maximum limit to add Financial Professionals.
                    </span>
                </div>
            )}
            {!props.hideModal && <div className="pds-modal-dialog-overlay" tabIndex={-1} />}
            <div className="pds-modal-dialog" role="dialog" aria-labelledby="modal-title" aria-hidden={props.hideModal}>
                <h4 id="modal-title" className="dialog-header pds-util-margin-10">Additional Financial Professional Information</h4>
                <div className="col-lg-12 pds-util-margin-bottom-16">
                    <TextField id="finProFirstName"
                        labelText="First name"
                        name="finProFirstName"
                        type="text"
                        register={registerFinProModal}
                        errors={errorsFinProModal}
                        required
                    />
                </div>
                <div className="col-lg-12 pds-util-margin-bottom-16">
                    <TextField id="finProMiddleName"
                        labelText="Middle name"
                        name="finProMiddleName"
                        type="text"
                        register={registerFinProModal}
                        errors={errorsFinProModal}
                    />
                </div>
                <div className="col-lg-12 pds-util-margin-bottom-16">
                    <TextField id="finProLastName"
                        labelText="Last name"
                        name="finProLastName"
                        type="text"
                        register={registerFinProModal}
                        errors={errorsFinProModal}
                        required
                    />
                </div>
                <div className="col-lg-12 pds-util-margin-bottom-16">
                    <TextField id="finProSsn"
                        labelText="Last 4 Digits of Social Security Number"
                        name="finProSsn"
                        type="text"
                        maxLength={4}
                        register={registerFinProModal}
                        errors={errorsFinProModal}
                    />
                </div>
                <div className="col-lg-12 pds-util-margin-bottom-16">
                    <MaskedInput id="finProPhoneNumber"
                        key={mountMaskedInputKey}
                        labelText="Phone number"
                        name="finProPhoneNumber"
                        mask={Constants.PHONE_MASK}
                        control={additionalFinProControl}
                        errors={errorsFinProModal}
                    />
                </div>
                <div className="col-lg-12 pds-util-margin-bottom-16">
                    <TextField id="finProEmailAddress"
                        labelText="Email address"
                        name="finProEmailAddress"
                        type="text"
                        register={registerFinProModal}
                        errors={errorsFinProModal}
                    />
                </div>
                <div className="col-lg-12 pds-util-margin-bottom-28">
                    <MaskedInput id="finProDetailCode"
                        key={mountMaskedInputKey}
                        labelText="Detail Code"
                        name="finProDetailCode"
                        mask={Constants.DETAIL_CODE_MASK}
                        control={additionalFinProControl}
                        errors={errorsFinProModal}
                    />
                </div>
                <div className="pds-modal-cta">
                    <button className="pds-button pds-button-secondary"
                        id="closeFinPro"
                        type="button"
                        onClick={closeModal}
                    >
                        Close
                    </button>
                    {!props.editFinProFlag && (
                        <button type="button"
                            id="addFinPro"
                            onClick={handleFinProModalSubmit(setAdditionalFinPro)}
                            className="pds-button pds-button-primary pds-util-margin-left-12"
                        >
                            Add
                        </button>
                    )}
                    {props.editFinProFlag && (
                        <button type="button"
                            id="updateFinPro"
                            onClick={handleFinProModalSubmit(setAdditionalFinPro)}
                            className="pds-button pds-button-primary pds-util-margin-left-12"
                        >
                            Save
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

AdditionalFinProModal.propTypes = {
    fields: PropTypes.array.isRequired,
    append: PropTypes.func.isRequired,
    additionalFinPro: PropTypes.any.isRequired,
    setAdditonalFinPro: PropTypes.func.isRequired,
    hideModal: PropTypes.bool.isRequired,
    setHideModal: PropTypes.func.isRequired,
    editFinProFlag: PropTypes.bool.isRequired,
    setEditFinProFlag: PropTypes.func.isRequired,
    setShowAdditionalFinProSection: PropTypes.any
};

AdditionalFinProModal.defaultProps = {
    setShowAdditionalFinProSection: false
};

export default AdditionalFinProModal;
