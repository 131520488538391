import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import '@pds/core/core.min.css';
import '@pds/util/util.min.css';
import '@pds/radio/radio.min.css';
import '@pds/input/input.min.css';
import '@pds/label/label.min.css';
import '@pds/select/select.min.css';
import '@pds/button/button.min.css';
import '@pds/erroredInput/erroredInput.min.css';
import '@pds/typography/typography.min.css';
import '@pds/tooltip/tooltip.min.css';
import { useHistory } from 'react-router-dom';
import Icon from '@pds-react/icon';
import * as Constants from '../utility/constants';
import SelectField from './form-fields/SelectField';
import RadioField from './form-fields/RadioField';
import CheckBoxField from './form-fields/CheckBoxField';
import MaskedInput from './form-fields/MaskedInput';
import Beneficiary from './form-fields/Beneficiary';
import StepIndicator from './commons/StepIndicator';

function Coverage(props: InferProps<typeof Coverage.propTypes>) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        control
    } = useFormContext();

    const {
        fields: primaryBeneficiaryFields,
        append: primaryBeneficiaryAppend,
        remove: primaryBeneficiaryRemove
    } = useFieldArray({
        name: 'primaryBeneficiary',
        control
    });

    const {
        fields: contingentBeneficiaryFields,
        append: contingentBeneficiaryAppend,
        remove: contingentBeneficiaryRemove
    } = useFieldArray({
        name: 'contingentBeneficiary',
        control
    });

    const [
        isValidPrimaryBeneficiaryPercentage,
        setPrimaryBeneficiaryPercentageErrorResponse
    ] = useState(false);
    const [
        isValidContingentBeneficiaryPercentage,
        setContingentBeneficiaryPercentageErrorResponse
    ] = useState(false);

    const history = useHistory();

    const product = watch(
        'product',
        props.requestData.product
    );

    const salaryIncreaseRider = watch(
        'salaryIncreaseRider',
        props.requestData.salaryIncreaseRider
    );
    const salaryIncreaseRiderAmount = watch(
        'salaryIncreaseRiderAmount',
        props.requestData.salaryIncreaseRiderAmount
    );

    const businessValueIncreaseRider = watch(
        'businessValueIncreaseRider',
        props.requestData.businessValueIncreaseRider
    );

    const homeOfficeOrder = watch(
        'homeOfficeOrder',
        props.requestData.homeOfficeOrder !== undefined
            ? props.requestData.homeOfficeOrder
            : 'true'
    );
    const alternateAdditionalPolicy = watch(
        'alternateAdditionalPolicy',
        props.requestData.alternateAdditionalPolicy
    );
    const addContingentBeneficiary = watch(
        'addContingentBeneficiary',
        props.requestData.addContingentBeneficiary
    );
    const amountOfInsurance = watch('amountOfInsurance');

    const onSubmit = (coverageDetails: any) => {
        let contingentBeneficiaryPercentageErrorResponse = isValidContingentBeneficiaryPercentage;
        if (
            addContingentBeneficiary === null
            || addContingentBeneficiary === 'false'
        ) {
            contingentBeneficiaryPercentageErrorResponse = true;
        }
        if (
            isValidPrimaryBeneficiaryPercentage
            && contingentBeneficiaryPercentageErrorResponse
            && validateFaceAmount()
        ) {
            props.setRequest({ ...props.requestData, ...coverageDetails });
            history.push(Constants.FINPRO_PATH);
        }
    };

    const showPrevious = () => {
        if (props.requestData.insuredOwnerSame === 'true') {
            history.push(Constants.INSURED_PATH);
        } else {
            history.push(Constants.OWNER_PATH);
        }
    };

    const validateFaceAmount = () => {
        if (!amountOfInsurance) {
            return true;
        } else {
            const faceAmount = Number(amountOfInsurance.replace(/,/g, ''));
            return faceAmount >= 200000;
        }
    };

    const validateSalaryIncreaseRiderAmount = () => {
        if (!salaryIncreaseRiderAmount) {
            return true;
        } else {
            const amount = Number(salaryIncreaseRiderAmount.replace(/,/g, ''));
            return amount >= 50000 && amount <= 150000;
        }
    };

    const isTerm2023EligibleRider = () => {
        if (!amountOfInsurance || !product) {
            return false;
        } else {
            const faceAmount = Number(amountOfInsurance.replace(/,/g, ''));
            return faceAmount >= 1000000 && product !== '30T23';
        }
    };

    const billingType = watch('billingType', '0001');

    const BILLING_TYPE_LIST_BILL = '0003';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container pds-container">
            <StepIndicator step={Constants.COVERAGE_STEP}
                isInsuredOwnerSame={props.requestData.insuredOwnerSame}
            />
            <br />
            <div className="row col-sm-9 col-lg-10">
                <p className="pds-typography-h3">Coverage</p>
            </div>
            {props.requestData.isPartialDropTicket && (
                <>
                    <br />
                    <div className="pds-card pds-alert pds-alert-warning">
                        <div className="pds-card-section pds-util-padding-vert-8">
                            <div className="pds-alert-content" role="alert">
                                <b>
                                    Principal introduced a new Term 2023 product on December 4, 2023. Additional fields have been added.
                                    To assure your drop ticket is in good order, please review in each section and assure all fields are completed.
                                </b>
                            </div>
                        </div>
                    </div>
                    <br />
                </>
            )}
            <div className="row pds-util-float-right pds-util-padding-right-12">
                <label htmlFor="required">
                    <span className="pds-label-required">*</span>
                    <small className="pds-util-float-right" id="required">
                        Required
                    </small>
                </label>
            </div>
            <br />
            <form name="forms.coverage" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-4">
                        <SelectField id="stateWritten"
                            labelText="State written"
                            name="stateWritten"
                            options={Constants.STATES}
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.stateWritten}
                            required
                            hideValue
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-4">
                        <SelectField id="product"
                            labelText="Product"
                            name="product"
                            options={Constants.TERM_2023_PRODUCT}
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.product}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-4">
                        <MaskedInput id="amountOfInsurance"
                            labelText="Amount of insurance"
                            name="amountOfInsurance"
                            placeHolder="(e.g. 1,000,000)"
                            mask={Constants.FACE_AMOUNT_MASK}
                            defaultValue={props.requestData.amountOfInsurance}
                            control={control}
                            errors={errors}
                            required
                        />
                    </div>
                </div>
                {!validateFaceAmount() && (
                    <div id="faceAmountError">
                        <span className="pds-error">
                            Please enter an amount of $200,000 or greater
                        </span>
                    </div>
                )}
                <br />
                <div className="row">
                    <div className="col-lg-4">
                        <MaskedInput id="premium"
                            labelText="Premium"
                            name="premium"
                            placeHolder="(e.g. 182.50)"
                            mask={Constants.PREMIUM_MASK}
                            defaultValue={props.requestData.premium}
                            control={control}
                            errors={errors}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-4">
                        <SelectField id="billingFrequency"
                            labelText="Billing frequency"
                            name="billingFrequency"
                            options={Constants.BILLING_FREQUENCY}
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.billingFrequency}
                            required
                        />
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-lg-4">
                        <SelectField id="billingType"
                            labelText="Billing method"
                            name="billingType"
                            options={Constants.BILLING_TYPE}
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.billingType}
                            required
                        />
                    </div>
                    {billingType === BILLING_TYPE_LIST_BILL && (
                        <>
                            <div className="col-lg-4">
                                <MaskedInput id="listBillNumber"
                                    labelText="Existing Number"
                                    name="listBillNumber"
                                    mask={Constants.LIST_BILL_MASK}
                                    placeHolder="(e.g. 999999999999-999)"
                                    defaultValue={props.requestData.listBillNumber}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-4">
                                <MaskedInput id="billingClientId"
                                    labelText="Client ID"
                                    name="billingClientId"
                                    mask={Constants.BILLING_CLIENT_ID_MASK}
                                    placeHolder="(e.g. 5123456)"
                                    defaultValue={props.requestData.billingClientId}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </>
                    )}
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-4">
                        <SelectField id="underwritingClass"
                            labelText="Underwriting class"
                            name="underwritingClass"
                            options={Constants.UNDERWRITING_CLASS}
                            register={register}
                            errors={errors}
                            defaultValue={props.requestData.underwritingClass}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-4">
                        <SelectField id="rating"
                            labelText="Rating"
                            name="rating"
                            options={Constants.RATING}
                            register={register}
                            defaultValue={props.requestData.rating}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="tobaccoUse">
                            <b>Tobacco use? </b>
                            <span className="pds-label-required">*</span>
                        </label>
                        <br />
                    </div>
                    <div className="row col-lg-10">
                        <RadioField name="tobaccoUse"
                            values={Constants.TOBACCO}
                            register={register}
                            errors={errors}
                            defaultChecked={props.requestData.tobaccoUse}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="conversionOption">
                            <b>Conversion option </b>
                            <span className="pds-label-required">* </span>
                        </label>
                        <a href="#"
                            className="pds-link-unstyled"
                            data-toggle="tooltip"
                            title={Constants.CONVERSION_OPTION_TOOLTIP_TEXT}
                        >
                            <Icon name="info" size={16} />
                        </a>
                        <br />
                    </div>
                    <div className="row col-lg-4">
                        <RadioField name="conversionOption"
                            values={Constants.CONVERSION_OPTIONS}
                            register={register}
                            errors={errors}
                            defaultChecked={props.requestData.conversionOption}
                        />
                    </div>
                </div>
                <br />
                <div className="row col-sm-12 col-md-10 col-lg-8">
                    <b>Riders</b>
                </div>
                <div className="row col-sm-12 col-md-10 col-lg-8">
                    <CheckBoxField id="acceleratedBenefitRider"
                        labelText="Accelerated Benefit Rider"
                        name="acceleratedBenefitRider"
                        register={register}
                        defaultChecked={props.requestData.acceleratedBenefitRider}
                    />
                </div>
                <br />
                <div className="row col-sm-12 col-md-10 col-lg-8">
                    <CheckBoxField id="waiverOfPremiumRider"
                        labelText="Waiver Of Premium Rider"
                        name="waiverOfPremiumRider"
                        register={register}
                        defaultChecked={props.requestData.waiverOfPremiumRider}
                    />
                </div>
                <br />
                {isTerm2023EligibleRider() && (
                    <>
                        <div className="row">
                            <div className="col-lg-10">
                                <CheckBoxField id="businessValueIncreaseRider"
                                    labelText="Business Value Increase Rider (Business case only)"
                                    name="businessValueIncreaseRider"
                                    register={register}
                                    disabled={salaryIncreaseRider}
                                    defaultChecked={props.requestData.businessValueIncreaseRider}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-lg-4">
                                <CheckBoxField id="salaryIncreaseRider"
                                    labelText="Salary Increase Rider (Business case only)"
                                    name="salaryIncreaseRider"
                                    register={register}
                                    disabled={businessValueIncreaseRider}
                                    defaultChecked={props.requestData.salaryIncreaseRider}
                                />
                            </div>
                        </div>
                        <div className="row">
                            {salaryIncreaseRider && (
                                <div className="col-lg-4 pds-util-margin-left-32">
                                    <MaskedInput id="salaryIncreaseRiderAmount"
                                        labelText="Amount"
                                        name="salaryIncreaseRiderAmount"
                                        placeHolder="(e.g. 50,000)"
                                        mask={Constants.FACE_AMOUNT_MASK}
                                        defaultValue={props.requestData.salaryIncreaseRiderAmount}
                                        control={control}
                                        errors={errors}
                                        required
                                    />
                                    {!validateSalaryIncreaseRiderAmount() && (
                                        <div id="salaryIncreaseRiderAmountError">
                                            <span className="pds-error">
                                                Please enter amount between $50,000 - $150,000
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <br />
                    </>
                )}
                <div className="row">
                    <div className="col-lg-12">
                        <b>
                            Principal will determine if this case qualifies for
                            {' '}
                            <a href="http://www.principal.com/accelerated"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Accelerated Underwriting
                            </a>
                            {' '}
                            upon being received and reviewed. In the event it does not
                            qualify, we will use the selected Paramed preferences below to
                            fulfill underwriting requirements.
                        </b>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="homeOfficeOrder">
                            <b>
                                Would you like Principal to order exam requirements for you?
                                {' '}
                            </b>
                        </label>
                        <br />
                    </div>
                    <div className="row col-lg-10">
                        <RadioField name="homeOfficeOrder"
                            values={Constants.EXAM_REQUIRMENT}
                            register={register}
                            defaultChecked={
                                props.requestData.homeOfficeOrder !== undefined
                                    ? props.requestData.homeOfficeOrder
                                    : 'true'
                            }
                        />
                    </div>
                </div>
                <br />
                {homeOfficeOrder === 'true' && (
                    <div id="paramedVendorInformation">
                        <div className="row">
                            <div className="col-lg-10">
                                <label htmlFor="paramedVendor">
                                    <b>Which paramed vendor would you prefer? </b>
                                </label>
                                <br />
                            </div>
                            <div className="row col-lg-10">
                                <RadioField name="paramedVendor"
                                    values={Constants.PARAMED_VENDOR}
                                    register={register}
                                    defaultChecked={
                                        props.requestData.paramedVendor !== undefined
                                            ? props.requestData.paramedVendor
                                            : 'ExamOne'
                                    }
                                />
                            </div>
                        </div>
                        <br />
                    </div>
                )}
                <div className="row ">
                    <div className="col-lg-5">
                        <div className="row">
                            <div className="col-lg-12">
                                <label htmlFor="alternateAdditionalPolicy">
                                    <b>Are you requesting an Alternate or Additional policy? </b>
                                </label>
                                <br />
                            </div>
                            <div className="row col-lg-12">
                                <RadioField name="alternateAdditionalPolicy"
                                    values={Constants.ALTERNATE_ADDITIONAL_POLICY}
                                    register={register}
                                    defaultChecked={props.requestData.alternateAdditionalPolicy}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        {(alternateAdditionalPolicy === 'Alternate'
                            || alternateAdditionalPolicy === 'Additional') && (
                                <div id="alternateAdditionalPolicyInformation">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <SelectField id="alternateAdditionalProduct"
                                                labelText="Product"
                                                name="alternateAdditionalProduct"
                                                options={Constants.PRODUCT}
                                                register={register}
                                                errors={errors}
                                                defaultValue={
                                                    props.requestData.alternateAdditionalProduct
                                                }
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <MaskedInput id="alternateAdditionalFaceAmount"
                                                labelText="Amount of insurance"
                                                name="alternateAdditionalFaceAmount"
                                                placeHolder="(e.g. 1,000,000)"
                                                mask={Constants.FACE_AMOUNT_MASK}
                                                errors={errors}
                                                defaultValue={
                                                    props.requestData.alternateAdditionalFaceAmount
                                                }
                                                control={control}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                        )}
                    </div>
                </div>
                <br />
                <br />
                <div className="row col-sm-12 col-md-10">
                    <p className="pds-typography-h5">Primary Beneficiary</p>
                </div>
                <Beneficiary watch={watch}
                    register={register}
                    fields={primaryBeneficiaryFields}
                    append={primaryBeneficiaryAppend}
                    remove={primaryBeneficiaryRemove}
                    idPrefix="primary"
                    errors={errors}
                    requestData={props.requestData}
                    validatePrimaryBeneficiaryPercentage={(
                        primaryBeneficiaryPercentageErrorFlag: boolean
                    ) => setPrimaryBeneficiaryPercentageErrorResponse(
                        primaryBeneficiaryPercentageErrorFlag
                    )}
                />
                <br />
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="addContingentBeneficiary">
                            <b>Would you like to add a contingent beneficiary?</b>
                        </label>
                    </div>
                    <div className="row col-lg-10">
                        <RadioField name="addContingentBeneficiary"
                            values={Constants.YES_NO_INPUTS}
                            register={register}
                            errors={errors}
                            defaultChecked={
                                props.requestData.addContingentBeneficiary === 'true'
                            }
                        />
                    </div>
                </div>
                <br />
                {addContingentBeneficiary === 'true' && (
                    <div id="contingentBeneficiaryInformation">
                        <div className="row col-sm-12 col-md-10 col-lg-8">
                            <p className="pds-typography-h5">Contingent Beneficiary</p>
                        </div>
                        <Beneficiary register={register}
                            watch={watch}
                            fields={contingentBeneficiaryFields}
                            append={contingentBeneficiaryAppend}
                            remove={contingentBeneficiaryRemove}
                            idPrefix="contingent"
                            errors={errors}
                            requestData={props.requestData}
                            validateContingentBeneficiaryPercentage={(
                                contingentBeneficiaryPercentageErrorFlag: boolean
                            ) => setContingentBeneficiaryPercentageErrorResponse(
                                contingentBeneficiaryPercentageErrorFlag
                            )}
                        />
                        <br />
                    </div>
                )}
                <div className="row col-sm-12 col-lg-12">
                    <div className="pds-util-padding-left-2">
                        <button id="previous"
                            className="pds-button pds-button-secondary"
                            type="button"
                            onClick={showPrevious}
                        >
                            Previous
                        </button>
                    </div>
                    <div className="pds-util-padding-left-12">
                        <button id="next"
                            className="pds-button pds-button-primary"
                            type="submit"
                        >
                            Next
                        </button>
                    </div>
                </div>
                <br />
            </form>
        </div>
    );
}

Coverage.propTypes = {
    setRequest: PropTypes.func.isRequired,
    requestData: PropTypes.any.isRequired
};

export default Coverage;
