import React from 'react';
import '@pds/navigationPrimary/navigationPrimary.min.css';
import '@pds/core/core.min.css';

const Navbar: React.FC = () => {
    return (
        <nav className="pds-navigationPrimary-horizontal">
            <div className="pds-navigationPrimary-container container">
                <a className="pds-navigationPrimary-skip-to-content-link" href="#main">Skip to content</a>
                <div className="pds-navigationPrimary-logo">
                    <a className="pds-navigationPrimary-logo-link pds-link-unstyled" href="#" aria-label="Principal Homepage">
                        <img alt="" className="pds-imgLogoCompanyNameWhiteRetina" />
                    </a>
                </div>
                <span className="title pds-color-font-white pds-util-margin-left-8">Life Drop Ticket</span>
            </div>
        </nav>
    );
};
export default Navbar;
