import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ErrorSpan from './ErrorSpan';
import ValidationRules from '../../validations/ValidationRules';

function RadioField(props: InferProps<typeof RadioField.propTypes>) {
    const hasError = props.errors && props.errors[props.name];
    const rules = ValidationRules[props.name];

    const radioRegister = props.register(props.name, rules);

    return (
        <>
            {props.verticalLayout && (
                <div className="col-lg-10">
                    {props.values.map((radioInput) => (
                        <div className="row">
                            <div className="pds-radio pds-util-padding-left-12" key={radioInput.value}>
                                <input data-testid={`${props.name}${radioInput.label}`}
                                    id={`${props.name}${radioInput.label}`}
                                    className={hasError ? 'pds-input-error' : ''}
                                    type="radio"
                                    {...radioRegister}
                                    onBlur={(event) => {
                                        radioRegister.onBlur(event);
                                        if (props.onBlur) {
                                            props.onBlur(event);
                                        }
                                    }}
                                    onChange={(event) => {
                                        radioRegister.onChange(event);
                                        if (props.onChange) {
                                            props.onChange(event);
                                        }
                                    }}
                                    defaultChecked={props.defaultChecked === radioInput.value || false}
                                    value={radioInput.value}
                                />
                                <label htmlFor={`${props.name}${radioInput.label}`}>{radioInput.label}</label>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!props.verticalLayout && (
                <div className="row col-lg-10">
                    {props.values.map((radioInput) => (
                        <div className="pds-radio pds-util-padding-left-12" key={radioInput.value}>
                            <input data-testid={`${props.name}${radioInput.label}`}
                                id={`${props.name}${radioInput.label}`}
                                className={hasError ? 'pds-input-error' : ''}
                                type="radio"
                                {...radioRegister}
                                onBlur={(event) => {
                                    radioRegister.onBlur(event);
                                    if (props.onBlur) {
                                        props.onBlur(event);
                                    }
                                }}
                                onChange={(event) => {
                                    radioRegister.onChange(event);
                                    if (props.onChange) {
                                        props.onChange(event);
                                    }
                                }}
                                defaultChecked={props.defaultChecked === radioInput.value || false}
                                value={radioInput.value}
                            />
                            <label htmlFor={`${props.name}${radioInput.label}`}>{radioInput.label}</label>
                        </div>
                    ))}
                </div>
            )}
            <div className="col-lg-10">
                <ErrorSpan hasError={hasError} name={props.name} errors={props.errors} />
            </div>
        </>
    );
}

RadioField.propTypes = {
    name: PropTypes.any.isRequired,
    register: PropTypes.any.isRequired,
    errors: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any,
    values: PropTypes.array.isRequired,
    verticalLayout: PropTypes.bool,
    defaultChecked: PropTypes.any
};

RadioField.defaultProps = {
    errors: undefined,
    onBlur: undefined,
    onChange: undefined,
    verticalLayout: false,
    defaultChecked: ''
};

export default RadioField;
