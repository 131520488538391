import RequestBuilder from './drop-ticket-request-builder';
import { post } from './rest-service-adapter';
import { CustomFile } from '../components/form-fields/FileUpload';

export default async function submitDropTicket(requestData: any, uploadedAttachments: Array<CustomFile>) {
    try {
        const request = await RequestBuilder.buildRequest(requestData, uploadedAttachments);
        return await post(`${getDropTicketServiceUrl()}`, request);
    } catch (error) {
        throw error;
    }
}

function getDropTicketServiceUrl() {
    return process.env.DROP_TICKET_URL_V2;
}
