import axios from 'axios';
import RestServiceError from './RestServiceError';

export const post = async (url: string, data: unknown, accessToken?: string) => {
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response;
    } catch (error) {
        if (error.response) {
            throw new RestServiceError(error.response.data, error.response.status);
        } else {
            throw new RestServiceError(error.message, 500);
        }
    }
};

export const get = async (url: string, accessToken: string) => {
    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            throw new RestServiceError(error.response.data, error.response.status);
        } else {
            throw new RestServiceError(error.message, 500);
        }
    }
};
