import React from 'react';
import { Controller } from 'react-hook-form';
import TextMask from 'react-text-mask';
import PropTypes, { InferProps } from 'prop-types';
import ValidationRules from '../../validations/ValidationRules';
import ErrorSpan from './ErrorSpan';
import '@pds/typography/typography.min.css';
import '@pds/util/util.min.css';

function MaskedInput(props: InferProps<typeof MaskedInput.propTypes>) {
    const checkHasError = (): boolean => {
        const nameSplitArray = props.name.split('.');
        if (nameSplitArray.length > 1) {
            return props.errors
                && props.errors[nameSplitArray[0]]
                && props.errors[nameSplitArray[0]][nameSplitArray[1]]
                && props.errors[nameSplitArray[0]][nameSplitArray[1]][`${nameSplitArray[2]}`];
        }
        return props.errors && props.errors[props.name];
    };

    const getValidationRules = () => {
        const nameSplitArray = props.name.split('.');
        if (nameSplitArray.length > 1) {
            return ValidationRules[nameSplitArray[0]][`${nameSplitArray[2]}`];
        }
        return ValidationRules[props.name];
    };

    const hasError = checkHasError();
    const rules = getValidationRules();

    return (
        <label htmlFor={props.id}>
            <b>
                {props.labelText}
                {' '}
            </b>
            {props.required && <span className="pds-label-required">*</span>}
            {props.helperText && (
            <p className="pds-typography-body-14 pds-typography-bold pds-util-margin-vert-8">{props.helperText}</p>
            )}
            <Controller control={props.control}
                name={props.name}
                render={({ field }) => (
                    <TextMask type="text"
                        id={props.id}
                        mask={props.mask}
                        disabled={false}
                        guide={props.guide}
                        placeholder={props.placeHolder || ''}
                        className={hasError ? 'pds-input-error' : ''}
                        onChange={(event) => {
                            field.onChange(event);
                            if (props.onChange) {
                                props.onChange(event);
                            }
                        }}
                        onBlur={(event) => {
                            field.onBlur();
                            if (props.onBlur) {
                                props.onBlur(event);
                            }
                        }}
                        defaultValue={props.defaultValue ? props.defaultValue : field.value}
                        readOnly={props.readOnly ? props.readOnly : false}
                        autoComplete='none'
                    />
                )}
                rules={rules}
            />
            <ErrorSpan hasError={hasError} name={props.name} errors={props.errors} />
        </label>
    );
}

MaskedInput.propTypes = {
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
    name: PropTypes.any.isRequired,
    guide: PropTypes.any,
    control: PropTypes.any.isRequired,
    placeHolder: PropTypes.string,
    id: PropTypes.string.isRequired,
    errors: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any,
    labelText: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    defaultValue: PropTypes.any,
    readOnly: PropTypes.bool
};

MaskedInput.defaultProps = {
    errors: undefined,
    onBlur: undefined,
    onChange: undefined,
    guide: false,
    labelText: '',
    placeHolder: '',
    required: false,
    defaultValue: '',
    helperText: '',
    readOnly: false
};

export default MaskedInput;
