import React from 'react';
import '@pds/modal/modal.min.css';
import '@pds/util/util.min.css';
import PropTypes, { InferProps } from 'prop-types';

function ErrorModal(props: InferProps<typeof ErrorModal.propTypes>) {
    return (
        <div className="pds-modal pds-util-margin-vert-16" id="pds-modal">
            {props.showErrorModal && <div className="pds-modal-dialog-overlay" tabIndex={-1} />}
            <div className="pds-modal-dialog" role="dialog" aria-labelledby="modal-title" aria-hidden={!props.showErrorModal}>
                <h4 id="modal-title" className="dialog-header pds-util-margin-10">Error</h4>
                <div className="row">
                    <p>
                        We're sorry, but there has been an error. Please click OK to try again.
                        <br />
                        If you continue to receive an error, please contact us at 1.800.554.3395 for assistance.
                    </p>
                </div>
                <div className="pds-modal-cta">
                    <button className="pds-button pds-button-secondary"
                        id="closeErrorModal"
                        type="button"
                        onClick={() => {
                            props.setIsError((value: boolean) => !value);
                            props.setShowErrorModal(false);
                        }}
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
}

ErrorModal.propTypes = {
    showErrorModal: PropTypes.any.isRequired,
    setShowErrorModal: PropTypes.func.isRequired,
    setIsError: PropTypes.func.isRequired
};

export default ErrorModal;
