import * as Constants from '../utility/constants';

const ValidationRules: any = {
    insuredFirstName: {
        required: true,
        maxLength: 15
    },
    insuredMiddleName: {
        maxLength: 15
    },
    insuredLastName: {
        required: true,
        maxLength: 24
    },
    insuredGender: {
        required: true
    },
    insuredDateOfBirth: {
        required: true,
        pattern: Constants.DATE
    },
    insuredSsn: {
        required: false,
        pattern: Constants.SSN
    },
    insuredPostalAddressLine1: {
        required: true,
        maxLength: 24
    },
    insuredPostalAddressLine2: {
        maxLength: 24
    },
    insuredPostalAddressCity: {
        required: true,
        maxLength: 20
    },
    insuredPostalAddressState: {
        required: true
    },
    insuredPostalAddressZip: {
        required: true,
        pattern: Constants.ZIP
    },
    insuredPhoneNumber: {
        required: true,
        pattern: Constants.PHONE
    },
    insuredPhoneType: {
        required: true
    },
    insuredEmailAddress: {
        required: true,
        pattern: Constants.EMAIL
    },
    insuredEnglishPrimaryLanguageFlag: {
        required: true
    },
    insuredEnglishFluencyFlag: {
        required: true
    },
    insuredPrimaryLanguage: {
        required: true
    },
    insuredOwnerSame: {
        required: true
    },
    partBLifeDiCombo: {
        required: true
    },
    specialCallingInstructions: {
        maxLength: 255
    },
    insuredExistingInsuranceResponse: {
        required: true
    },
    insuredExistingInsuranceReplacementResponse: {
        required: true
    },
    insuredExistingInsurancePolicies: {
        company: {
            required: true
        },
        amount: {
            required: true
        }
    },
    ownerType: {
        required: true
    },
    ownerFirstName: {
        required: true,
        maxLength: 15
    },
    ownerMiddleName: {
        maxLength: 15
    },
    ownerLastName: {
        required: true,
        maxLength: 24
    },
    ownerName: {
        required: true,
        maxLength: 40
    },
    ownerSsn: {
        required: false,
        pattern: Constants.SSN
    },
    ownerEmailAddress: {
        required: true,
        pattern: Constants.EMAIL
    },
    ownerRelationship: {
        required: true
    },
    ownerPhoneNumber: {
        required: true,
        pattern: Constants.PHONE
    },
    ownerPhoneType: {
        required: true
    },
    ownerEnglishPrimaryLanguageFlag: {
        required: true
    },
    ownerEnglishFluencyFlag: {
        required: true
    },
    ownerPrimaryLanguage: {
        required: true
    },
    ownerRepresentativeFirstName: {
        required: true,
        maxLength: 15
    },
    ownerRepresentativeMiddleName: {
        maxLength: 15
    },
    ownerRepresentativeLastName: {
        required: true,
        maxLength: 24
    },
    ownerRepresentativeOfficerTitle: {
        required: true,
        maxLength: 40
    },
    ownerRepresentativePhoneNumber: {
        required: true,
        pattern: Constants.PHONE
    },
    ownerRepresentativePhoneType: {
        required: true
    },
    ownerRepresentativeEnglishPrimaryLanguageFlag: {
        required: true
    },
    ownerRepresentativeEnglishFluencyFlag: {
        required: true
    },
    ownerRepresentativePrimaryLanguage: {
        required: true
    },
    ownerOfficerTitle: {
        required: true,
        maxLength: 40
    },
    ownerRepresentativeEmailAddress: {
        required: true,
        pattern: Constants.EMAIL
    },
    ownerExistingInsuranceResponse: {
        required: true
    },
    ownerExistingInsuranceReplacementResponse: {
        required: true
    },
    ownerExistingInsurancePolicies: {
        company: {
            required: true
        },
        amount: {
            required: true
        }
    },
    stateWritten: {
        required: true
    },
    product: {
        required: true
    },
    amountOfInsurance: {
        required: true
    },
    premium: {
        required: true
    },
    billingFrequency: {
        required: true
    },
    billingType: {
        required: true
    },
    listBillNumber: {
        pattern: Constants.LIST_BILL
    },
    billingClientId: {
        pattern: Constants.BILLING_CLIENT_ID
    },
    underwritingClass: {
        required: true
    },
    tobaccoUse: {
        required: true
    },
    conversionOption: {
        required: true
    },
    salaryIncreaseRiderAmount: {
        required: true
    },
    alternateAdditionalProduct: {
        required: true
    },
    alternateAdditionalFaceAmount: {
        required: true
    },
    primaryBeneficiary: {
        name: {
            required: true
        },
        relationship: {
            required: true
        },
        percentage: {
            required: true,
            pattern: Constants.PERCENTAGE
        }
    },
    contingentBeneficiary: {
        name: {
            required: true
        },
        relationship: {
            required: true
        },
        percentage: {
            required: true,
            pattern: Constants.PERCENTAGE
        }
    },
    fieldOfficeContactName: {
        required: 'true',
        pattern: Constants.NAME
    },
    fieldOfficeContactPhoneNumber: {
        pattern: Constants.FIELD_OFFICE_CONTACT_PHONE
    },
    fieldOfficeContactEmailAddress: {
        required: 'true',
        pattern: Constants.EMAIL
    },
    primaryFinProFirstName: {
        required: 'true'
    },
    primaryFinProLastName: {
        required: 'true'
    },
    primaryFinProSsn: {
        required: 'true'
    },
    insuredFinancialProfessionalRelationship: {
        required: 'true'
    },
    primaryFinProPhoneNumber: {
        required: 'true',
        pattern: Constants.PHONE
    },
    primaryFinProEmailAddress: {
        required: 'true',
        pattern: Constants.EMAIL
    },
    primaryFinProLicenseNumberMandatory: {
        required: 'true'
    },
    primaryFinProPostalAddressLine1: {
        required: true,
        maxLength: 24
    },
    primaryFinProPostalAddressLine2: {
        maxLength: 24
    },
    primaryFinProPostalAddressCity: {
        required: true,
        maxLength: 20
    },
    primaryFinProPostalAddressState: {
        required: true
    },
    primaryFinProPostalAddressZip: {
        required: true,
        pattern: Constants.ZIP
    },
    primaryDetailCode: {
        pattern: Constants.DETAIL_CODE
    },
    otherPrimaryDetailCode: {
        pattern: Constants.DETAIL_CODE
    },
    additionalFinPros: {
        sellingCommissionPercentage: {
            required: true
        },
        servicingCommissionPercentage: {
            required: true
        }
    },
    finProFirstName: {
        required: true
    },
    finProMiddleName: {
        required: false
    },
    finProLastName: {
        required: true
    },
    finProSsn: {
        required: false
    },
    finProPhoneNumber: {
        required: false,
        pattern: Constants.PHONE
    },
    finProEmailAddress: {
        required: false,
        pattern: Constants.EMAIL
    },
    finProDetailCode: {
        required: false
    }
};

export default ValidationRules;
