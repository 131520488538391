import { FileWithPath } from 'react-dropzone';
import {
    BENEFICIARY_RELATIONS,
    INDIVIDUAL_OWNER_RELATIONS,
    FINPRO_RELATIONS,
    ORGANIZATION_OWNER_RELATIONS,
    TRUST_OWNER_RELATIONS,
    IN_STATE_WRITTEN_CODE
} from '../utility/constants';
import { CustomFile } from '../components/form-fields/FileUpload';

const RequestBuilder = {
    async buildRequest(data: any, uploadedAttachments: Array<CustomFile>) {
        const request: any = {};
        request.insured = buildInsured(data);
        request.owner = buildOwner(data);
        request.coverage = buildCoverage(data);
        request.primaryBeneficiaries = buildBeneficiaries(data.primaryBeneficiary);
        request.contingentBeneficiaryFlag = setBoolean(data.addContingentBeneficiary);
        if (request.contingentBeneficiaryFlag) {
            request.contingentBeneficiaries = buildBeneficiaries(data.contingentBeneficiary);
        }
        request.primaryAdvisor = buildPrimaryFinPro(data);
        request.additionalAdvisorFlag = setBoolean(data.additionalFinProResponse);
        if (request.additionalAdvisorFlag) {
            request.additionalAdvisors = buildAdditionalFinPro(data.additionalFinPros);
        } else {
            request.additionalAdvisors = [];
        }
        request.fieldOfficeContactName = data.fieldOfficeContactName;
        request.fieldOfficeContactPhoneNumber = data.fieldOfficeContactPhoneNumber;
        request.fieldOfficeContactEmailAddress = data.fieldOfficeContactEmailAddress;
        if (data.officeName) {
            request.officeName = data.officeName;
        } else if (data.otherPrimaryDetailCodeOfficeName) {
            request.officeName = data.otherPrimaryDetailCodeOfficeName;
        } else if (data.unknowPrimaryDetailCodeOfficeName) {
            request.officeName = data.unknowPrimaryDetailCodeOfficeName;
        }
        if (data.officeNumber) {
            request.officeNumber = data.officeNumber;
        } else if (data.otherPrimaryDetailCodeOfficeNumber) {
            request.officeName = data.otherPrimaryDetailCodeOfficeNumber;
        } else if (data.unknowPrimaryDetailCodeOfficeNumber) {
            request.officeName = data.unknowPrimaryDetailCodeOfficeNumber;
        }
        request.partBLifeDiCombo = setBoolean(data.partBLifeDiCombo);
        request.submissionTimestamp = new Date();
        request.publicDropTicket = data.publicDropTicket;
        if (uploadedAttachments) {
            await buildAttachments(request, uploadedAttachments);
        }
        return request;
    }
};

const buildInsured = (data: any) => {
    const insured: any = {};
    insured.firstName = data.insuredFirstName;
    insured.middleName = data.insuredMiddleName;
    insured.lastName = data.insuredLastName;
    insured.dateOfBirth = data.insuredDateOfBirth;
    insured.gender = data.insuredGender;
    insured.emailAddress = data.insuredEmailAddress;
    if (data.insuredSsn) {
        insured.taxId = data.insuredSsn;
    }
    if (data.insuredPhoneType === 'true') {
        insured.phoneType = '0012';
    } else {
        insured.phoneType = '0001';
    }
    insured.englishPrimaryLanguageFlag = setBoolean(data.insuredEnglishPrimaryLanguageFlag);
    if (!insured.englishPrimaryLanguageFlag) {
        insured.englishFluencyFlag = setBoolean(data.insuredEnglishFluencyFlag);
        if (!insured.englishFluencyFlag) {
            insured.primaryLanguage = data.insuredPrimaryLanguage;
        } else {
            insured.primaryLanguage = 'EN';
        }
    } else {
        insured.englishFluencyFlag = true;
        insured.primaryLanguage = 'EN';
    }
    insured.insuredOwnerSame = setBoolean(data.insuredOwnerSame);
    insured.phoneNumber = data.insuredPhoneNumber;
    insured.specialCallingInstructions = data.specialCallingInstructions;
    insured.existingInsurance = buildInsuredExistingInsurance(data);
    insured.postalAddress = buildInsuredPostalAddress(data);
    return insured;
};

const buildInsuredExistingInsurance = (data: any) => {
    const insuredExistingInsurance: any = {};
    insuredExistingInsurance.existingInsurance = setBoolean(data.insuredExistingInsuranceResponse);
    insuredExistingInsurance.replacement = setBoolean(data.insuredExistingInsuranceReplacementResponse);
    if (insuredExistingInsurance.existingInsurance) {
        insuredExistingInsurance.policies = data.insuredExistingInsurancePolicies;
    }
    return insuredExistingInsurance;
};

function buildNoExistingInsurance() {
    const noExistingInsurance: any = {};
    noExistingInsurance.existingInsurance = false;
    noExistingInsurance.replacement = false;
    return noExistingInsurance;
}

const buildInsuredPostalAddress = (data: any) => {
    const insuredPostalAddress: any = {};
    insuredPostalAddress.city = data.insuredPostalAddressCity;
    insuredPostalAddress.addressLine1 = data.insuredPostalAddressLine1;
    insuredPostalAddress.addressLine2 = data.insuredPostalAddressLine2;
    insuredPostalAddress.state = data.insuredPostalAddressState;
    insuredPostalAddress.zip = data.insuredPostalAddressZip;
    return insuredPostalAddress;
};

const buildOwner = (data: any) => {
    const owner: any = {};
    if (setBoolean(data.insuredOwnerSame)) {
        owner.individualOwner = false;
        owner.existingInsurance = buildNoExistingInsurance();
    } else {
        if (data.ownerType === 'Individual') {
            owner.firstName = data.ownerFirstName;
            owner.middleName = data.ownerMiddleName ?? null;
            owner.lastName = data.ownerLastName;
            owner.name = null;
            owner.signerEmailAddress = data.ownerEmailAddress;
            owner.phoneNumber = data.ownerPhoneNumber;
            if (data.ownerPhoneType === 'true') {
                owner.phoneType = '0012';
            } else {
                owner.phoneType = '0001';
            }
            owner.englishPrimaryLanguageFlag = setBoolean(data.ownerEnglishPrimaryLanguageFlag);
            if (!owner.englishPrimaryLanguageFlag) {
                owner.englishFluencyFlag = setBoolean(data.ownerEnglishFluencyFlag);
                if (!owner.englishFluencyFlag) {
                    owner.primaryLanguage = data.ownerPrimaryLanguage;
                } else {
                    owner.primaryLanguage = 'EN';
                }
            } else {
                owner.englishFluencyFlag = true;
                owner.primaryLanguage = 'EN';
            }
            owner.representative = null;
        } else if (data.ownerType === 'Organization' || data.ownerType === 'Trust') {
            if (data.ownerType === 'Trust') {
                data.ownerRelationship = 'Trust';
            }
            owner.firstName = null;
            owner.middleName = null;
            owner.lastName = null;
            owner.name = data.ownerName;
            owner.signerEmailAddress = null;
            owner.officerTitle = null;
            owner.phoneNumber = null;
            owner.phoneType = null;
            owner.englishPrimaryLanguageFlag = null;
            owner.englishFluencyFlag = null;
            owner.primaryLanguage = null;
            owner.representative = buildOwnerRepresentative(data);
        }
        owner.ownerType = data.ownerType;
        if (data.ownerSsn) {
            owner.taxId = data.ownerSsn;
        }
        owner.relationship = buildOwnerRelationship(data);
        owner.existingInsurance = buildOwnerExistingInsurance(data);
    }
    return owner;
};

const buildOwnerRepresentative = (data: any) => {
    const ownerRepresentative: any = {};
    ownerRepresentative.firstName = data.ownerRepresentativeFirstName;
    ownerRepresentative.middleName = data.ownerRepresentativeMiddleName ?? null;
    ownerRepresentative.lastName = data.ownerRepresentativeLastName;
    ownerRepresentative.title = data.ownerRepresentativeOfficerTitle ?? null;
    ownerRepresentative.emailAddress = data.ownerRepresentativeEmailAddress;
    ownerRepresentative.phoneNumber = data.ownerRepresentativePhoneNumber;
    if (data.ownerRepresentativePhoneType === 'true') {
        ownerRepresentative.phoneType = '0012';
    } else {
        ownerRepresentative.phoneType = '0001';
    }
    ownerRepresentative.englishPrimaryLanguageFlag = setBoolean(data.ownerRepresentativeEnglishPrimaryLanguageFlag);
    if (!ownerRepresentative.englishPrimaryLanguageFlag) {
        ownerRepresentative.englishFluencyFlag = setBoolean(data.ownerRepresentativeEnglishFluencyFlag);
        if (!ownerRepresentative.englishFluencyFlag) {
            ownerRepresentative.primaryLanguage = data.ownerRepresentativePrimaryLanguage;
        } else {
            ownerRepresentative.primaryLanguage = 'EN';
        }
    } else {
        ownerRepresentative.englishFluencyFlag = true;
        ownerRepresentative.primaryLanguage = 'EN';
    }
    return ownerRepresentative;
};

const buildOwnerRelationship = (data: any) => {
    const ownerRelationship: any = {};
    let ownerRelation: any = {};
    if (data.ownerType === 'Organization') {
        ownerRelation = ORGANIZATION_OWNER_RELATIONS.find(relation => relation.value === data.ownerRelationship);
    } else if (data.ownerType === 'Individual') {
        ownerRelation = INDIVIDUAL_OWNER_RELATIONS.find(relation => relation.value === data.ownerRelationship);
    } else if (data.ownerType === 'Trust') {
        ownerRelation = TRUST_OWNER_RELATIONS.find(relation => relation.value === data.ownerRelationship);
    }
    ownerRelationship.text = ownerRelation?.label;
    ownerRelationship.relationRoleType = ownerRelation?.relationRoleType ?? null;
    ownerRelationship.organizationFormType = ownerRelation?.organizationFormType ?? null;
    ownerRelationship.relationDescription = ownerRelation?.relationDescription ?? null;
    return ownerRelationship;
};

const buildOwnerExistingInsurance = (data: any) => {
    const ownerExistingInsurance: any = {};
    ownerExistingInsurance.existingInsurance = setBoolean(data.ownerExistingInsuranceResponse);
    ownerExistingInsurance.replacement = setBoolean(data.ownerExistingInsuranceReplacementResponse);
    if (ownerExistingInsurance.existingInsurance) {
        ownerExistingInsurance.policies = data.ownerExistingInsurancePolicies;
    }
    return ownerExistingInsurance;
};

const buildCoverage = (data: any) => {
    const coverage: any = {};
    coverage.stateWritten = data.stateWritten;
    if (data.conversionOption === 'Full') {
        coverage.product = `${data.product}C`;
        coverage.conversionExtensionRider = true;
    } else if (data.conversionOption === 'Limited') {
        coverage.product = `${data.product}C`;
    } else {
        coverage.product = `${data.product}N`;
    }
    coverage.faceAmount = data.amountOfInsurance;
    coverage.premium = data.premium;
    coverage.billingType = data.billingType;
    if (data.billingType === '0003') {
        coverage.billingClientId = data.billingClientId;
        coverage.listBillNumber = data.listBillNumber;
    }
    coverage.billingFrequency = data.billingFrequency;
    coverage.underwritingClass = data.underwritingClass;
    if (data.rating) {
        coverage.rating = data.rating;
    }
    coverage.tobaccoUse = setBoolean(data.tobaccoUse);
    coverage.acceleratedBenefitRider = data.acceleratedBenefitRider;
    coverage.waiverOfPremiumRider = data.waiverOfPremiumRider;
    if (data.salaryIncreaseRider) {
        coverage.salaryIncreaseRider = data.salaryIncreaseRider;
        coverage.siRiderBenefitAmount = data.salaryIncreaseRiderAmount;
    } else if (data.businessValueIncreaseRider) {
        coverage.businessValueIncreaseRider = data.businessValueIncreaseRider;
    }
    coverage.homeOfficeOrder = setBoolean(data.homeOfficeOrder);
    if (coverage.homeOfficeOrder) {
        coverage.preferredParamedVendor = data.paramedVendor;
    }
    coverage.alternateOrAdditional = data.alternateAdditionalPolicy;
    if (coverage.alternateOrAdditional !== 'No') {
        coverage.alternateAdditionalFaceAmount = data.alternateAdditionalFaceAmount;
        coverage.alternateAdditionalProduct = data.alternateAdditionalProduct;
    } else {
        coverage.alternateAdditionalFaceAmount = null;
        coverage.alternateAdditionalProduct = null;
    }
    return coverage;
};

const buildBeneficiaries = (data: any[]) => {
    const beneficiaries: any[] = [];
    data.forEach((beneficiary: any) => {
        const beneficiaryDetails: any = {};
        beneficiaryDetails.name = beneficiary.name;
        beneficiaryDetails.relationship = buildBeneficiaryRelationship(beneficiary);
        beneficiaryDetails.percentage = beneficiary.percentage;
        beneficiaries.push(beneficiaryDetails);
    });
    return beneficiaries;
};

const buildBeneficiaryRelationship = (data: any) => {
    const beneficiaryRelationship: any = {};
    const beneficiaryRelation = BENEFICIARY_RELATIONS.find(relation => relation.value === data.relationship);
    if (beneficiaryRelation?.relationRoleType) {
        beneficiaryRelationship.relationRoleType = beneficiaryRelation?.relationRoleType;
    }
    beneficiaryRelationship.text = beneficiaryRelation?.label;
    if (beneficiaryRelation?.relationDescription) {
        beneficiaryRelationship.relationDescription = beneficiaryRelation.relationDescription;
    }
    if (beneficiaryRelation?.organizationFormType) {
        beneficiaryRelationship.organizationFormType = beneficiaryRelation.organizationFormType;
    }
    if (beneficiaryRelation?.trustType) {
        beneficiaryRelationship.trustType = beneficiaryRelation.trustType;
    }
    return beneficiaryRelationship;
};

const buildPrimaryFinPro = (data: any) => {
    const primaryFinPro: any = {};
    primaryFinPro.firstName = data.primaryFinProFirstName;
    if (data.primaryFinProMiddleName) {
        primaryFinPro.middleName = data.primaryFinProMiddleName;
    }
    primaryFinPro.lastName = data.primaryFinProLastName;
    primaryFinPro.ssn = data.primaryFinProSsn;
    primaryFinPro.insuredFinancialProfessionalRelationship = buildFinProRelationship(data);
    primaryFinPro.phoneNumber = data.primaryFinProPhoneNumber;
    primaryFinPro.emailAddress = data.primaryFinProEmailAddress;
    if (data.selectedDetailCode) {
        primaryFinPro.selectedDetailCode = data.selectedDetailCode;
    } else if (data.primaryFinProDetailCode) {
        primaryFinPro.selectedDetailCode = data.primaryFinProDetailCode;
    }
    if (data.primaryFinProLicenseNumberMandatory) {
        primaryFinPro.licenseNumber = data.primaryFinProLicenseNumberMandatory;
    } else {
        primaryFinPro.licenseNumber = data.primaryFinProLicenseNumberNonMandatory;
    }
    if (data.primaryFinProDetailCode) {
        if (data.primaryFinProDetailCode === 'otherDetailCode') {
            primaryFinPro.detailCode = data.otherPrimaryDetailCode;
        } else if (data.primaryFinProDetailCode === 'unknownDetailCode') {
            primaryFinPro.detailCode = null;
        }
    } else if (data.primaryDetailCode) {
        primaryFinPro.detailCode = data.primaryDetailCode;
    }
    if (data.additionalFinPros.length > 0) {
        primaryFinPro.sellingCommission = data.additionalFinPros[0].sellingCommissionPercentage;
        primaryFinPro.servicingCommission = data.additionalFinPros[0].servicingCommissionPercentage;
    }
    if (data.stateWritten === IN_STATE_WRITTEN_CODE) {
        primaryFinPro.postalAddress = buildFinancialProfessionalPostalAddress(data);
    }
    primaryFinPro.enterpriseId = data.primaryFinProEnterpriseId;
    return primaryFinPro;
};

const buildFinancialProfessionalPostalAddress = (data: any) => {
    const financialProfessionalPostalAddress: any = {};
    financialProfessionalPostalAddress.addressLine1 = data.primaryFinProPostalAddressLine1;
    financialProfessionalPostalAddress.addressLine2 = data.primaryFinProPostalAddressLine2;
    financialProfessionalPostalAddress.city = data.primaryFinProPostalAddressCity;
    financialProfessionalPostalAddress.state = data.primaryFinProPostalAddressState;
    financialProfessionalPostalAddress.zip = data.primaryFinProPostalAddressZip;
    return financialProfessionalPostalAddress;
};

const buildFinProRelationship = (data: any) => {
    const insuredFinancialProfessionalRelationship: any = {};
    const insuredFinancialProfessionalRelation = FINPRO_RELATIONS.find(relation => relation.value === data.insuredFinancialProfessionalRelationship);
    if (insuredFinancialProfessionalRelation?.relationRoleType) {
        insuredFinancialProfessionalRelationship.relationRoleType = insuredFinancialProfessionalRelation?.relationRoleType;
    }
    insuredFinancialProfessionalRelationship.text = insuredFinancialProfessionalRelation?.label;
    return insuredFinancialProfessionalRelationship;
};

const buildAdditionalFinPro = (data: any[]) => {
    const additionalFinPros: any[] = [];
    data.shift();
    data.forEach((additionalFinPro: any) => {
        const additionalFinProDetails: any = {};
        additionalFinProDetails.firstName = additionalFinPro.finProFirstName;
        additionalFinProDetails.middleName = additionalFinPro.finProMiddleName;
        additionalFinProDetails.lastName = additionalFinPro.finProLastName;
        additionalFinProDetails.ssn = additionalFinPro.finProSsn;
        if (additionalFinPro.finProPhoneNumber) {
            additionalFinProDetails.phoneNumber = additionalFinPro.finProPhoneNumber;
        }
        if (additionalFinPro.finProEmailAddress) {
            additionalFinProDetails.emailAddress = additionalFinPro.finProEmailAddress;
        }
        additionalFinProDetails.detailCode = additionalFinPro.finProDetailCode;
        additionalFinProDetails.sellingCommission = additionalFinPro.sellingCommissionPercentage;
        additionalFinProDetails.servicingCommission = additionalFinPro.servicingCommissionPercentage;
        additionalFinPros.push(additionalFinProDetails);
    });
    return additionalFinPros;
};

const buildAttachments = async (request: any, uploadedAttachments: Array<CustomFile>) => {
    request.attachments = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const attachment of uploadedAttachments) {
        // eslint-disable-next-line no-await-in-loop
        const fileString = await readFileAsBase64(attachment.file);
        request.attachments.push(fileString);
    }
};

async function readFileAsBase64(file: FileWithPath): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64Data = reader.result as string;
            resolve(base64Data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

const setBoolean = (data: any) => {
    return data === 'true';
};

export default RequestBuilder;
