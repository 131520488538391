import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useHistory } from 'react-router-dom';
import '@pds/core/core.min.css';
import Icon from '@pds-react/icon';
import { useFormContext } from 'react-hook-form';
import * as Constants from '../utility/constants';
import submitDropTicket from '../services/submit-drop-ticket';
import StepIndicator from './commons/StepIndicator';
import ErrorModal from './commons/ErrorModal';
import FileUpload from './form-fields/FileUpload';

function Attestation(this: any, props: InferProps<typeof Attestation.propTypes>) {
    const {
        formState: { errors },
        setValue,
        watch,
        register,
        getValues,
        clearErrors
    } = useFormContext();

    const history = useHistory();
    const [isSubmitting, setSubmissionState] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [, setIsError] = useState(false);

    const showPrevious = () => {
        history.push(Constants.FINPRO_PATH);
    };

    const onSubmit = async () => {
        try {
            if (!props.requestData.insuredTitle) {
                const uploadedAttachments = getValues('attachFilesInput');
                setSubmissionState(true);
                await submitDropTicket(props.requestData, uploadedAttachments);
                setSubmissionState(false);
                history.push(Constants.SUBMIT_PATH);
            } else {
                throw Error();
            }
        } catch (error) {
            setShowErrorModal(true);
            setSubmissionState(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container pds-container">
            <StepIndicator step={Constants.ATTESTATION_STEP}
                isInsuredOwnerSame={props.requestData.insuredOwnerSame}
            />
            <br />
            {(process.env.UPLOAD_ATTACHMENTS_TOGGLE === 'ON') && (
                <>
                    <div className="row col-sm-9 col-lg-10 pds-util-padding-bottom-16">
                        <p className="pds-typography-h3">Upload Attachments</p>
                    </div>
                    <FileUpload id="attachFilesInput"
                        name="attachFilesInput"
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        clearErrors={clearErrors}
                        errors={errors}
                        labelText="Attach Files"
                    />
                    {' '}
                    <br />

                </>
            )}
            <br />
            <div className="row col-sm-9 col-lg-10 pds-util-padding-bottom-16">
                <p className="pds-typography-h3">Attestation</p>
            </div>
            <div className="pds-card">
                <div className="pds-card-section pds-util-padding-14" style={{ backgroundColor: '#E8E8E8' }}>
                    <p className="pds-typography-body-16 pds-typography-bold">
                        By clicking the “I Agree/Submit” button below, I certify that the following statements
                        are true and accurate:
                    </p>
                </div>
                <div className="pds-card-section pds-card-section-body pds-util-padding-14">
                    I certify that the following statements are true and accurate:
                    <br />
                    <br />
                    (1)  I am a duly licensed and appointed (if appointment is required) life
                    insurance agent in the state in which the applicant was solicited and in
                    the state in which the policy, if one is issued, will be delivered.
                    The plan and amount of insurance identified is appropriate in view of
                    the applicant’s insurance needs and financial objectives.
                    The information provided is complete, accurate, and correctly recorded.
                    All forms required to be delivered at time of solicitation have been
                    delivered and all other required forms (including privacy notices,
                    if necessary) have been or will be provided to the applicant.
                    <br />
                    (2)  I confirmed with the proposed applicant whether they have any existing life insurance or annuities; and
                    <br />
                    (a) the coverage being applied for does not replace or change any current insurance or annuity with any company; or
                    <br />
                    (b) if a replacement is involved, I have discussed the advantages and disadvantages of the replacement with the applicant,
                    and determined that the transaction is appropriate.  If existing coverage is involved, I have obtained
                    from the applicant the information required by state replacement laws and I have read the Important Notice Regarding Replacement
                    aloud to the applicant or the applicant did not wish the notice to be read aloud.
                    <br />
                    (3)  I do not know or have any reason to believe that any group of investors plans to obtain any right, title, or interest in any
                    policy issued as a result of this application.  To the best of my knowledge, neither the proposed insured nor owner has ever sold,
                    assigned or transferred ownership in any life policy to an outside investor group.
                    <br />
                    <br />
                    I authorize Principal National Life Insurance Company or Principal Life Insurance Company (collectively “Company”) to obtain such administrative
                    information as may be necessary to complete any life insurance application, replacement forms or supplemental forms resulting from this lead
                    submission, provided, however, that any item of information or question from the applicant requiring the act or advice of a licensed
                    life insurance agent will be referred to me for action before the application can be completed or will be obtained by life licensed
                    personnel of the Company.  I will personally review the application created from this data and administrative information provided
                    by the applicant and contact him or her concerning any incomplete or inconsistent information and I will not deliver the policy unless
                    I have completed my review and am satisfied that the policy, application and all attached papers, if any, are complete and accurate.
                    <br />
                    <br />
                    CA:  If an employer is applying for corporate owned life insurance, I confirmed with the proposed owner-employer that the proposed
                    insured is not a nonexempt employee.
                    <br />
                    <br />
                    AZ, CO, HI, TX, UT:  If a replacement is involved, I have or will have provided paper copies of any electronically presented
                    sales materials used in a presentation to the applicant no later than at the time of delivery of the policy.
                    <br />
                    <br />
                    ME:  I have or will have completed and reviewed the required Preliminary Statement of Policy Cost form with the applicant
                    before policy issuance or delivery of the policy.  The Preliminary Statement of Policy Cost form is included in the
                    quotation system.
                    <br />
                    <br />
                    I acknowledge that clicking the “I Agree/Submit” button below constitutes 1) my consent to do business and to conduct
                    business electronically and 2) my signature on the form and has the same effect as if I personally signed the form.
                    Where permitted by  state law, I authorize the Company to affix my signature to the life insurance application,
                    replacement form and all other forms required to be completed during the application process.
                </div>
            </div>
            <br />
            <div className="row col-sm-12 col-lg-12">
                <div className="pds-util-padding-left-2">
                    <button id="previous" className="pds-button pds-button-secondary" type="button" onClick={showPrevious} disabled={isSubmitting}>Previous</button>
                </div>
                <div className="pds-util-padding-left-12">
                    <button test-id="submit" id="submit" className="pds-button pds-button-primary" type="submit" onClick={onSubmit} disabled={isSubmitting}>I Agree/Submit</button>
                    {isSubmitting && (
                        <span className="text-center pds-util-margin-left-8">
                            <Icon name="loader" size={20} className="pds-util-padding-top-8" />
                            <span>Submitting...</span>
                        </span>
                    )}
                </div>
            </div>
            {showErrorModal && (
                <ErrorModal showErrorModal={showErrorModal} setShowErrorModal={setShowErrorModal} setIsError={setIsError} />
            )}
            <br />
        </div>
    );
}

Attestation.propTypes = {
    requestData: PropTypes.any.isRequired
};

export default Attestation;
