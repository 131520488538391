import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import * as Constants from '../../utility/constants';
import '@pds/util/util.min.css';
import '@pds/modal/modal.min.css';
import '@pds/typography/typography.min.css';
import MaskedInput from './MaskedInput';
import AdditionalFinProModal from './AdditionalFinProModal';

function AdditionalFinPro(props: InferProps<typeof AdditionalFinPro.propTypes>) {
    const [additionalFinPro, setAdditionalFinPro] = useState(null);
    const [hideModal, setHideModal] = useState(true);
    const [editFinProFlag, setEditFinProFlag] = useState(false);
    const [additionalFinProIndex, setAdditionalFinProIndex] = useState<number>();
    let zeroSellingCommissionWarning = '';
    let zeroServicingCommissionWarning = '';
    let isNaNFinProSellingPercentage = false;
    let isNaNFinProServicingPercentage = false;

    useEffect(() => {
        props.primaryFinPro.sellingCommissionPercentage = props.requestData.additionalFinPros[0]?.sellingCommissionPercentage;
        props.primaryFinPro.servicingCommissionPercentage = props.requestData.additionalFinPros[0]?.servicingCommissionPercentage;
        const additionalFinProsArray = props.requestData.additionalFinPros;
        additionalFinProsArray[0] = props.primaryFinPro;
        props.setRequest({ ...props.requestData, ...additionalFinProsArray });
    }, [props.showAdditionalFinProSection]);

    useEffect(() => {
        const additionalFinProsArray = props.requestData.additionalFinPros;
        if (additionalFinPro !== null && !editFinProFlag) {
            additionalFinProsArray.push(additionalFinPro);
            props.setRequest({ ...props.requestData, ...additionalFinProsArray });
        } else if (additionalFinPro !== null && editFinProFlag) {
            additionalFinProsArray.splice(additionalFinProIndex, 1, additionalFinPro);
            props.setRequest({ ...props.requestData, ...additionalFinProsArray });
        }
    }, [additionalFinPro]);

    const financialProfessionals = props.watch('additionalFinPros');

    const deleteAdditionalFinPro = (index: number) => {
        if (props.fields.length > 1) {
            props.remove(index);
            props.requestData.additionalFinPros.splice(index, 1);
        }
    };

    const editAdditionalFinPro = (index: number) => {
        setAdditionalFinProIndex(index);
        setEditFinProFlag(true);
        setAdditionalFinPro(props.requestData.additionalFinPros[index]);
        setHideModal(false);
    };

    const validateSellingPercentage = () => {
        const totalPercentage = calculateSellingPercentage();
        if (totalPercentage === 0 && isNaNFinProSellingPercentage) {
            return true;
        } else if (totalPercentage !== 100) {
            props.validateSellingPercentage(false);
            return false;
        } else {
            props.validateSellingPercentage(true);
            return true;
        }
    };

    const validateServicingPercentage = () => {
        const totalPercentage = calculateServicingPercentage();
        if (totalPercentage === 0 && isNaNFinProServicingPercentage) {
            return true;
        } else if (totalPercentage !== 100) {
            props.validateServicingPercentage(false);
            return false;
        } else {
            props.validateServicingPercentage(true);
            return true;
        }
    };

    const calculateSellingPercentage = () => {
        let totalPercentage = 0;
        let currentSellingPercentage;
        financialProfessionals.forEach((financialProfessional: any) => {
            currentSellingPercentage = Number(financialProfessional.sellingCommissionPercentage);
            if (Number.isNaN(currentSellingPercentage)) {
                currentSellingPercentage = 0;
                isNaNFinProSellingPercentage = true;
            }
            totalPercentage += currentSellingPercentage;
        });
        return totalPercentage;
    };

    const calculateServicingPercentage = () => {
        let totalPercentage = 0;
        let currentServicingPercentage;
        financialProfessionals.forEach((financialProfessional: any) => {
            currentServicingPercentage = Number(financialProfessional.servicingCommissionPercentage);
            if (Number.isNaN(currentServicingPercentage)) {
                currentServicingPercentage = 0;
                isNaNFinProServicingPercentage = true;
            }
            totalPercentage += currentServicingPercentage;
        });
        return totalPercentage;
    };

    const finProZeroSellingCommissionWarning = () => {
        let finProNames = '';
        financialProfessionals.forEach((financialProfessional: any, index: number) => {
            if (Number(financialProfessional.sellingCommissionPercentage) === 0) {
                finProNames = `${finProNames + props.requestData.additionalFinPros[index]?.finProFirstName} ${props.requestData.additionalFinPros[index]?.finProLastName}, `;
            }
        });
        if (finProNames !== '') {
            zeroSellingCommissionWarning = `Selling commission % is zero for ${finProNames.slice(0, -2)}. Are you sure this is correct? If yes, please proceed.`;
        }
        return zeroSellingCommissionWarning;
    };

    const finProZeroServicingCommissionWarning = () => {
        let finProNames = '';
        financialProfessionals.forEach((financialProfessional: any, index: number) => {
            if (Number(financialProfessional.servicingCommissionPercentage) === 0) {
                finProNames = `${finProNames + props.requestData.additionalFinPros[index]?.finProFirstName} ${props.requestData.additionalFinPros[index]?.finProLastName}, `;
            }
        });
        if (finProNames !== '') {
            zeroServicingCommissionWarning = `Servicing commission % is zero for ${finProNames.slice(0, -2)}. Are you sure this is correct? If yes, please proceed.`;
        }
        return zeroServicingCommissionWarning;
    };

    return (
        <>
            <div className="row col-sm-12 col-md-10 col-lg-8">
                <p className="pds-typography-h5">Additional Financial Professional</p>
            </div>
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <label htmlFor="addAdvisor"><b>Please list all additional financial professionals :</b></label>
                </div>
            </div>
            <AdditionalFinProModal append={props.append}
                fields={props.fields}
                additionalFinPro={additionalFinPro}
                setAdditonalFinPro={setAdditionalFinPro}
                hideModal={hideModal}
                setHideModal={setHideModal}
                editFinProFlag={editFinProFlag}
                setEditFinProFlag={setEditFinProFlag}
                setShowAdditionalFinProSection={(
                    showAdditionalFinProSection: boolean
                ) => props.setShowAdditionalFinProSection(showAdditionalFinProSection)}
            />
            {props.showAdditionalFinProSection && (
                <div>
                    <div className="row">
                        <table className="pds-table">
                            <thead>
                                <tr>
                                    <th className="col-lg-4">
                                        Financial Professional
                                    </th>
                                    <th className="col-lg-3">
                                        Selling commission %
                                        <label htmlFor="additionalFinProSellingCommissionPercentage"><span className="pds-label-required"> *</span></label>
                                    </th>
                                    <th className="col-lg-3">
                                        Servicing commission %
                                        <label htmlFor="additionalFinProServicingCommissionPercentage"><span className="pds-label-required"> *</span></label>
                                    </th>
                                    <th className="col-lg-1" />
                                    <th className="col-lg-1" />
                                </tr>
                            </thead>
                            <tbody>
                                {props.fields.map((additionalFinPros: any, index: number) => (
                                    <tr key={additionalFinPros.id}>
                                        <td id={`additionalFinPros.${index}.fullName`} data-heading="Financial Professional" className="pds-util-vertical-align-top col-lg-4">
                                            {props.requestData.additionalFinPros[index]?.finProFirstName}
                                            {' '}
                                            {props.requestData.additionalFinPros[index]?.finProLastName}
                                            {index === 0 && (' (Primary)')}
                                        </td>
                                        <td data-heading="Selling Commission %" className="pds-util-vertical-align-top col-lg-3">
                                            <MaskedInput id={`additionalFinPros.${index}.sellingCommissionPercentage`}
                                                name={`additionalFinPros.${index}.sellingCommissionPercentage`}
                                                mask={Constants.PERCENTAGE_MASK}
                                                defaultValue={props.requestData.additionalFinPros[index]?.sellingCommissionPercentage}
                                                control={props.control}
                                                errors={props.errors}
                                            />
                                        </td>
                                        <td data-heading="Servicing Commission %" className="pds-util-vertical-align-top col-lg-3">
                                            <MaskedInput id={`additionalFinPros.${index}.servicingCommissionPercentage`}
                                                name={`additionalFinPros.${index}.servicingCommissionPercentage`}
                                                mask={Constants.PERCENTAGE_MASK}
                                                defaultValue={props.requestData.additionalFinPros[index]?.servicingCommissionPercentage}
                                                control={props.control}
                                                errors={props.errors}
                                            />
                                        </td>
                                        <td className="pds-util-vertical-align-top col-lg-1">
                                            {index !== 0 && (
                                                <button type="button"
                                                    id={`editAdditionalFinPro.${index}.button`}
                                                    test-id={`editAdditionalFinPro.${index}.button`}
                                                    className="pds-button pds-button-tertiary-border"
                                                    onClick={() => editAdditionalFinPro(index)}
                                                >
                                                    Edit
                                                </button>)}
                                        </td>
                                        <td className="pds-util-vertical-align-top col-lg-1">
                                            {index !== 0 && (
                                                <button type="button"
                                                    id={`deleteAdditionalFinPro.${index}.button`}
                                                    test-id={`deleteAdditionalFinPro.${index}.button`}
                                                    className="pds-button pds-button-tertiary-border"
                                                    onClick={() => deleteAdditionalFinPro(index)}
                                                >
                                                    Delete
                                                </button>)}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="hidden-xs hidden-sm" />
                                    <td>
                                        <div>
                                            <p id="totalSellingPercentage">
                                                Total selling %:
                                                {' '}
                                                {calculateSellingPercentage()}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p id="totalServicingPercentage">
                                                Total servicing %:
                                                {' '}
                                                {calculateServicingPercentage()}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {!validateSellingPercentage() && (
                        <div className="row col-lg-12" id="sellingCommissionPercentageError">
                            <span className="pds-error">
                                Selling commission % total must equal 100%
                            </span>
                        </div>
                    )}
                    {!validateServicingPercentage() && (
                        <div className="row col-lg-12" id="servicingCommissionPercentageError">
                            <span className="pds-error">
                                Servicing commission % total must equal 100%
                            </span>
                        </div>
                    )}
                    {finProZeroSellingCommissionWarning() !== '' && (
                        <div className="row col-lg-12" id="finProSellingCommissionZeroError">
                            <span className="pds-error">
                                {zeroSellingCommissionWarning}
                            </span>
                        </div>
                    )}
                    {finProZeroServicingCommissionWarning() !== '' && (
                        <div className="row col-lg-12" id="finProServicingCommissionZeroError">
                            <span className="pds-error">
                                {zeroServicingCommissionWarning}
                            </span>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

AdditionalFinPro.propTypes = {
    watch: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    control: PropTypes.any.isRequired,
    append: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    setRequest: PropTypes.func.isRequired,
    requestData: PropTypes.any.isRequired,
    primaryFinPro: PropTypes.any.isRequired,
    showAdditionalFinProSection: PropTypes.bool.isRequired,
    setShowAdditionalFinProSection: PropTypes.func.isRequired,
    errors: PropTypes.any,
    validateServicingPercentage: PropTypes.any,
    validateSellingPercentage: PropTypes.any
};

AdditionalFinPro.defaultProps = {
    errors: undefined,
    validateServicingPercentage: undefined,
    validateSellingPercentage: undefined
};

export default AdditionalFinPro;
