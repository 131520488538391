import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ErrorSpan from './ErrorSpan';
import ValidationRules from '../../validations/ValidationRules';

function TextAreaField(props: InferProps<typeof TextAreaField.propTypes>) {
    const hasError = props.errors && props.errors[props.name];
    const rules = ValidationRules[props.name];

    return (
        <>
            <textarea {...props.register(props.name, rules)}
                data-testid={props.id}
                id={props.id}
                rows={8}
                className="pds-util-margin-left-10 col-lg-9"
                defaultValue={props.defaultValue}
            />
            <ErrorSpan hasError={hasError} name={props.name} errors={props.errors} />
        </>
    );
}

TextAreaField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.any.isRequired,
    register: PropTypes.any.isRequired,
    errors: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any,
    value: PropTypes.string,
    defaultValue: PropTypes.string
};

TextAreaField.defaultProps = {
    errors: undefined,
    onBlur: undefined,
    onChange: undefined,
    value: '',
    defaultValue: ''
};

export default TextAreaField;
