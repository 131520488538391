import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes, { InferProps } from 'prop-types';
import * as Constants from '../utility/constants';
import Insured from './Insured';
import Owner from './Owner';
import FinancialProfessional from './FinancialProfessional';
import Coverage from './Coverage';
import Attestation from './Attestation';
import Submit from './Submit';
import SubmitToFinPro from './SubmitToFinPro';

function Wizard(props: InferProps<typeof Wizard.propTypes>) {
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {
            ...props.requestData
        }
    });

    return (
        <FormProvider {...methods}>
            <Switch>
                <Route path={[Constants.INSURED_PATH, Constants.LANDING_PAGE_PATH]}
                    exact
                    render={() => (
                        <Insured requestData={props.requestData}
                            setRequest={props.setRequest}
                        />
                    )}
                />
                <Route path={[Constants.OWNER_PATH]}
                    exact
                    render={() => (
                        <Owner requestData={props.requestData}
                            setRequest={props.setRequest}
                        />
                    )}
                />
                <Route path={[Constants.FINPRO_PATH]}
                    exact
                    render={() => (
                        <FinancialProfessional requestData={props.requestData}
                            setRequest={props.setRequest}
                        />
                    )}
                />
                <Route path={[Constants.COVERAGE_PATH]}
                    exact
                    render={() => (
                        <Coverage requestData={props.requestData}
                            setRequest={props.setRequest}
                        />
                    )}
                />
                <Route path={[Constants.ATTESTATION_PATH]}
                    exact
                    render={() => (
                        <Attestation requestData={props.requestData} />
                    )}
                />
                <Route path={[Constants.SUBMIT_PATH]}
                    exact
                    render={() => (
                        <Submit />
                    )}
                />
                <Route path={[Constants.SUBMIT_TO_FINPRO_PATH]}
                    exact
                    render={() => (
                        <SubmitToFinPro />
                    )}
                />
            </Switch>
        </FormProvider>
    );
}

Wizard.propTypes = {
    setRequest: PropTypes.func.isRequired,
    requestData: PropTypes.any.isRequired
};

export default Wizard;
