import React, { useEffect, useState } from 'react';
import { UserData } from 'react-oidc';
import axios from 'axios';
import Icon from '@pds-react/icon';
import Wizard from './components/Wizard';
import * as Constants from './utility/constants';
import Navbar from './components/commons/Navbar';
import Footer from './components/commons/Footer';
import ErrorModal from './components/commons/ErrorModal';

const App: React.FC = () => {
    const [loadingScreen, setLoadingScreen] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isError, setIsError] = useState(false);
    const { user } = React.useContext(UserData);
    const [request, setRequest] = useState({
        language: Constants.LANGUAGE_ENGLISH,
        publicDropTicket: process.env.ACCESS_LEVEL === 'public',
        insuredExistingInsurancePolicies: [{}],
        ownerExistingInsurancePolicies: [{}],
        primaryBeneficiary: [{}],
        contingentBeneficiary: [{}],
        additionalFinPros: [{}],
        bgaSpecificOfficeNumber: '',
        bgaSpecificDropTicket: false,
        bgaSpecificOfficeDetails: [{}],
        attachFilesInput: []
    });

    useEffect(() => {
        if (process.env.ACCESS_LEVEL === 'secure') {
            const urlParams = new URLSearchParams(window.location.search);
            const partialDropTicketId = urlParams.get('id');
            const authToken = user !== null ? user.access_token : '';
            if (partialDropTicketId) {
                axios.get(`${process.env.DROP_TICKET_SYSTEM_PARTIAL_URL}/${partialDropTicketId}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }).then(partialDropTicketResponse => {
                    setRequest({
                        ...request,
                        ...JSON.parse(JSON.stringify(partialDropTicketResponse.data)),
                        isPartialDropTicket: true
                    });
                    setLoadingScreen(false);
                }).catch(() => {
                    setShowErrorModal(true);
                });
            } else {
                setLoadingScreen(false);
                axios.get(`${process.env.DROP_TICKET_SYSTEM_FINPRO_URL}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }).then(res => {
                    setRequest({ ...request, ...JSON.parse(JSON.stringify(res.data)) });
                }).catch(() => {
                    setShowErrorModal(true);
                });
            }
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            const bgaSpecificOfficeNumber = urlParams.get('o');
            if (bgaSpecificOfficeNumber) {
                setRequest({ ...request, bgaSpecificOfficeNumber, bgaSpecificDropTicket: true });
            }
            setLoadingScreen(false);
        }
    }, [isError]);

    return (
        <>
            <Navbar />
            <br />
            {loadingScreen && (
                <span className="text-center">
                    <Icon name="loader" size={20} className="pds-util-padding-top-8" />
                    <span>Loading...</span>
                </span>
            )}
            {showErrorModal && (
                <ErrorModal showErrorModal={showErrorModal} setShowErrorModal={setShowErrorModal} setIsError={setIsError} />
            )}
            {(!loadingScreen && !showErrorModal) && (
                <Wizard setRequest={setRequest} requestData={request} />
            )}
            <Footer />
        </>
    );
};

export default App;
