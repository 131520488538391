import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import * as Constants from '../../utility/constants';
import TextField from './TextField';
import SelectField from './SelectField';
import '@pds/util/util.min.css';
import MaskedInput from './MaskedInput';

function Beneficiary(props: InferProps<typeof Beneficiary.propTypes>) {
    const beneficiaries = props.watch(`${props.idPrefix}Beneficiary`);

    const addBeneficiary = () => {
        if (props.fields.length < 4) {
            props.append({});
        }
    };

    const deleteBeneficiary = (index: number) => {
        if (props.fields.length > 1) {
            props.remove(index);
        }
    };

    const validatePercentage = () => {
        const totalPercentage = calculatePercentage();
        if (totalPercentage === 0) {
            return true;
        } else if (totalPercentage !== 100) {
            setPercentageValidationResponse(false);
            return false;
        } else {
            setPercentageValidationResponse(true);
            return true;
        }
    };

    const calculatePercentage = () => {
        let totalPercentage = 0;
        let currentPercentage;
        beneficiaries.forEach((beneficiary: any) => {
            currentPercentage = Number(beneficiary.percentage);
            if (Number.isNaN(currentPercentage)) {
                currentPercentage = 0;
            }
            totalPercentage += currentPercentage;
        });
        return totalPercentage;
    };

    const setPercentageValidationResponse = (percentageValidationResponse: boolean) => {
        if (props.idPrefix === 'primary') {
            props.validatePrimaryBeneficiaryPercentage(percentageValidationResponse);
        } else if (props.idPrefix === 'contingent') {
            props.validateContingentBeneficiaryPercentage(percentageValidationResponse);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <label htmlFor={`${props.idPrefix}Beneficiary`}>
                        <b>
                            Please list all &nbsp;
                            {props.idPrefix}
                            {' '}
                            beneficiaries (at least one is required):
                            {' '}
                        </b>
                        <span className="pds-label-required">*</span>
                    </label>
                    {(props.idPrefix === 'primary' && !validatePercentage()) && (
                        <div id="primaryBeneficiaryPercentageError">
                            <span className="pds-error">
                                Primary Beneficiary percentage total must equal 100%
                            </span>
                        </div>
                    )}
                    {(props.idPrefix === 'contingent' && !validatePercentage()) && (
                        <div id="contingentBeneficiaryPercentageError">
                            <span className="pds-error">
                                Contingent Beneficiary percentage total must equal 100%
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className="row pds-util-margin-top-16">
                <div className="col-lg-12">
                    <button type="button" className="pds-button pds-button-tertiary" onClick={addBeneficiary}>Add Beneficiary</button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <table className="pds-table">
                        <thead>
                            <tr>
                                <th>
                                    Name
                                    <label htmlFor={`${props.idPrefix}BeneficiaryName`}><span className="pds-label-required"> *</span></label>
                                </th>
                                <th>
                                    Relationship
                                    <label htmlFor={`${props.idPrefix}BeneficiaryRelationship`}><span className="pds-label-required"> *</span></label>
                                </th>
                                <th>
                                    %
                                    <label htmlFor={`${props.idPrefix}BeneficiaryPercentage`}><span className="pds-label-required"> *</span></label>
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {props.fields.map((beneficiary: any, index: number) => (
                                <tr key={beneficiary.id}>
                                    <td data-heading="Name" className="pds-util-vertical-align-top">
                                        <TextField id={`${props.idPrefix}Beneficiary.${index}.name`}
                                            name={`${props.idPrefix}Beneficiary.${index}.name`}
                                            type="text"
                                            register={props.register}
                                            errors={props.errors}
                                            defaultValue={props.requestData[`${props.idPrefix}Beneficiary`]?.[index]?.name}
                                        />
                                    </td>
                                    <td data-heading="Relationship" className="pds-util-vertical-align-top">
                                        <SelectField id={`${props.idPrefix}Beneficiary.${index}.relationship`}
                                            name={`${props.idPrefix}Beneficiary.${index}.relationship`}
                                            register={props.register}
                                            options={Constants.BENEFICIARY_RELATIONS}
                                            errors={props.errors}
                                            defaultValue={props.requestData[`${props.idPrefix}Beneficiary`]?.[index]?.relationship}
                                        />
                                    </td>
                                    <td data-heading="%" className="pds-util-vertical-align-top">
                                        <MaskedInput id={`${props.idPrefix}Beneficiary.${index}.percentage`}
                                            name={`${props.idPrefix}Beneficiary.${index}.percentage`}
                                            mask={Constants.PERCENTAGE_MASK}
                                            errors={props.errors}
                                            defaultValue={props.requestData[`${props.idPrefix}Beneficiary`]?.[index]?.percentage}
                                            control={props.control}
                                        />
                                    </td>
                                    <td className="pds-util-vertical-align-top">
                                        <button type="button" className="pds-button pds-button-tertiary-border" onClick={() => deleteBeneficiary(index)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className="hidden-xs hidden-sm" />
                                <td className="hidden-xs hidden-sm" />
                                <td>
                                    <div>
                                        <p id="totalPrimaryPercentage">
                                            Total %:
                                            {' '}
                                            {calculatePercentage()}
                                        </p>
                                    </div>
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

Beneficiary.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    control: PropTypes.any.isRequired,
    fields: PropTypes.array.isRequired,
    append: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    idPrefix: PropTypes.string.isRequired,
    errors: PropTypes.any,
    requestData: PropTypes.any.isRequired,
    validatePrimaryBeneficiaryPercentage: PropTypes.any,
    validateContingentBeneficiaryPercentage: PropTypes.any
};

Beneficiary.defaultProps = {
    errors: undefined,
    validatePrimaryBeneficiaryPercentage: undefined,
    validateContingentBeneficiaryPercentage: undefined
};

export default Beneficiary;
