import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ErrorSpan from './ErrorSpan';
import ValidationRules from '../../validations/ValidationRules';

function TextField(props: InferProps<typeof TextField.propTypes>) {
    const checkHasError = (): boolean => {
        const nameSplitArray = props.name.split('.');
        if (nameSplitArray.length > 1) {
            return props.errors
                && props.errors[nameSplitArray[0]]
                && props.errors[nameSplitArray[0]][nameSplitArray[1]]
                && props.errors[nameSplitArray[0]][nameSplitArray[1]][`${nameSplitArray[2]}`];
        }
        return props.errors && props.errors[props.name];
    };

    const getValidationRules = () => {
        const nameSplitArray = props.name.split('.');
        if (nameSplitArray.length > 1) {
            return ValidationRules[nameSplitArray[0]][`${nameSplitArray[2]}`];
        }
        return ValidationRules[props.name];
    };

    const hasError = checkHasError();
    const rules = getValidationRules();

    const inputRegister = props.register(props.name, rules);

    return (
        <label htmlFor={props.id}>
            <b>
                {props.labelText}
                {' '}
            </b>
            {props.required && <span className="pds-label-required">*</span>}
            <input id={props.id}
                data-testid={props.id}
                maxLength={props.maxLength || undefined}
                type={props.type}
                className={hasError ? 'pds-input-error' : ''}
                placeholder={props.placeHolder || ''}
                {...inputRegister}
                onBlur={(event) => {
                    inputRegister.onBlur(event);
                    if (props.onBlur) {
                        props.onBlur(event);
                    }
                }}
                onChange={(event) => {
                    inputRegister.onChange(event);
                    if (props.onChange) {
                        props.onChange(event);
                    }
                }}
                defaultValue={props.defaultValue}
                value={props.value}
                readOnly={props.readOnly}
                autoComplete='none'
            />
            <ErrorSpan hasError={hasError} name={props.name} errors={props.errors} />
        </label>
    );
}

TextField.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.any.isRequired,
    register: PropTypes.any.isRequired,
    maxLength: PropTypes.number,
    errors: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    labelText: PropTypes.string,
    placeHolder: PropTypes.string,
    defaultValue: PropTypes.any,
    required: PropTypes.bool
};

TextField.defaultProps = {
    maxLength: undefined,
    errors: undefined,
    onBlur: undefined,
    onChange: undefined,
    value: undefined,
    readOnly: false,
    labelText: '',
    placeHolder: '',
    required: false,
    defaultValue: ''
};

export default TextField;
