import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import '@pds/core/core.min.css';
import '@pds/util/util.min.css';
import '@pds/radio/radio.min.css';
import '@pds/input/input.min.css';
import '@pds/label/label.min.css';
import '@pds/select/select.min.css';
import '@pds/icon/icon.min.css';
import '@pds/tooltip/tooltip.min.css';
import '@pds/button/button.min.css';
import '@pds/erroredInput/erroredInput.min.css';
import '@pds/table/table.min.css';
import '@pds/typography/typography.min.css';
import { useHistory } from 'react-router-dom';
import * as Constants from '../utility/constants';
import RadioField from './form-fields/RadioField';
import TextField from './form-fields/TextField';
import SelectField from './form-fields/SelectField';
import ExistingInsurance from './form-fields/ExistingInsurance';
import StepIndicator from './commons/StepIndicator';
import MaskedInput from './form-fields/MaskedInput';

function Owner(props: InferProps<typeof Owner.propTypes>) {
    const { register, formState: { errors }, handleSubmit, watch, control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'ownerExistingInsurancePolicies',
        control
    });

    const [replacementErrorResponse, setReplacementErrorResponse] = useState(false);

    const history = useHistory();
    const ownerType = watch('ownerType');

    const onSubmit = (ownerDetails: any) => {
        if (!replacementErrorResponse) {
            props.setRequest({ ...props.requestData, ...ownerDetails });
            history.push(Constants.COVERAGE_PATH);
        }
    };

    const showPrevious = () => {
        history.push(Constants.INSURED_PATH);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ownerEnglishPrimaryLanguageFlag = watch('ownerEnglishPrimaryLanguageFlag', 'true');
    const ownerEnglishFluencyFlag = watch('ownerEnglishFluencyFlag', 'true');
    const ownerRepresentativeEnglishPrimaryLanguageFlag = watch('ownerRepresentativeEnglishPrimaryLanguageFlag', 'true');
    const ownerRepresentativeEnglishFluencyFlag = watch('ownerRepresentativeEnglishFluencyFlag', 'true');

    return (
        <div className="container pds-container">
            <StepIndicator step={Constants.OWNER_STEP}
                isInsuredOwnerSame={props.requestData.insuredOwnerSame}
            />
            <br />
            <div className="row col-sm-9 col-lg-10">
                <p className="pds-typography-h3">Owner</p>
            </div>
            <div className="row pds-util-float-right pds-util-padding-right-12">
                <label htmlFor="required">
                    <span className="pds-label-required">*</span>
                    <small className="pds-util-float-right" id="required">Required</small>
                </label>
            </div>
            <br />
            <form name="forms.owner" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="ownerType">
                            <b>Owner type </b>
                            <span className="pds-label-required">*</span>
                        </label>
                        <br />
                    </div>
                    <div className="row col-lg-10">
                        <RadioField name="ownerType"
                            values={Constants.OWNER_TYPE_APP_REDESIGN}
                            register={register}
                            errors={errors}
                            defaultChecked={props.requestData.ownerType}
                        />
                    </div>
                </div>
                <br />
                {(ownerType === 'Individual') && (
                    <>
                        <div id="individualOwnerName">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <TextField id="ownerFirstName"
                                        labelText="First name"
                                        name="ownerFirstName"
                                        type="text"
                                        register={register}
                                        errors={errors}
                                        defaultValue={props.requestData.ownerFirstName}
                                        required
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <TextField id="ownerMiddleName"
                                        labelText="Middle name"
                                        name="ownerMiddleName"
                                        type="text"
                                        register={register}
                                        errors={errors}
                                        defaultValue={props.requestData.ownerMiddleName}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <TextField id="ownerLastName"
                                        labelText="Last name"
                                        name="ownerLastName"
                                        type="text"
                                        register={register}
                                        errors={errors}
                                        defaultValue={props.requestData.ownerLastName}
                                        required
                                    />
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <SelectField id="ownerRelationship"
                                    labelText="Relationship"
                                    name="ownerRelationship"
                                    options={Constants.INDIVIDUAL_OWNER_RELATIONS}
                                    register={register}
                                    errors={errors}
                                    defaultValue={props.requestData.ownerRelationship}
                                    required
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <MaskedInput id="ownerSsn"
                                    labelText="Social security number"
                                    name="ownerSsn"
                                    mask={Constants.FULL_SSN_MASK}
                                    defaultValue={props.requestData.ownerSsn}
                                    control={control}
                                    errors={errors}
                                    onBlur={Constants.maskSsn}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12 col-lg-8">
                                <TextField id="ownerEmailAddress"
                                    labelText="Email address"
                                    name="ownerEmailAddress"
                                    type="text"
                                    register={register}
                                    errors={errors}
                                    defaultValue={props.requestData.ownerEmailAddress}
                                    required
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <MaskedInput id="ownerPhoneNumber"
                                    labelText="Phone number"
                                    name="ownerPhoneNumber"
                                    placeHolder="(999) 999-9999"
                                    mask={Constants.PHONE_MASK}
                                    defaultValue={props.requestData.ownerPhoneNumber}
                                    control={control}
                                    errors={errors}
                                    required
                                />
                            </div>
                            <div className="row col-sm-12 col-md-6 col-lg-6">
                                <div className="col-lg-10">
                                    <label htmlFor="ownerPhoneType">
                                        <b>Is this a mobile number? </b>
                                        <span className="pds-label-required">*</span>
                                    </label>
                                    <br />
                                </div>
                                <div className="row col-lg-10">
                                    <RadioField name="ownerPhoneType"
                                        values={Constants.YES_NO_INPUTS}
                                        register={register}
                                        errors={errors}
                                        defaultChecked={props.requestData.ownerPhoneType}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-lg-10">
                                <label htmlFor="ownerEnglishPrimaryLanguageFlag">
                                    <b>Is English primary/native language of the owner? </b>
                                    <span className="pds-label-required">*</span>
                                </label>
                            </div>
                            <div className="row col-lg-10">
                                <RadioField name="ownerEnglishPrimaryLanguageFlag"
                                    values={Constants.YES_NO_INPUTS}
                                    register={register}
                                    errors={errors}
                                    defaultChecked={props.requestData.ownerEnglishPrimaryLanguageFlag}
                                />
                            </div>
                        </div>
                        <br />
                        {ownerEnglishPrimaryLanguageFlag === 'false' && (
                            <>
                                <div className="row">
                                    <div className="col-lg-10">
                                        <label htmlFor="ownerEnglishFluencyFlag">
                                            <b>Is owner fluent in reading, speaking, and understanding English? </b>
                                            <span className="pds-label-required">*</span>
                                        </label>
                                    </div>
                                    <div className="row col-lg-10">
                                        <RadioField name="ownerEnglishFluencyFlag"
                                            values={Constants.YES_NO_INPUTS}
                                            register={register}
                                            errors={errors}
                                            defaultChecked={props.requestData.ownerEnglishFluencyFlag}
                                        />
                                    </div>
                                </div>
                                <br />
                                {ownerEnglishFluencyFlag === 'false' && (
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-8 col-lg-6">
                                                <SelectField id="ownerPrimaryLanguage"
                                                    labelText="What is the primary language of the owner?"
                                                    name="ownerPrimaryLanguage"
                                                    options={Constants.LANGUAGES}
                                                    register={register}
                                                    errors={errors}
                                                    defaultValue={props.requestData.ownerPrimaryLanguage}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="pds-card pds-alert pds-alert-information">
                                            <div className="pds-card-section pds-util-padding-vert-8">
                                                <div className="pds-alert-content" role="alert">
                                                    <b>Based on the above response, please submit the Statement of English Understanding form, DD 992.</b>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                )}
                            </>
                        )}
                        <ExistingInsurance register={register}
                            watch={watch}
                            fields={fields}
                            append={append}
                            remove={remove}
                            idPrefix="owner"
                            labelFor="owner"
                            errors={errors}
                            hasReplacementError={(replacementErrorFlag: boolean) => setReplacementErrorResponse(replacementErrorFlag)}
                            requestData={props.requestData}
                            setRequest={props.setRequest}
                        />
                    </>
                )}
                {(ownerType === 'Organization' || ownerType === 'Trust') && (
                    <>
                        {ownerType === 'Organization' && (
                            <div id="organizationOwnerName">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <TextField id="ownerName"
                                            labelText="Name"
                                            name="ownerName"
                                            type="text"
                                            register={register}
                                            errors={errors}
                                            defaultValue={props.requestData.ownerName}
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        {ownerType === 'Trust' && (
                            <div id="trustOwnerName">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <TextField id="ownerName"
                                            labelText="Name"
                                            name="ownerName"
                                            type="text"
                                            register={register}
                                            errors={errors}
                                            defaultValue={props.requestData.ownerName}
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        <div className="row">
                            {ownerType === 'Organization' && (
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <SelectField id="ownerRelationship"
                                        labelText="Relationship"
                                        name="ownerRelationship"
                                        options={Constants.ORGANIZATION_OWNER_RELATIONS}
                                        register={register}
                                        errors={errors}
                                        defaultValue={props.requestData.ownerRelationship}
                                        required
                                    />
                                </div>
                            )}
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <MaskedInput id="ownerSsn"
                                    labelText="SSN or Tax Id"
                                    name="ownerSsn"
                                    mask={Constants.FULL_SSN_MASK}
                                    defaultValue={props.requestData.ownerSsn}
                                    control={control}
                                    errors={errors}
                                    onBlur={Constants.maskSsn}
                                />
                            </div>
                        </div>
                        <br />
                        <ExistingInsurance register={register}
                            watch={watch}
                            fields={fields}
                            append={append}
                            remove={remove}
                            idPrefix="owner"
                            labelFor="owner"
                            errors={errors}
                            hasReplacementError={(replacementErrorFlag: boolean) => setReplacementErrorResponse(replacementErrorFlag)}
                            requestData={props.requestData}
                            setRequest={props.setRequest}
                        />
                        <br />
                        <div className="col-sm-9 col-lg-10">
                            {ownerType === 'Organization' && (
                                <>
                                    <p className="row pds-typography-h5">Organization Representative</p>
                                    <p className="row pds-typography-h7">This person will sign on behalf of the Organization</p>
                                </>
                            )}
                            {ownerType === 'Trust' && (
                                <>
                                    <p className="row pds-typography-h5">Trustee</p>
                                    <p className="row pds-typography-h7">This person will sign on behalf of the Trust</p>
                                </>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <TextField id="ownerRepresentativeFirstName"
                                    labelText="First name"
                                    name="ownerRepresentativeFirstName"
                                    type="text"
                                    register={register}
                                    errors={errors}
                                    defaultValue={props.requestData.ownerRepresentativeFirstName}
                                    required
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <TextField id="ownerRepresentativeMiddleName"
                                    labelText="Middle name"
                                    name="ownerRepresentativeMiddleName"
                                    type="text"
                                    register={register}
                                    errors={errors}
                                    defaultValue={props.requestData.ownerRepresentativeMiddleName}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <TextField id="ownerRepresentativeLastName"
                                    labelText="Last name"
                                    name="ownerRepresentativeLastName"
                                    type="text"
                                    register={register}
                                    errors={errors}
                                    defaultValue={props.requestData.ownerRepresentativeLastName}
                                    required
                                />
                            </div>
                        </div>
                        <br />
                        {ownerType === 'Organization' && (
                            <div id="ownerRepresntativeOfficerTitleDiv">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <TextField id="ownerRepresentativeOfficerTitle"
                                            labelText="Title of officer"
                                            name="ownerRepresentativeOfficerTitle"
                                            type="text"
                                            register={register}
                                            errors={errors}
                                            defaultValue={props.requestData.ownerRepresentativeOfficerTitle}
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField id="ownerRepresentativeEmailAddress"
                                    labelText="Email address"
                                    name="ownerRepresentativeEmailAddress"
                                    type="text"
                                    register={register}
                                    errors={errors}
                                    defaultValue={props.requestData.ownerRepresentativeEmailAddress}
                                    required
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <MaskedInput id="ownerRepresentativePhoneNumber"
                                    labelText="Phone number"
                                    name="ownerRepresentativePhoneNumber"
                                    placeHolder="(999) 999-9999"
                                    mask={Constants.PHONE_MASK}
                                    defaultValue={props.requestData.ownerRepresentativePhoneNumber}
                                    control={control}
                                    errors={errors}
                                    required
                                />
                            </div>
                            <div className="row col-sm-12 col-md-6 col-lg-6">
                                <div className="col-lg-10">
                                    <label htmlFor="ownerRepresentativePhoneType">
                                        <b>Is this a mobile number? </b>
                                        <span className="pds-label-required">*</span>
                                    </label>
                                    <br />
                                </div>
                                <div className="row col-lg-10">
                                    <RadioField name="ownerRepresentativePhoneType"
                                        values={Constants.YES_NO_INPUTS}
                                        register={register}
                                        errors={errors}
                                        defaultChecked={props.requestData.ownerRepresentativePhoneType}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-lg-10">
                                <label htmlFor="ownerRepresentativeEnglishPrimaryLanguageFlag">
                                    <b>Is English primary/native language of the owner? </b>
                                    <span className="pds-label-required">*</span>
                                </label>
                            </div>
                            <div className="row col-lg-10">
                                <RadioField name="ownerRepresentativeEnglishPrimaryLanguageFlag"
                                    values={Constants.YES_NO_INPUTS}
                                    register={register}
                                    errors={errors}
                                    defaultChecked={props.requestData.ownerRepresentativeEnglishPrimaryLanguageFlag}
                                />
                            </div>
                        </div>
                        <br />
                        {ownerRepresentativeEnglishPrimaryLanguageFlag === 'false' && (
                            <>
                                <div className="row">
                                    <div className="col-lg-10">
                                        <label htmlFor="ownerRepresentativeEnglishFluencyFlag">
                                            <b>Is owner fluent in reading, speaking, and understanding English? </b>
                                            <span className="pds-label-required">*</span>
                                        </label>
                                    </div>
                                    <div className="row col-lg-10">
                                        <RadioField name="ownerRepresentativeEnglishFluencyFlag"
                                            values={Constants.YES_NO_INPUTS}
                                            register={register}
                                            errors={errors}
                                            defaultChecked={props.requestData.ownerRepresentativeEnglishFluencyFlag}
                                        />
                                    </div>
                                </div>
                                <br />
                                {ownerRepresentativeEnglishFluencyFlag === 'false' && (
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-8 col-lg-6">
                                                <SelectField id="ownerRepresentativePrimaryLanguage"
                                                    labelText="What is the primary language of the owner?"
                                                    name="ownerRepresentativePrimaryLanguage"
                                                    options={Constants.LANGUAGES}
                                                    register={register}
                                                    errors={errors}
                                                    defaultValue={props.requestData.ownerRepresentativePrimaryLanguage}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="pds-card pds-alert pds-alert-information">
                                            <div className="pds-card-section pds-util-padding-vert-8">
                                                <div className="pds-alert-content" role="alert">
                                                    <b>Based on the above response, please submit the Statement of English Understanding form, DD 992.</b>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
                <div className="row col-sm-12 col-lg-12">
                    <div className="pds-util-padding-left-2">
                        <button id="previous" className="pds-button pds-button-secondary" type="button" onClick={showPrevious}>Previous</button>
                    </div>
                    <div className="pds-util-padding-left-12">
                        <button id="next" className="pds-button pds-button-primary" type="submit">Next</button>
                    </div>
                </div>
                <br />
            </form>
        </div>
    );
}

Owner.propTypes = {
    setRequest: PropTypes.func.isRequired,
    requestData: PropTypes.any.isRequired
};

export default Owner;
