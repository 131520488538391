const ErrorMessages: any = {
    insuredFirstName: {
        required: 'First name is required',
        maxLength: 'Please enter 15 characters or less'
    },
    insuredMiddleName: {
        maxLength: 'Please enter 15 characters or less'
    },
    insuredLastName: {
        required: 'Last name is required',
        maxLength: 'Please enter 24 characters or less'
    },
    insuredGender: {
        required: 'Gender is required'
    },
    insuredDateOfBirth: {
        required: 'Date of birth is required',
        pattern: 'Please enter date in MM/DD/YYYY format'
    },
    insuredSsn: {
        required: false,
        pattern: 'Please enter a valid SSN'
    },
    insuredPostalAddressLine1: {
        required: 'Address line 1 is required',
        maxLength: 'Please enter 24 characters or less'
    },
    insuredPostalAddressLine2: {
        maxLength: 'Please enter 24 characters or less'
    },
    insuredPostalAddressCity: {
        required: 'City is required',
        maxLength: 'Please enter 20 characters or less'
    },
    insuredPostalAddressState: {
        required: 'State is required'
    },
    insuredPostalAddressZip: {
        required: 'Zip is required',
        pattern: 'Please enter valid ZIP'
    },
    insuredPhoneNumber: {
        required: 'Phone number is required',
        pattern: 'Please enter a valid phone number. Toll free numbers cannot be used.'
    },
    insuredPhoneType: {
        required: 'Phone type is required'
    },
    insuredEmailAddress: {
        required: 'Email address is required',
        pattern: 'Please enter email in valid format'
    },
    insuredEnglishPrimaryLanguageFlag: {
        required: 'English primary language response is required'
    },
    insuredEnglishFluencyFlag: {
        required: 'English fluency response is required'
    },
    insuredPrimaryLanguage: {
        required: 'Primary language is required'
    },
    partBLifeDiCombo: {
        required: 'Response is required'
    },
    insuredOwnerSame: {
        required: 'Insured owner same response is required'
    },
    insuredExistingInsuranceResponse: {
        required: 'Existing insurance response is required'
    },
    insuredExistingInsuranceReplacementResponse: {
        required: 'Existing insurance replacement response is required'
    },
    specialCallingInstructions: {
        maxLength: 'Please enter 255 characters or less'
    },
    insuredExistingInsurancePolicies: {
        company: {
            required: 'Company name is required'
        },
        amount: {
            required: 'Amount is required'
        }
    },
    ownerType: {
        required: 'Owner type is required'
    },
    ownerFirstName: {
        required: 'First name is required',
        maxLength: 'Please enter 15 characters or less'
    },
    ownerMiddleName: {
        maxLength: 'Please enter 15 characters or less'
    },
    ownerLastName: {
        required: 'Last name is required',
        maxLength: 'Please enter 24 characters or less'
    },
    ownerName: {
        required: 'Owner name is required',
        maxLength: 'Please enter 40 characters or less'
    },
    ownerRelationship: {
        required: 'Owner relationship is required'
    },
    ownerSsn: {
        required: false,
        pattern: 'Please enter a valid SSN'
    },
    ownerOfficerTitle: {
        required: 'Title of officer is required',
        maxLength: 'Please enter 40 characters or less'
    },
    ownerExistingInsuranceResponse: {
        required: 'Existing insurance response is required'
    },
    ownerExistingInsuranceReplacementResponse: {
        required: 'Existing insurance replacement response is required'
    },
    ownerExistingInsurancePolicies: {
        company: {
            required: 'Company name is required'
        },
        amount: {
            required: 'Amount is required'
        }
    },
    ownerEmailAddress: {
        required: 'Email address is required',
        pattern: 'Please enter email in valid format'
    },
    ownerPhoneNumber: {
        required: 'Phone number is required',
        pattern: 'Please enter a valid phone number. Toll free numbers cannot be used.'
    },
    ownerPhoneType: {
        required: 'Phone type is required'
    },
    ownerEnglishPrimaryLanguageFlag: {
        required: 'English primary language response is required'
    },
    ownerEnglishFluencyFlag: {
        required: 'English fluency response is required'
    },
    ownerPrimaryLanguage: {
        required: 'Primary language is required'
    },
    ownerRepresentativeFirstName: {
        required: 'First name is required',
        maxLength: 'Please enter 15 characters or less'
    },
    ownerRepresentativeMiddleName: {
        maxLength: 'Please enter 15 characters or less'
    },
    ownerRepresentativeLastName: {
        required: 'Last name is required',
        maxLength: 'Please enter 24 characters or less'
    },
    ownerRepresentativeOfficerTitle: {
        required: 'Title of officer is required',
        maxLength: 'Please enter 40 characters or less'
    },
    ownerRepresentativeEmailAddress: {
        required: 'Email address is required',
        pattern: 'Please enter email in valid format'
    },
    ownerRepresentativePhoneNumber: {
        required: 'Phone number is required',
        pattern: 'Please enter a valid phone number. Toll free numbers cannot be used.'
    },
    ownerRepresentativePhoneType: {
        required: 'Phone type is required'
    },
    ownerRepresentativeEnglishPrimaryLanguageFlag: {
        required: 'English primary language response is required'
    },
    ownerRepresentativeEnglishFluencyFlag: {
        required: 'English fluency response is required'
    },
    ownerRepresentativePrimaryLanguage: {
        required: 'Primary language is required'
    },
    stateWritten: {
        required: 'State is required'
    },
    product: {
        required: 'Product is required'
    },
    amountOfInsurance: {
        required: 'Face amount is required'
    },
    premium: {
        required: 'Premium amount is required'
    },
    billingFrequency: {
        required: 'Billing frequency is required'
    },
    billingType: {
        required: 'Billing Method is required'
    },
    listBillNumber: {
        pattern: 'Please enter valid existing number'
    },
    billingClientId: {
        pattern: 'Please enter a valid Client ID'
    },
    underwritingClass: {
        required: 'Underwriting class is required'
    },
    tobaccoUse: {
        required: 'Tobacco use response is required'
    },
    conversionOption: {
        required: 'Conversion option is required'
    },
    salaryIncreaseRiderAmount: {
        required: 'Amount is required'
    },
    alternateAdditionalProduct: {
        required: 'Product is required'
    },
    alternateAdditionalFaceAmount: {
        required: 'Face amount is required'
    },
    primaryBeneficiary: {
        name: {
            required: 'Name is required'
        },
        relationship: {
            required: 'Relationship is required'
        },
        percentage: {
            required: 'Percentage is required',
            pattern: 'Invalid Percentage'
        }
    },
    contingentBeneficiary: {
        name: {
            required: 'Name is required'
        },
        relationship: {
            required: 'Relationship is required'
        },
        percentage: {
            required: 'Percentage is required',
            pattern: 'Invalid Percentage'
        }
    },
    fieldOfficeContactName: {
        required: 'Name is required',
        pattern: 'Please enter a valid name'
    },
    fieldOfficeContactPhoneNumber: {
        pattern: 'Please enter a valid phone number'
    },
    fieldOfficeContactEmailAddress: {
        required: 'Email address is required',
        pattern: 'Please enter email in valid format'
    },
    primaryFinProFirstName: {
        required: 'First name is required'
    },
    primaryFinProLastName: {
        required: 'Last name is required'
    },
    primaryFinProSsn: {
        required: 'SSN is required'
    },
    insuredFinancialProfessionalRelationship: {
        required: 'Insured relation to financial professional is required'
    },
    primaryFinProPhoneNumber: {
        required: 'Phone number is required',
        pattern: 'Please enter a valid phone number. Toll free numbers cannot be used.'
    },
    primaryFinProEmailAddress: {
        required: 'Email address is required',
        pattern: 'Please enter email in valid format'
    },
    primaryFinProLicenseNumberMandatory: {
        required: 'License number is required'
    },
    primaryFinProPostalAddressLine1: {
        required: 'Address line 1 is required',
        maxLength: 'Please enter 24 characters or less'
    },
    primaryFinProPostalAddressLine2: {
        maxLength: 'Please enter 24 characters or less'
    },
    primaryFinProPostalAddressCity: {
        required: 'City is required',
        maxLength: 'Please enter 20 characters or less'
    },
    primaryFinProPostalAddressState: {
        required: 'State is required'
    },
    primaryFinProPostalAddressZip: {
        required: 'Zip is required',
        pattern: 'Please enter valid ZIP'
    },
    primaryDetailCode: {
        pattern: 'Please enter valid detail code'
    },
    otherPrimaryDetailCode: {
        pattern: 'Please enter valid detail code'
    },
    additionalFinPros: {
        sellingCommissionPercentage: {
            required: 'Selling commission % is required'
        },
        servicingCommissionPercentage: {
            required: 'Servicing commission % is required'
        }
    },
    finProFirstName: {
        required: 'First name is required'
    },
    finProMiddleName: {
        required: false
    },
    finProLastName: {
        required: 'Last name is required'
    },
    finProSsn: {
        required: false
    },
    finProPhoneNumber: {
        required: false,
        pattern: 'Please enter a valid phone number.'
    },
    finProEmailAddress: {
        required: false,
        pattern: 'Please enter email in valid format'
    },
    finProDetailCode: {
        required: false
    }
};

export default ErrorMessages;
